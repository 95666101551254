import { Grid } from "@mui/material";
import React from "react";
import SingleDisplay from "../../parts/Item/SingleDisplay.tsx";
import classes from "./SingleDispForm.module.scss";
import DatamartStatistics from "../../../types/response/DatamartStatistics.tsx";

type SingleDispFormProps = {
  data: DatamartStatistics[];
};

const SingleDispForm: React.FC<SingleDispFormProps> = (props) => {
  const noData: DatamartStatistics = {
    type: "",
    unit: "",
    value: null,
  };
  return (
    <>
      <Grid container spacing={1} className={classes.singleDispForm}>
        {[...Array(4)].map((_, i) => (
          <Grid item xs={3} className={classes.singleDispItem} key={i}>
            <SingleDisplay
              data={
                props.data && props.data.length !== 0 ? props.data[i] : noData
              }
              type="organization"
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default SingleDispForm;
