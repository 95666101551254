import React, { useContext } from "react";
import LoginContext from "../../../contexts/LoginContext.tsx";
import { Box, Stack } from "@mui/material";
import classes from "./ResetPasswordField.module.scss";
import { useTranslation } from "react-i18next";
import PasswordText from "../../parts/TextField/PasswordText.tsx";

const ResetPasswordField = () => {
  const loginContext = useContext(LoginContext);
  const { t } = useTranslation();

  /**
   * パスワード入力イベント
   * @param event
   */
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    loginContext?.setPassword(event.target.value);
  };

  /**
   * パスワード再入力イベント
   * @param event
   */
  const handleResetPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    loginContext?.setRePassword(event.target.value);
  };

  return (
    <Stack>
      <Box sx={{ py: "0.5vw" }}>
        <PasswordText
          label={t("passwordReset.inputNewPassword")}
          className={classes.input_new_password}
          handleChange={handlePasswordChange}
          value={loginContext?.password}
          inputProps={{
            minLength: 8,
          }}
        />
      </Box>
      <Box sx={{ py: "0.5vw" }}>
        <PasswordText
          label={t("passwordReset.inputRePassword")}
          className={classes.input_new_password}
          handleChange={handleResetPasswordChange}
          value={loginContext?.rePassword}
          inputProps={{
            minLength: 8,
          }}
        />
      </Box>
    </Stack>
  );
};

export default ResetPasswordField;
