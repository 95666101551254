import React from "react";
import { Box } from "@mui/material";
import classes from "./NoticeManagementLayout.module.scss";
import NoticeManagementForm from "../../forms/NoticeManagementForm/NoticeManagementForm.tsx";

type NoticeManagementLayoutProps = {};

const NoticeManagementLayout: React.FC<NoticeManagementLayoutProps> = (
  props
) => {
  return (
    <Box className={classes.mainContent}>
      <NoticeManagementForm />
    </Box>
  );
};

export default NoticeManagementLayout;
