import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import NoticeManagementLayout from "../../layouts/NoticeManagement/NoticeManagementLayout.tsx";

const NoticeManagementPage = () => {
  return (
    <ContentLayout>
      <NoticeManagementLayout />
    </ContentLayout>
  );
};

export default NoticeManagementPage;
