import DatamartPcfChart from "./DatamartPcfChart";
import DatamartPcfHeatmap from "./DatamartPcfHeatmap";
import DatamartPdsTable from "./DatamartPdsTable";
import DatamartStatistics from "./DatamartStatistics";
import ErrorResponse from "./ErrorResponse";

export const defaultDatamartEquipment = {
  statistics: [],
  pcf_heatmap: {
    major_category_data: [],
    middle_category_data: [],
    minor_category_data: [],
    parts_or_energies_data: [],
  },
  emission_heatmap: {
    major_category_data: [],
    middle_category_data: [],
    minor_category_data: [],
    parts_or_energies_data: [],
  },
  pcf_chart: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  pcf_graph: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  pds_table: [],
  emissions_chart: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  emissions_graph: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  amount_chart: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  amount_graph: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
};

// 設備データマート取得APIレスポンス
export interface DatamartEquipment {
  // シングルデータ
  statistics: DatamartStatistics[];
  // PCFヒートマップ
  pcf_heatmap: {
    major_category_data: DatamartPcfHeatmap[];
    middle_category_data: DatamartPcfHeatmap[];
    minor_category_data: DatamartPcfHeatmap[];
    parts_or_energies_data: DatamartPcfHeatmap[];
  };
  // PCFヒートマップ
  emission_heatmap: {
    major_category_data: DatamartPcfHeatmap[];
    middle_category_data: DatamartPcfHeatmap[];
    minor_category_data: DatamartPcfHeatmap[];
    parts_or_energies_data: DatamartPcfHeatmap[];
  };
  // PCFチャート
  pcf_chart: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  // PCFグラフ
  pcf_graph: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  // PDS
  pds_table: DatamartPdsTable[];
  // PCFチャート
  emissions_chart: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  // PCFグラフ
  emissions_graph: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  // PCFチャート
  amount_chart: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  // PCFグラフ
  amount_graph: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
}

type DatamartEquipmentResponse = DatamartEquipment | ErrorResponse;

export default DatamartEquipmentResponse;
