import React, { useContext, useEffect } from "react";
import ProductLayout from "../../layouts/Product/ProductLayout.tsx";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import FullScreenLayout from "../../layouts/Common/FullScreenLayout.tsx";
import { DatamartProductProvider } from "../../../contexts/DatamartProductContext.tsx";

const ProductPage = () => {
  const { searchData, setSearchData, setSearch, setUnitSwitch } = useContext(
    DatamartSearchContext
  );

  useEffect(() => {
    searchData.gco2e_unit_type = "per_unit_managed";
    setUnitSwitch("company");
    setSearchData(searchData);
    setSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatamartProductProvider>
      <ContentLayout>
        <FullScreenLayout>
          <ProductLayout />
        </FullScreenLayout>
      </ContentLayout>
    </DatamartProductProvider>
  );
};

export default ProductPage;
