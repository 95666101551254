import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as ScopeImage } from "../../../assets/images/Search/icon_scope.svg";
import { ReactComponent as EnergyImage } from "../../../assets/images/Search/icon_energy_color.svg";
import { Inventory2 } from "@mui/icons-material";
import classes from "./SearchCheckBoxField.module.scss";
import SearchCheckBox from "../../parts/CheckBox/SearchCheckBox.tsx";
import { CheckBoxType } from "../../../types/SearchCheckBoxType.tsx";

type SearchCheckBoxFieldProps = {
  checkBoxData: { [key: string]: CheckBoxType };
  type: string;
  title: string;
};

const SearchCheckBoxField: React.FC<SearchCheckBoxFieldProps> = (props) => {
  const getIcon = () => {
    switch (props.type) {
      case "organizations":
        return <Inventory2 />;
      case "scopes":
        return <ScopeImage />;
      case "energies":
        return <EnergyImage />;
      default:
        return "";
    }
  };

  return (
    <>
      <Box className={classes.iconField} sx={{ pl: "1.5vw" }}>
        {/* {getIcon()} */}
        {props.title}
      </Box>
      <Box className={classes.checkBoxField}>
        <SearchCheckBox datas={props.checkBoxData} type={props.type} />
      </Box>
    </>
  );
};

export default SearchCheckBoxField;
