import DatamartPcfChart from "./DatamartPcfChart";
import DatamartPdsTable from "./DatamartPdsTable";
import DatamartStatistics from "./DatamartStatistics";
import ErrorResponse from "./ErrorResponse";

export const defaultDatamartProduct = {
  statistics: [],
  pcf_chart: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  pcf_graph: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  amount_chart: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  amount_graph: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  pds_table: [],
  emissions_chart: [],
};

// 製品軸データマート取得APIレスポンス
export interface DatamartProduct {
  // シングルデータ
  statistics: DatamartStatistics[];
  // PCFチャート
  pcf_chart: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  // PCFグラフ
  pcf_graph: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  amount_chart: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  amount_graph: {
    year_month: DatamartPcfChart[];
    calendar_year: DatamartPcfChart[];
    organization_fiscal_year: DatamartPcfChart[];
    general_business_year: DatamartPcfChart[];
  };
  // PDS
  pds_table: DatamartPdsTable[];
  emissions_chart: DatamartPcfChart[];
}

type DatamartProductResponse = DatamartProduct | ErrorResponse;

export default DatamartProductResponse;
