import React from "react";
import classes from "./TopContentForm.module.scss";
import { Box } from "@mui/material";
import HelloContentField from "../../fields/Top/HelloContentField.tsx";

type TopContentFormProps = {};

const TopContentForm: React.FC<TopContentFormProps> = (props) => {
  return (
    <>
      <Box className={classes.helloContent}>
        <HelloContentField />
      </Box>
      <Box className={classes.taskInfoContent}></Box>
    </>
  );
};

export default TopContentForm;
