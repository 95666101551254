import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import classes from "./SearchProductField.module.scss";
import SearchDateField from "./SearchDateField.tsx";
import SearchCheckBoxField from "./SearchCheckBoxField.tsx";
import SearchButtonField from "./SearchButtonField.tsx";
import { useTranslation } from "react-i18next";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import DatamartProductContext from "../../../contexts/DatamartProductContext.tsx";

type SearchProductFieldProps = {};

const SearchProductField: React.FC<SearchProductFieldProps> = (props) => {
  const { t } = useTranslation();
  const { checkBoxData, searchData } = useContext(DatamartSearchContext);
  const { setSelectCells } = useContext(DatamartProductContext);
  const {
    getCheckBoxInMiddleCategory,
    getCheckBoxInMinorCategory,
    getCheckBoxInPartsOrEnegies,
  } = SearchFeatures();

  const resetData = () => {
    setSelectCells({});
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Box className={classes.searchItem}>
          <SearchDateField
            title={t("header.search.product.targetYear")}
            fromDate={searchData.filter_params.from_target_year_month}
            toDate={searchData.filter_params.to_target_year_month}
          />
          <SearchDateField
            title={t("header.search.product.baseYear")}
            fromDate={searchData.filter_params.from_base_year_month}
            toDate={searchData.filter_params.to_base_year_month}
            isBase={true}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={checkBoxData.major_categories}
            type="major_categories"
            title={t("header.search.product.checkBoxMajor")}
          />
        </Box>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInMiddleCategory(checkBoxData)}
            type="middle_categories"
            title={t("header.search.product.checkBoxMiddle")}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInMinorCategory(checkBoxData)}
            type="minor_categories"
            title={t("header.search.product.checkBoxMinor")}
          />
        </Box>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInPartsOrEnegies(checkBoxData)}
            type="parts_or_energies"
            title={t("header.search.product.checkBoxProductEnergies")}
          />
        </Box>
      </Grid>
      <Grid item xs={2} className={classes.searchButtonItem}>
        <SearchButtonField resetData={resetData} />
      </Grid>
    </Grid>
  );
};

export default SearchProductField;
