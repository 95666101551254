import React, { useContext, useState } from "react";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import classes from "./LeftContentForm.module.scss";
import { Box, Grid } from "@mui/material";
import SingleDisplay from "../../parts/Item/SingleDisplay.tsx";
import BesideBarGraphField from "../../fields/Product/BesideBarGraphField.tsx";
import { DatamartProduct } from "../../../types/response/DatamartProductResponse.tsx";
import DatamartPcfChart from "../../../types/response/DatamartPcfChart.tsx";
import PDSTableDialog from "../../parts/Dialog/PDSTableDialog.tsx";
import DatamartProductContext from "../../../contexts/DatamartProductContext.tsx";

type LeftContentFormProps = {
  data: DatamartProduct;
};

const LeftContentForm: React.FC<LeftContentFormProps> = (props) => {
  const [open, setOpen] = useState(false);
  const { selectType, saveProductData, selectCells } = useContext(
    DatamartProductContext
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getData = (type) => {
    const data =
      ["by_energy", "by_organization"].includes(selectType) ||
      !Object.keys(selectCells).length
        ? props.data
        : saveProductData;
    if (!data || !data.statistics) {
      return {
        type: "",
        unit: "",
        value: 0,
      };
    }
    return (
      data.statistics.find((val) => {
        return val.type === type;
      }) || {
        type: "",
        unit: "",
        value: 0,
      }
    );
  };

  const getGraph = (title) => {
    const data = Object.keys(selectCells).length ? saveProductData : props.data;
    if (!data || !data.emissions_chart) return {} as DatamartPcfChart;
    return (
      data.emissions_chart.find((val) => {
        return val.title === title;
      }) || ({} as DatamartPcfChart)
    );
  };

  return (
    <div className={classes.leftContent}>
      <Box className={classes.singleDisplay}>
        <SingleDisplay data={getData("pcf")} type="product" />
      </Box>
      <Box className={classes.singleDisplay}>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay data={getData("pcf_pysm")} type="product" />
          </Grid>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay
              data={getData("pcf_pysm_changes_rate")}
              type="product"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.singleDisplay}>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay data={getData("pcf_base")} type="product" />
          </Grid>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay
              data={getData("pcf_base_changes_rate")}
              type="product"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.pdsButtonItem}>
        <BaseButton
          label="PDS"
          handleClick={handleClickOpen}
          className={classes.pdsButton}
        />
      </Box>
      <Box className={classes.singleDisplay}>
        <SingleDisplay data={getData("offset_rate")} type="product" />
      </Box>
      <Box className={classes.singleDisplay}>
        <SingleDisplay
          data={getData("recycle_material_use_rate")}
          type="product"
        />
      </Box>
      <Box className={classes.barGraph}>
        <BesideBarGraphField data={getGraph("by_energy")} />
      </Box>
      <Box className={classes.barGraph}>
        <BesideBarGraphField data={getGraph("by_organization")} />
      </Box>
      <PDSTableDialog
        pdsData={props.data.pds_table}
        handleClose={handleClose}
        open={open}
      />
    </div>
  );
};

export default LeftContentForm;
