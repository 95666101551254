import React, { useContext } from "react";
import classes from "./RightContentForm.module.scss";
import { Box } from "@mui/material";
import PCFLineGraphField from "../../fields/Product/PCFLineGraphField.tsx";
import BesideBarGraphField from "../../fields/Product/BesideBarGraphField.tsx";
import { DatamartProduct } from "../../../types/response/DatamartProductResponse.tsx";
import DatamartPcfChart from "../../../types/response/DatamartPcfChart.tsx";
import DatamartProductContext from "../../../contexts/DatamartProductContext.tsx";

type RightContentFormProps = {
  data: DatamartProduct;
};

const RightContentForm: React.FC<RightContentFormProps> = (props) => {
  const { selectType, saveProductData, selectCells } = useContext(
    DatamartProductContext
  );

  const getGraph = (title) => {
    let data = props.data;
    if (!data || !data.emissions_chart) return {} as DatamartPcfChart;
    switch (title) {
      case "in_major_category":
        if (Object.keys(selectCells).length) data = saveProductData;
        break;
      case "in_own_middle_category":
        if (
          Object.keys(selectCells).length &&
          [
            "by_energy",
            "by_organization",
            "in_own_middle_category",
            "in_own_minor_category",
          ].includes(selectType)
        ) {
          data = saveProductData;
        }

        if (
          selectCells["in_major_category"] &&
          selectCells["in_major_category"].find((val) => {
            return val.code === "99";
          })
        ) {
          return {} as DatamartPcfChart;
        }
        break;
      case "in_other_middle_category":
        if (
          Object.keys(selectCells).length &&
          [
            "by_energy",
            "by_organization",
            "in_other_middle_category",
            "in_other_minor_category",
          ].includes(selectType)
        ) {
          data = saveProductData;
        }
        if (
          selectCells["in_major_category"] &&
          selectCells["in_major_category"].find((val) => {
            return val.code === "01";
          })
        ) {
          return {} as DatamartPcfChart;
        }
        break;
      case "in_own_minor_category":
        if (
          Object.keys(selectCells).length &&
          ["by_energy", "by_organization", "in_own_minor_category"].includes(
            selectType
          )
        ) {
          data = saveProductData;
        }
        if (
          selectCells["in_major_category"] &&
          selectCells["in_major_category"].find((val) => {
            return val.code === "99";
          })
        ) {
          return {} as DatamartPcfChart;
        }
        break;
      case "in_other_minor_category":
        if (
          Object.keys(selectCells).length &&
          ["by_energy", "by_organization", "in_other_minor_category"].includes(
            selectType
          )
        ) {
          data = saveProductData;
        }
        if (
          selectCells["in_major_category"] &&
          selectCells["in_major_category"].find((val) => {
            return val.code === "01";
          })
        ) {
          return {} as DatamartPcfChart;
        }
        break;
    }
    return (
      data.emissions_chart.find((val) => {
        return val.title === title;
      }) || ({} as DatamartPcfChart)
    );
  };

  return (
    <div className={classes.rightContent}>
      <Box className={classes.lingGraph}>
        <PCFLineGraphField data={props.data} />
      </Box>
      <Box className={classes.barGraph}>
        <BesideBarGraphField data={getGraph("in_major_category")} />
      </Box>
      <Box className={classes.barGraph}>
        <Box className={classes.harfBarGraph}>
          <BesideBarGraphField data={getGraph("in_own_middle_category")} />
        </Box>
        <Box className={classes.harfBarGraph}>
          <BesideBarGraphField data={getGraph("in_other_middle_category")} />
        </Box>
      </Box>
      <Box className={classes.barGraph}>
        <Box className={classes.harfBarGraph}>
          <BesideBarGraphField data={getGraph("in_own_minor_category")} />
        </Box>
        <Box className={classes.harfBarGraph}>
          <BesideBarGraphField data={getGraph("in_other_minor_category")} />
        </Box>
      </Box>
    </div>
  );
};

export default RightContentForm;
