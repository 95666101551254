import React, { useState } from "react";
import { ReactComponent as IconImage } from "../../../assets/images/Header/icon_person.svg";
import { IconButton, Typography } from "@mui/material";
import classes from "./UserField.module.scss";
import AccountMenu from "../../parts/Menu/AccountMenu.tsx";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

const UserField = () => {
  const { getUserName } = UserFuatures();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event) => {
    setOpen(!open);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <IconImage className={classes.iconImage} />
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1 }}
          className={classes.iconTitle}
        >
          {getUserName()}
        </Typography>
      </IconButton>
      <AccountMenu open={open} anchorEl={anchorEl} handleClose={handleClose} />
    </>
  );
};

export default UserField;
