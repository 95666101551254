import domtoimage from "dom-to-image";

export const ScreenFuatures = () => {
  /**
   * 画像保存処理
   */
  const photoSave = async (
    chartElement: HTMLElement | null,
    name: string,
    active: boolean
  ) => {
    if (chartElement) {
      const originalOverflow = chartElement.style.overflow;
      const originalHeight = chartElement.style.height;
      const originalWidth = chartElement.style.width;
      const originalBackGroudColor = chartElement.style.backgroundColor;

      chartElement.style.overflow = "visible";
      chartElement.style.height = `${chartElement.scrollHeight}px`;
      chartElement.style.width = `${chartElement.scrollWidth}px`;
      chartElement.style.backgroundColor = "white";
      domtoimage
        .toPng(chartElement, {
          bgcolor: "white",
        })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = name + ".png";
          link.click();
          link.remove();
        })
        .catch((error) => {
          console.error("キャプチャエラー:", error);
        })
        .finally(() => {
          chartElement.style.overflow = originalOverflow;
          chartElement.style.height = originalHeight;
          chartElement.style.width = originalWidth;
          chartElement.style.backgroundColor = originalBackGroudColor;
        });
    }
  };

  return { photoSave };
};
