import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Pagination,
  Checkbox,
} from "@mui/material";
import classes from "./MailCheckDialog.module.scss";
import { useTranslation } from "react-i18next";
import MailType from "../../../types/MailType.tsx";

interface Props {
  isOpen: boolean;
  selectEmail: (mail: MailType) => void;
  handleSubmit: () => void;
  handleCancel: () => void;
  emailList: MailType[];
  selectedEmailList: MailType[];
}

const MailCheckDialog = (props: Props) => {
  const { t } = useTranslation();
  const [isReceiverListDisplay, setIsReceiverListDisplay] =
    useState<boolean>(false);
  const [currentPageNum, setCurrentPageNum] = useState<number>(1);
  const onPageChange = (e, page) => setCurrentPageNum(page);

  const checked = props.selectedEmailList.includes(
    props.emailList[currentPageNum - 1]
  );
  const onCheckboxChange = () => {
    props.selectEmail(props.emailList[currentPageNum - 1]);
  };

  const onConfirmBtnClick = () => {
    if (currentPageNum === props.emailList.length) {
      props.selectedEmailList.length === 0
        ? props.handleSubmit()
        : setIsReceiverListDisplay(true);
    } else {
      setCurrentPageNum(currentPageNum + 1);
    }
  };
  const onSubmitClick = () => props.handleSubmit();
  const onCancelClick = () => props.handleCancel();

  useEffect(() => {
    setCurrentPageNum(1);
    setIsReceiverListDisplay(false);
  }, [props.isOpen]);

  return (
    <>
      <Dialog open={props.isOpen} maxWidth={"md"}>
        <DialogTitle>{t("accountCreate.dialog.title")}</DialogTitle>
        {isReceiverListDisplay ? (
          <DialogContent>
            <Box className={classes.segment}>
              <Typography>{t("accountCreate.dialog.address")}</Typography>
              <List className={classes.segment_content}>
                {props.selectedEmailList.map((email) => (
                  <ListItem key={email.email_id}>
                    <Typography>{email.email}</Typography>
                  </ListItem>
                ))}
              </List>
            </Box>
            <Box className={classes.button_container}>
              <Button variant="outlined" onClick={onCancelClick}>
                {t("accountCreate.dialog.button.cancel")}
              </Button>
              <Button variant="contained" onClick={onSubmitClick}>
                {t("accountCreate.dialog.button.submit")}
              </Button>
            </Box>
          </DialogContent>
        ) : (
          <DialogContent>
            <Box className={classes.segment}>
              <Typography>{t("accountCreate.dialog.mailTitle")}</Typography>
              <Typography className={classes.segment_content}>
                {props.emailList.length !== 0
                  ? props.emailList[currentPageNum - 1].title
                  : ""}
              </Typography>
            </Box>
            <Box className={classes.segment}>
              <Typography>{t("accountCreate.dialog.address")}</Typography>
              <Typography className={classes.segment_content}>
                {props.emailList.length !== 0
                  ? props.emailList[currentPageNum - 1].email
                  : ""}
              </Typography>
            </Box>
            <Box className={classes.segment}>
              <Typography>{t("accountCreate.dialog.mailContent")}</Typography>
              <Typography className={classes.content_body}>
                {props.emailList.length !== 0
                  ? props.emailList[currentPageNum - 1].message
                  : ""}
              </Typography>
            </Box>
            <Box className={classes.pagination_container}>
              <Pagination
                count={props.emailList.length}
                page={currentPageNum}
                onChange={onPageChange}
              />
            </Box>
            <Box className={classes.button_container}>
              <Box className={classes.checkbox_container}>
                <Checkbox checked={checked} onChange={onCheckboxChange} />
                <Typography>{t("accountCreate.dialog.send")}</Typography>
              </Box>
              <Button variant="contained" onClick={onConfirmBtnClick}>
                {currentPageNum === props.emailList.length
                  ? t("accountCreate.dialog.button.confirm")
                  : t("accountCreate.dialog.button.next")}
              </Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default MailCheckDialog;
