import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import HelpLayout from "../../layouts/Help/HelpLayout.tsx";

const HelpPage = () => {
  return (
    <ContentLayout>
      <HelpLayout />
    </ContentLayout>
  );
};

export default HelpPage;
