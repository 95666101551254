import React, { useContext } from "react";
import InputCenterText from "../../parts/TextField/InputCenterText.tsx";
import LoginContext from "../../../contexts/LoginContext.tsx";
import { Stack } from "@mui/material";
import classes from "./ResetMailField.module.scss";
import { useTranslation } from "react-i18next";

const ResetMailField = () => {
  const loginContext = useContext(LoginContext);
  const { t } = useTranslation();

  /**
   * パスワードリセットメール送信先入力イベント
   * @param event
   */
  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    loginContext?.setId(event.target.value);
  };

  return (
    <Stack>
      <InputCenterText
        label={t("passwordReset.inputPesetMail")}
        className={classes.input_mail}
        handleChange={handleOtpChange}
        value={loginContext?.id}
      />
    </Stack>
  );
};

export default ResetMailField;
