import React from "react";
import classes from "./BesideBarGraphField.module.scss";
import { Box, Paper, Typography } from "@mui/material";
import BesideBarGraph from "../../parts/Chart/BesideBarGraph.tsx";
import DatamartPcfChart from "../../../types/response/DatamartPcfChart.tsx";
import { useTranslation } from "react-i18next";

type BesideBarGraphFieldProps = {
  data: DatamartPcfChart;
};

const BesideBarGraphField: React.FC<BesideBarGraphFieldProps> = (props) => {
  const { t } = useTranslation();
  const getTitle = () => {
    if (!props.data) return "";
    switch (props.data.title) {
      case "by_energy":
        return t("product.graphByEnergy");
      case "by_organization":
        return t("product.graphByOrganization");
      case "in_major_category":
        return t("product.graphMajor");
      case "in_own_middle_category":
        return t("product.graphOwnMiddle");
      case "in_other_middle_category":
        return t("product.graphOtherMiddle");
      case "in_own_minor_category":
        return t("product.graphOwnMinor");
      case "in_other_minor_category":
        return t("product.graphOtherMinor");
    }
    return "";
  };

  return (
    <Paper elevation={1} className={classes.besideBarGraph}>
      <Typography color="text.secondary" gutterBottom className={classes.label}>
        {getTitle()}
      </Typography>
      <Box className={classes.besideBarBox}>
        <BesideBarGraph
          title={getTitle()}
          data={props.data}
          type={props.data.title}
        />
      </Box>
    </Paper>
  );
};

export default BesideBarGraphField;
