import { useTranslation } from "react-i18next";

export const SingleDisplayType = () => {
  const { t } = useTranslation();

  const SingleDisplayEnumOrganization = {
    co2_emissions: t("type.SingleDisplayType.organizations.co2Emissions"),
    co2_emissions_pysm: t(
      "type.SingleDisplayType.organizations.co2EmissionsPysm"
    ),
    co2_emissions_pysm_changes_amount: t(
      "type.SingleDisplayType.organizations.changesAmaount"
    ),
    co2_emissions_pysm_changes_rate: t(
      "type.SingleDisplayType.organizations.changesRate"
    ),
  };

  const SingleDisplayEnumSummary = {
    pcf: t("type.SingleDisplayType.summary.pcf"),
    pcf_pysm: t("type.SingleDisplayType.summary.pcfPysm"),
    pcf_base: t("type.SingleDisplayType.summary.pcfBase"),
    pcf_pysm_changes_rate: t("type.SingleDisplayType.summary.changesRate"),
    pcf_base_changes_rate: t("type.SingleDisplayType.summary.changesRateBase"),
    offset_rate: t("type.SingleDisplayType.summary.offsetRate"),
    recycle_material_use_rate: t(
      "type.SingleDisplayType.summary.recycleMaterialUseRate"
    ),
  };

  const SingleDisplayEnumProduct = {
    pcf: t("type.SingleDisplayType.product.pcf"),
    pcf_pysm: t("type.SingleDisplayType.product.pcfPysm"),
    pcf_base: t("type.SingleDisplayType.product.pcfBase"),
    pcf_pysm_changes_rate: t("type.SingleDisplayType.product.changesRate"),
    pcf_base_changes_rate: t("type.SingleDisplayType.product.changesRateBase"),
    offset_rate: t("type.SingleDisplayType.product.offsetRate"),
    recycle_material_use_rate: t(
      "type.SingleDisplayType.product.recycleMaterialUseRate"
    ),
  };

  const SingleDisplayEnumEquipment = {
    pcf: t("type.SingleDisplayType.equipment.pcf"),
    pcf_pysm: t("type.SingleDisplayType.equipment.pcfPysm"),
    pcf_base: t("type.SingleDisplayType.equipment.pcfBase"),
    pcf_pysm_changes_rate: t(
      "type.SingleDisplayType.equipment.pcfPysmChangesRate"
    ),
    pcf_base_changes_rate: t(
      "type.SingleDisplayType.equipment.pcfBaseChangesRate"
    ),
    co2_emissions: t("type.SingleDisplayType.equipment.co2Emissions"),
    co2_emissions_pysm: t("type.SingleDisplayType.equipment.co2EmissionsPysm"),
    co2_emissions_base: t("type.SingleDisplayType.equipment.co2EmissionsBase"),
    co2_emissions_pysm_changes_rate: t(
      "type.SingleDisplayType.equipment.co2EmissionsPysmChangesRate"
    ),
    co2_emissions_base_changes_rate: t(
      "type.SingleDisplayType.equipment.co2EmissionsBaseChangesRate"
    ),
    offset_rate: t("type.SingleDisplayType.equipment.offsetRate"),
    recycle_material_use_rate: t(
      "type.SingleDisplayType.equipment.recycleMaterialUseRate"
    ),
  };

  return {
    SingleDisplayEnumOrganization,
    SingleDisplayEnumSummary,
    SingleDisplayEnumProduct,
    SingleDisplayEnumEquipment,
  };
};
