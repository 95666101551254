import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import SettingLayout from "../../layouts/Setting/SettingLayout.tsx";

const SettingPage = () => {
  return (
    <ContentLayout>
      <SettingLayout />
    </ContentLayout>
  );
};

export default SettingPage;
