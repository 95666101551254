import React, { useContext } from "react";
import InputText from "../../parts/TextField/InputText.tsx";
import PasswordText from "../../parts/TextField/PasswordText.tsx";
import { Stack } from "@mui/material";
import LoginContext from "../../../contexts/LoginContext.tsx";
import classes from "./LoginField.module.scss";
import { useTranslation } from "react-i18next";

const LoginField = () => {
  const loginContext = useContext(LoginContext);
  const { t } = useTranslation();

  /**
   * ID（メールアドレス）入力イベント
   * @param event
   */
  const handleIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    loginContext?.setId(event.target.value);
  };

  /**
   * パスワード入力イベント
   * @param event
   */
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    loginContext?.setPassword(event.target.value);
  };

  return (
    <Stack>
      <InputText
        label={t("login.inputId")}
        handleChange={handleIdChange}
        value={loginContext?.id}
        className={classes.input_id}
      />
      <PasswordText
        label={t("login.inputPassword")}
        handleChange={handlePasswordChange}
        value={loginContext?.password}
        className={classes.input_password}
      />
    </Stack>
  );
};

export default LoginField;
