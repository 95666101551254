import { useMutation, useQuery } from "react-query";
import axios from "axios";
import TargetResponse from "../../types/response/TargetResponse.tsx";
import TargetRequest from "../../types/request/TargetRequest.tsx";

const TargetApi = {
  /**
   * 目標値取得API
   * @param request
   */
  GetTargets: (productCode, onSuccess, onError) => {
    return useQuery(
      "getTargets",
      async () => {
        const response = await axios.get<TargetResponse>(
          process.env.REACT_APP_HOST_URL +
            "/v1/targets?product_code=" +
            productCode,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
  /**
   * 目標値更新API
   * @param request
   */
  PutTargets: (onSuccess, onError) => {
    return useMutation(
      async (request: TargetRequest) => {
        const response = await axios.put(
          process.env.REACT_APP_HOST_URL + "/v1/targets",
          request,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default TargetApi;
