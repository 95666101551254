import { useContext, useState } from "react";
import UserApi from "../features/api/UserApi.tsx";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import UserContext from "../contexts/UserContext.tsx";
import UserInfoRequest from "../types/request/UserInfoRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import LanguageRequest from "../types/request/LanguageRequest.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";
import { useTranslation } from "react-i18next";

export const useUser = () => {
  const { t, i18n } = useTranslation();
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { onCommonError } = ApiCommon();
  const [userApiLoading, setUserApiLoading] = useState(false);
  const [language, setLanguage] = useState("");
  const [userInfoRequest, setUserInfoRequest] = useState<UserInfoRequest>(
    {} as UserInfoRequest
  );

  /**
   * ユーザー情報取得API成功時
   */
  const onSuccess = (data) => {
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    userContext?.setUserInfo(data);
    i18n.changeLanguage(data.language);
    localStorage.setItem("language", data.language);
    setUserApiLoading(false);
  };

  /**
   * ユーザー情報取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setUserApiLoading(false);
  };

  /**
   * ユーザー情報取得
   */
  const getUserApi = () => {
    setUserApiLoading(true);
    exeGetUser.refetch();
  };

  /**
   * ユーザー情報更新API成功時（目標値更新）
   */
  const onGoalValueSuccess = (data) => {
    setUserApiLoading(false);
    appContext?.setSuccessOpen(true);
    appContext?.setAlertMessage(Message.SUCCESS_UPDATE_GOAL_VALUE);
  };

  /**
   * ユーザー情報更新（目標値更新）
   */
  const updateGoalValueApi = () => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    setUserApiLoading(true);
    exeUpdateGoalValue.refetch();
  };

  /**
   * 言語設定API成功時
   */
  const onLanguageSuccess = (data) => {
    setUserApiLoading(false);
    appContext?.setSuccessOpen(true);
    userInfo.language = language;
    userContext?.setUserInfo(userInfo);
  };

  /**
   * 言語設定
   */
  const putLanguageApi = (language: string) => {
    if (!appContext?.idToken.sub) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_SYSTEM_ERROR);
      return;
    }
    const param: LanguageRequest = {
      language: language,
    };
    setUserApiLoading(true);
    setLanguage(language);
    exePutLanguage.mutate(param);
  };

  // ユーザー情報取得API実行
  const exeGetUser = UserApi.GetUserInfo(
    appContext?.accessToken.sub || "",
    onSuccess,
    onError
  );

  // ユーザー情報更新API実行（目標値更新）
  const exeUpdateGoalValue = UserApi.PutUserInfo(
    appContext?.accessToken.sub || "",
    userInfoRequest,
    onGoalValueSuccess,
    onError
  );

  // 言語設定API実行
  const exePutLanguage = UserApi.PutLanguage(onLanguageSuccess, onError);

  return {
    getUserApi,
    updateGoalValueApi,
    userInfoRequest,
    setUserInfoRequest,
    userApiLoading,
    putLanguageApi,
  };
};
