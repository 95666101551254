import React, { useContext, useEffect } from "react";
import DataInputForm from "../../forms/DataInput/DataInputForm.tsx";
import UploadHistoriesContext from "../../../contexts/UploadHistoriesContext.tsx";
import { useFile } from "../../../hooks/useFile.tsx";
import FileFeatures from "../../../features/FileFeatures.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { FilesType } from "../../../types/enum/FileTypeEnum.tsx";

type DataInputLayoutProps = {};

const DataInputLayout: React.FC<DataInputLayoutProps> = (props) => {
  const uploadHistoiesContext = useContext(UploadHistoriesContext);
  const { getUploadsHistories, historiesResponse, isLoading } = useFile();
  const {
    CompanyFileType,
    CompanyLocationsFileType,
    ProductFileType,
    EnergySavingFileType,
  } = FilesType();

  useEffect(() => {
    // アップロード履歴取得API
    getUploadsHistories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!historiesResponse) return;
    uploadHistoiesContext?.setUploadHistories(historiesResponse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historiesResponse]);

  return (
    <>
      {!isLoading && (
        <DataInputForm
          dispData={FileFeatures.getIconDispData(
            uploadHistoiesContext?.uploadHistoies.history || [],
            CompanyFileType,
            CompanyLocationsFileType,
            ProductFileType,
            EnergySavingFileType
          )}
        />
      )}
      {isLoading && <CircularProgressDialog />}
    </>
  );
};

export default DataInputLayout;
