import React from "react";
import classes from "./CustomerHelpForm.module.scss";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type CustomerHelpFormProps = {};

const CustomerHelpForm: React.FC<CustomerHelpFormProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5" component="div" className={classes.title}>
        {t("help.customerHelp")}
      </Typography>
    </>
  );
};

export default CustomerHelpForm;
