import { useMutation } from "react-query";
import axios from "axios";
import PrepareCreateAccountsResponse from "../../types/response/PrepareCreateAccountResponse";
import PrepareCreateAccountRequest from "../../types/request/PrepareCreateAccountRequest";

const AccountCreateApi = {
  /**
   * アカウント発行準備API
   */
  PrepareCreateAccounts: (onSuccess, onError) => {
    return useMutation(
      async (request: PrepareCreateAccountRequest) => {
        if (
          !(
            request.userFile &&
            request.organizationFile &&
            request.calendarFile &&
            request.contractFile
          )
        ) {
          return Promise.reject(new Error("Invalid parameters"));
        }
        const formData = new FormData();
        formData.append("user_input_file", request.userFile);
        formData.append("organization_input_file", request.organizationFile);
        formData.append("calendar_input_file", request.calendarFile);
        formData.append("contract_input_file", request.contractFile);

        const response = await axios.post<PrepareCreateAccountsResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/accounts/temp",
          formData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * アカウント発行API
   */
  CreateAccounts: (onSuccess, onError) => {
    return useMutation(
      async (mailIdList: string[]) => {
        const response = await axios.post<boolean>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/accounts",
          { email_id_list: mailIdList },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * 一時保存アカウント削除API
   */
  DeleteAccounts: (onSuccess, onError) => {
    return useMutation(
      async (mailIdList: string[]) => {
        const response = await axios.delete<boolean>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/accounts/temp",
          {
            data: { email_id_list: mailIdList },
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default AccountCreateApi;
