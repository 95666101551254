import { Box } from "@mui/material";
import React from "react";
import HeaderForm from "../../forms/Header/HeaderForm.tsx";
import SideMenuForm from "../../forms/SideMenu/SideMenuForm.tsx";

const ProductFreeAnalysisPage = () => {
  return (
    <Box display={"flex"}>
      <HeaderForm />
      <SideMenuForm />
    </Box>
  );
};

export default ProductFreeAnalysisPage;
