import React from "react";
import classes from "./InquiryLayout.module.scss";
import { Box } from "@mui/material";
import InquiryForm from "../../forms/Inquiry/InquiryForm.tsx";

type InquiryLayoutProps = {};

const InquiryLayout: React.FC<InquiryLayoutProps> = (props) => {
  return (
    <Box className={classes.inquiry}>
      <InquiryForm />
    </Box>
  );
};

export default InquiryLayout;
