import React, { createContext, useState } from "react";
import UserInfoResponse from "../types/response/UserInfoResponse.tsx";

type UserInfoProps = {
  userInfo: UserInfoResponse;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfoResponse>>;
  role: string[];
  setRole: React.Dispatch<React.SetStateAction<string[]>>;
  energySavingFlag: boolean;
  setEnergySavingFlag: React.Dispatch<React.SetStateAction<boolean>>;
  freeAnalysisFlag: boolean;
  setFreeAnalysisFlag: React.Dispatch<React.SetStateAction<boolean>>;
  productSpecificFlag: boolean;
  setProductSpecificFlag: React.Dispatch<React.SetStateAction<boolean>>;
};

// ユーザーコンテキスト
const UserContext = createContext<UserInfoProps | null>(null);

type Props = {
  children: React.ReactNode;
};

export const AdminUserProvider: React.VFC<Props> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<UserInfoResponse>(
    {} as UserInfoResponse
  );
  const [role, setRole] = useState<string[]>([]);
  const [energySavingFlag, setEnergySavingFlag] = useState<boolean>(false);
  const [freeAnalysisFlag, setFreeAnalysisFlag] = useState<boolean>(false);
  const [productSpecificFlag, setProductSpecificFlag] =
    useState<boolean>(false);

  return (
    <UserContext.Provider
      value={{
        userInfo,
        setUserInfo,
        role,
        setRole,
        energySavingFlag,
        setEnergySavingFlag,
        freeAnalysisFlag,
        setFreeAnalysisFlag,
        productSpecificFlag,
        setProductSpecificFlag,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
