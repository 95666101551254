import React, { useContext } from "react";
import classes from "./PCFLineGraphField.module.scss";
import { Box, Paper, Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PCFGraph from "../../parts/Chart/PCFGraph.tsx";
import { DatamartProduct } from "../../../types/response/DatamartProductResponse.tsx";
import DatamartFeatures from "../../../features/DatamartFeatures.tsx";
import { useTranslation } from "react-i18next";
import DatamartProductContext from "../../../contexts/DatamartProductContext.tsx";

type PCFLineGraphFieldProps = {
  data: DatamartProduct;
};

const PCFLineGraphField: React.FC<PCFLineGraphFieldProps> = (props) => {
  const { t } = useTranslation();
  const { createChartData } = DatamartFeatures();
  const { targets, selectType, saveProductData, selectCells, tab, setTab } =
    useContext(DatamartProductContext);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const isDispSaveChart = () => {
    if (
      Object.keys(selectCells).length &&
      !["by_energy", "by_organization"].includes(selectType)
    ) {
      return true;
    }
    return false;
  };

  return (
    <Paper elevation={1} className={classes.pcfLineGraph}>
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "left",
        }}
        color="text.secondary"
        gutterBottom
        className={classes.title}
      >
        {t("product.lineGraph.title")}
      </Typography>
      <TabContext value={tab}>
        <TabList
          onChange={handleChange}
          aria-label="pcfLineGraph"
          className={classes.tabList}
        >
          <Tab
            label={t("product.lineGraph.tabYearMonth")}
            value="1"
            className={classes.tab}
          />
          <Tab
            label={t("product.lineGraph.tabCalendarYear")}
            value="2"
            className={classes.tab}
          />
          <Tab
            label={t("product.lineGraph.tabFiscalYear")}
            value="3"
            className={classes.tab}
          />
          <Tab
            label={t("product.lineGraph.tabBusinessYear")}
            value="4"
            className={classes.tab}
          />
        </TabList>
        <TabPanel value="1" className={classes.tabPanel}>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                data={createChartData(
                  isDispSaveChart()
                    ? saveProductData.pcf_chart.year_month
                    : props.data.pcf_chart.year_month,
                  isDispSaveChart()
                    ? saveProductData.pcf_graph.year_month
                    : props.data.pcf_graph.year_month,
                  t("product.lineGraph.yearMonth.pcfGraphRightLabel")
                )}
                title={t("product.lineGraph.yearMonth.pcfGraphTitle")}
                leftLabel={t("product.lineGraph.yearMonth.pcfGraphLeftLabel")}
                rightLabel={t("product.lineGraph.yearMonth.pcfGraphRightLabel")}
                targetValue={targets.product_target_value}
              />
            </Paper>
          </Box>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                data={createChartData(
                  Object.keys(selectCells).length
                    ? saveProductData.amount_chart.year_month
                    : props.data.amount_chart.year_month,
                  Object.keys(selectCells).length
                    ? saveProductData.amount_graph.year_month
                    : props.data.amount_graph.year_month,
                  t("product.lineGraph.yearMonth.activityGraphRightLabel")
                )}
                title={t("product.lineGraph.yearMonth.activityGraphTitle")}
                leftLabel={t(
                  "product.lineGraph.yearMonth.activityGraphLeftLabel"
                )}
                rightLabel={t(
                  "product.lineGraph.yearMonth.activityGraphRightLabel"
                )}
              />
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanel}>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                data={createChartData(
                  isDispSaveChart()
                    ? saveProductData.pcf_chart.calendar_year
                    : props.data.pcf_chart.calendar_year,
                  isDispSaveChart()
                    ? saveProductData.pcf_graph.calendar_year
                    : props.data.pcf_graph.calendar_year,
                  t("product.lineGraph.calendarYear.pcfGraphRightLabel")
                )}
                title={t("product.lineGraph.calendarYear.pcfGraphTitle")}
                leftLabel={t(
                  "product.lineGraph.calendarYear.pcfGraphLeftLabel"
                )}
                rightLabel={t(
                  "product.lineGraph.calendarYear.pcfGraphRightLabel"
                )}
                targetValue={targets.product_target_value}
              />
            </Paper>
          </Box>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                data={createChartData(
                  Object.keys(selectCells).length
                    ? saveProductData.amount_chart.calendar_year
                    : props.data.amount_chart.calendar_year,
                  Object.keys(selectCells).length
                    ? saveProductData.amount_graph.calendar_year
                    : props.data.amount_graph.calendar_year,
                  t("product.lineGraph.calendarYear.activityGraphRightLabel")
                )}
                title={t("product.lineGraph.calendarYear.activityGraphTitle")}
                leftLabel={t(
                  "product.lineGraph.calendarYear.activityGraphLeftLabel"
                )}
                rightLabel={t(
                  "product.lineGraph.calendarYear.activityGraphRightLabel"
                )}
              />
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value="3" className={classes.tabPanel}>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                data={createChartData(
                  isDispSaveChart()
                    ? saveProductData.pcf_chart.organization_fiscal_year
                    : props.data.pcf_chart.organization_fiscal_year,
                  isDispSaveChart()
                    ? saveProductData.pcf_graph.organization_fiscal_year
                    : props.data.pcf_graph.organization_fiscal_year,
                  t("product.lineGraph.fiscalYear.pcfGraphRightLabel")
                )}
                title={t("product.lineGraph.fiscalYear.pcfGraphTitle")}
                leftLabel={t("product.lineGraph.fiscalYear.pcfGraphLeftLabel")}
                rightLabel={t(
                  "product.lineGraph.fiscalYear.pcfGraphRightLabel"
                )}
                targetValue={targets.product_target_value}
              />
            </Paper>
          </Box>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                data={createChartData(
                  Object.keys(selectCells).length
                    ? saveProductData.amount_chart.organization_fiscal_year
                    : props.data.amount_chart.organization_fiscal_year,
                  Object.keys(selectCells).length
                    ? saveProductData.amount_graph.organization_fiscal_year
                    : props.data.amount_graph.organization_fiscal_year,
                  t("product.lineGraph.fiscalYear.activityGraphRightLabel")
                )}
                title={t("product.lineGraph.fiscalYear.activityGraphTitle")}
                leftLabel={t(
                  "product.lineGraph.fiscalYear.activityGraphLeftLabel"
                )}
                rightLabel={t(
                  "product.lineGraph.fiscalYear.activityGraphRightLabel"
                )}
              />
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value="4" className={classes.tabPanel}>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                data={createChartData(
                  isDispSaveChart()
                    ? saveProductData.pcf_chart.general_business_year
                    : props.data.pcf_chart.general_business_year,
                  isDispSaveChart()
                    ? saveProductData.pcf_graph.general_business_year
                    : props.data.pcf_graph.general_business_year,
                  t("product.lineGraph.businessYear.pcfGraphRightLabel")
                )}
                title={t("product.lineGraph.businessYear.pcfGraphTitle")}
                leftLabel={t(
                  "product.lineGraph.businessYear.pcfGraphLeftLabel"
                )}
                rightLabel={t(
                  "product.lineGraph.businessYear.pcfGraphRightLabel"
                )}
                targetValue={targets.product_target_value}
              />
            </Paper>
          </Box>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                data={createChartData(
                  Object.keys(selectCells).length
                    ? saveProductData.amount_chart.general_business_year
                    : props.data.amount_chart.general_business_year,
                  Object.keys(selectCells).length
                    ? saveProductData.amount_graph.general_business_year
                    : props.data.amount_graph.general_business_year,
                  t("product.lineGraph.businessYear.activityGraphRightLabel")
                )}
                title={t("product.lineGraph.businessYear.activityGraphTitle")}
                leftLabel={t(
                  "product.lineGraph.businessYear.activityGraphLeftLabel"
                )}
                rightLabel={t(
                  "product.lineGraph.businessYear.activityGraphRightLabel"
                )}
              />
            </Paper>
          </Box>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default PCFLineGraphField;
