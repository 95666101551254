import React, { useContext, useState } from "react";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import LoginField from "../../fields/Login/LoginField.tsx";
import { Box, Link, SelectChangeEvent, Stack } from "@mui/material";
import econipass from "../../../assets/images/EcoNiPass.png";
import { useLogin } from "../../../hooks/useLogin.tsx";
import OtpField from "../../fields/Login/OtpField.tsx";
import LoginContext from "../../../contexts/LoginContext.tsx";
import classes from "./LoginForm.module.scss";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTranslation } from "react-i18next";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import { Language } from "../../../types/enum/LanguageEnum.tsx";

const LoginForm = () => {
  const { t, i18n } = useTranslation();
  const { useLoginApi, useOtpLoginApi, response, isLoading, errorMessage } =
    useLogin();
  const loginContext = useContext(LoginContext);
  const [selectLan, setSelectLan] = useState("ja");

  const Login = () => {
    useLoginApi();
  };

  const OtpLogin = () => {
    useOtpLoginApi();
  };

  const handleSelectLanguage = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSelectLan(value);
    i18n.changeLanguage(value);
    localStorage.setItem("language", value);
  };

  return (
    <>
      <SelectItem
        title={t("common.language")}
        selectValue={selectLan}
        values={Language}
        className={classes.language}
        handleClick={handleSelectLanguage}
      />
      <Box className={classes.title}>
        <img src={econipass} width={"262.5px"} height={"75px"} alt="" />
        <div>{t("adminLogin.title")}</div>
      </Box>
      <Stack spacing={2}>
        {!response && (
          <>
            <Box className={classes.inputForm}>
              <LoginField />
            </Box>
            <Box sx={{ pt: 3 }}>
              <BaseButton
                label={t("login.title")}
                className={classes.login_button}
                handleClick={Login}
              />
            </Box>
          </>
        )}
        {response && (
          <>
            <h3>{t("optLogin.optInput")}</h3>
            <div>{t("optLogin.optInputAnnounce")}</div>
            <Box sx={{ pt: 1 }} className={classes.inputForm}>
              <OtpField />
            </Box>
            <Box sx={{ pt: 3 }}>
              <BaseButton
                label={t("optLogin.optButtonLabel")}
                className={classes.login_button}
                handleClick={OtpLogin}
                disabled={loginContext?.onetimePassword.length !== 6}
              />
            </Box>
          </>
        )}
        {errorMessage && (
          <Box sx={{ py: 1 }} color={"red"} fontWeight={"bold"}>
            {errorMessage}
          </Box>
        )}
        {isLoading && <CircularProgressDialog title={t("login.isLogging")} />}
      </Stack>
    </>
  );
};

export default LoginForm;
