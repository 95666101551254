import { useQuery } from "react-query";
import axios from "axios";
import NoticeResponse from "../../types/response/NoticeResponse";
import NoticeRequest from "../../types/request/NoticeRequest";

const NoticeApi = {
  /**
   * お知らせ一覧取得API
   * @param request
   */
  GetNotices: (onSuccess, onError) => {
    return useQuery(
      "getNotices",
      async () => {
        const response = await axios.get<NoticeResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/notice",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * お知らせ削除API
   */
  DeleteNotice: (idList: number[], onSuccess, onError) => {
    return useQuery(
      "deleteNotice",
      async () => {
        const response = await axios.delete(
          process.env.REACT_APP_HOST_URL + "/v1/admin/notice",
          {
            data: {id_list: idList},
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * お知らせ登録API
   */
  RegisterNotice: (request: NoticeRequest | undefined, onSuccess, onError) => {
    return useQuery(
      "registerNotice",
      async () => {
        if (request !== undefined) {
          const response = await axios.post(
            process.env.REACT_APP_HOST_URL + "/v1/admin/notice",
            request,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
              withCredentials: true,
            }
          );
          return response.data;
        } else {
          return {
            "message": "Error Message"
          }
        }
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default NoticeApi;
