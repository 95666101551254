import {
  defaultMasterDatas,
  MasterDatas,
} from "../types/response/MasterDataResponse.tsx";
import RequestID from "../types/request/RequestID.tsx";
import RequestScopeType from "../types/request/RequestScopeType.tsx";
import RequestCode from "../types/request/RequestCode.tsx";
import { useContext } from "react";
import DatamartSearchContext from "../contexts/DatamartSearchContext.tsx";
import CheckBoxDataType, {
  CheckBoxType,
  defaultCheckBoxType,
} from "../types/SearchCheckBoxType.tsx";
import DatamartEmissionsData from "../types/response/DatamartEmissionsData.tsx";
import { useTranslation } from "react-i18next";
import MasterContext from "../contexts/MasterContext.tsx";
import DatamartProductRequest from "../types/request/DatamartProductRequest.tsx";
import { ProductDashboardType } from "../types/enum/DashboardTypeEnum.tsx";
import AppContext from "../contexts/AppContext.tsx";

// 検索機能の共通処理
const SearchFeatures = () => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const {
    searchData,
    setSearchData,
    checkBoxData,
    setCheckBoxData,
    setSearch,
  } = useContext(DatamartSearchContext);
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;

  /**
   * マスターデータに基づいて、検索条件を設定
   * @param data
   */
  const setSearchDatabyMasterData = (data: MasterDatas) => {
    const scopes = data.scopes;
    const organizations = data.organizations;
    const energies = data.energies;
    const major_categories = data.major_categories;
    const middle_categories = data.middle_categories;
    const minor_categories = data.minor_categories;
    const products = data.products;
    const parts_or_energies = data.parts_or_energies;
    //スコープ
    const checkBox: CheckBoxDataType = defaultCheckBoxType;
    const searchScope: RequestScopeType[] = [];
    const labelsScope: string[] = [];
    scopes.forEach((value) => {
      if (!labelsScope.includes(value.name)) {
        checkBox.scopes[value.name] = {
          label: value.name,
          code: value.scope_type,
          checked: true,
        };
        labelsScope.push(value.name);
      }
      // searchScope.push({
      //   scope_type: Number(value.scope_type),
      //   name: value.name,
      // });
    });
    //ユーザー
    const searchOrganizations: RequestID[] = [];
    const labelsOrganizations: string[] = [];
    organizations.forEach((value) => {
      if (!labelsOrganizations.includes(value.name)) {
        checkBox.organizations[value.name] = {
          label: value.name,
          code: value.id,
          checked: true,
        };
        labelsOrganizations.push(value.name);
      }
      // searchOrganizations.push({
      //   id: value.id,
      //   name: value.name,
      // });
    });
    //エネルギー
    const searchEnergies: RequestID[] = [];
    const labelsEnergies: string[] = [];
    energies.forEach((value) => {
      if (value.scope_code && !labelsEnergies.includes(value.name)) {
        checkBox.energies[value.name] = {
          label: value.name,
          code: value.id,
          checked: true,
        };
        labelsEnergies.push(value.name);
      }
      // searchEnergies.push({
      //   id: value.id,
      //   name: value.name,
      // });
    });
    //大分類
    const searchMajor: RequestCode[] = [];
    const labelsMajor: string[] = [];
    major_categories.forEach((value) => {
      if (!labelsMajor.includes(value.name)) {
        checkBox.major_categories[value.name] = {
          label: value.name,
          code: value.code,
          checked: true,
        };
        labelsMajor.push(value.name);
      }
      // searchMajor.push({
      //   code: value.code,
      //   name: value.name,
      // });
    });
    //中分類
    const searchMiddle: RequestCode[] = [];
    const labelsMiddle: string[] = [];
    middle_categories.forEach((value) => {
      if (!labelsMiddle.includes(value.name)) {
        checkBox.middle_categories[value.name] = {
          label: value.name,
          code: value.code,
          checked: true,
        };
        labelsMiddle.push(value.name);
      }
      // searchMiddle.push({
      //   code: value.code,
      //   name: value.name,
      // });
    });
    //小分類
    const searchMinor: RequestCode[] = [];
    const labelsMinor: string[] = [];
    minor_categories.forEach((value) => {
      if (!labelsMinor.includes(value.name)) {
        checkBox.minor_categories[value.name] = {
          label: value.name,
          code: value.code,
          checked: true,
        };
        labelsMinor.push(value.name);
      }
      // searchMinor.push({
      //   code: value.code,
      //   name: value.name,
      // });
    });
    //製品
    const searchProduct: RequestID[] = [];
    const labelsProduct: string[] = [];
    products.forEach((value, index) => {
      if (!labelsProduct.includes(value.name)) {
        checkBox.products[value.name] = {
          label: value.name,
          code: value.id,
          checked: true,
        };
        if (appContext?.sideItem !== "facility" && index === 0) {
          searchProduct.push({
            id: value.id,
            name: value.name,
          });
        }
        labelsProduct.push(value.name);
      }
    });
    //部品/エネルギー
    const searchParts: RequestID[] = [];
    const labelsParts: string[] = [];
    parts_or_energies.forEach((value) => {
      if (!labelsParts.includes(value.name)) {
        checkBox.parts_or_energies[value.name] = {
          label: value.name,
          code: value.id,
          checked: true,
        };
        labelsParts.push(value.name);
      }
      // searchParts.push({
      //   id: value.id,
      //   name: value.name,
      // });
    });
    searchData.filter_params.from_base_year_month =
      data.from_initial_year_month;
    searchData.filter_params.from_target_year_month =
      data.from_initial_year_month;
    searchData.filter_params.to_base_year_month = data.to_initial_year_month;
    searchData.filter_params.to_target_year_month = data.to_initial_year_month;
    searchData.filter_organization_params.from_month =
      data.from_initial_year_month;
    searchData.filter_organization_params.to_month = data.to_initial_year_month;
    searchData.filter_organization_params.scopes = searchScope;
    searchData.filter_organization_params.organizations = searchOrganizations;
    searchData.filter_organization_params.energies = searchEnergies;
    searchData.filter_params.major_categories = searchMajor;
    searchData.filter_params.middle_categories = searchMiddle;
    searchData.filter_params.minor_categories = searchMinor;
    searchData.filter_params.products = searchProduct;
    searchData.filter_params.parts_or_energies = searchParts;
    searchData.filter_params.self_manufacturing = [];
    setSearchData(Object.assign({}, searchData));
    setCheckBoxData(Object.assign({}, checkBoxData));
  };

  /**
   * 製品のセレクト要素作成
   * @param data
   * @returns
   */
  const createSelectProduct = (data: MasterDatas) => {
    const values: { value: string; label: string }[] = [];
    const labels: string[] = [];
    if (appContext?.sideItem === "facility") {
      values.push({
        value: "all",
        label: t("common.selectAll"),
      });
      labels.push(t("common.selectAll"));
    }
    if (!data.products) {
      return values;
    }
    data.products.forEach((product) => {
      if (!labels.includes(product.name)) {
        values.push({
          value: product.name,
          label: product.name,
        });
        labels.push(product.name);
      }
    });
    return values;
  };

  /**
   * 小分類のセレクト要素作成
   * @param data
   * @returns
   */
  const createSelectMinorCategories = (
    data: MasterDatas,
    checkBox: CheckBoxDataType
  ) => {
    const values = [
      {
        value: "all",
        label: t("common.selectAll"),
      },
    ];
    if (!data.minor_categories) {
      return values;
    }
    const labels: string[] = [];
    // チェック状態となる大分類
    const checkedMajors = Object.values(checkBox.major_categories).filter(
      (val) => {
        return val.checked;
      }
    );
    // チェック状態となる中分類
    const checkedMiddles = Object.values(checkBox.middle_categories).filter(
      (val) => {
        return val.checked;
      }
    );

    /// マスターデータから大分類・中分類コードに紐づく小分類データを抽出
    const minors = masterData.minor_categories.filter((minor) => {
      return (
        checkedMajors.find((major) => {
          return major.code === minor.major_category_code;
        }) &&
        checkedMiddles.find((middle) => {
          return middle.code === minor.middle_category_code;
        })
      );
    });
    Object.values(checkBox.minor_categories).forEach((data) => {
      if (
        minors.find((minor) => {
          return minor.name === data.label;
        }) !== undefined &&
        !labels.includes(data.label)
      ) {
        values.push({
          value: data.label,
          label: data.label,
        });
        labels.push(data.label);
      }
    });
    return values;
  };

  /**
   * カテゴリのセレクト要素作成
   * @param data
   * @returns
   */
  const createSelectSelfManufacturing = (data: MasterDatas) => {
    const values = [
      {
        value: "all",
        label: t("common.selectAll"),
      },
    ];
    if (!data.self_manufacturing) {
      return values;
    }
    data.self_manufacturing.forEach((self_manufacturing) => {
      values.push({
        value: self_manufacturing.code,
        label: self_manufacturing.name,
      });
    });
    return values;
  };

  /**
   * チェックボックス用データ作成
   * エネルギー用
   * @param CheckBoxItems
   * @returns
   */
  const getCheckBoxInEnergiesData = (CheckBoxItems: CheckBoxDataType) => {
    const results = {};
    if (
      !Object.keys(CheckBoxItems.scopes).length ||
      !Object.keys(CheckBoxItems.energies).length
    ) {
      return results;
    }
    // チェック状態となるスコープデータ
    const checkedScopes = Object.values(CheckBoxItems.scopes).filter((val) => {
      return val.checked;
    });

    /// マスターデータからスコープコードに紐づくエネルギーデータを抽出
    const energies = masterData.energies.filter((energy) => {
      return checkedScopes.find((scope) => {
        return scope.code === energy.scope_code;
      });
    });

    Object.values(CheckBoxItems.energies).forEach((data) => {
      if (
        energies.find((energy) => {
          return energy.name === data.label;
        }) !== undefined
      ) {
        results[data.label] = data;
      }
    });
    return results;
  };

  /**
   * チェックボックス用データ作成
   * 中分類用
   * @param CheckBoxItems
   * @returns
   */
  const getCheckBoxInMiddleCategory = (CheckBoxItems: CheckBoxDataType) => {
    const results = {};
    if (
      !Object.keys(CheckBoxItems.major_categories).length ||
      !Object.keys(CheckBoxItems.middle_categories).length
    ) {
      return results;
    }
    // チェック状態となる大分類
    const checkedMajors = Object.values(CheckBoxItems.major_categories).filter(
      (val) => {
        return val.checked;
      }
    );

    const majors = masterData.major_categories.filter((major) => {
      return checkedMajors.find((data) => {
        return data.label === major.name;
      });
    });

    /// マスターデータから大分類コードに紐づく中分類データを抽出
    const middles = masterData.middle_categories.filter((middle) => {
      return majors.find((major) => {
        return major.code === middle.major_category_code;
      });
    });

    Object.values(CheckBoxItems.middle_categories).forEach((data) => {
      if (
        middles.find((middle) => {
          return middle.name === data.label;
        }) !== undefined
      ) {
        results[data.label] = data;
      }
    });
    return results;
  };

  /**
   * チェックボックス用データ作成
   * 小分類用
   * @param CheckBoxItems
   * @returns
   */
  const getCheckBoxInMinorCategory = (CheckBoxItems: CheckBoxDataType) => {
    const results = {};
    if (
      !Object.keys(CheckBoxItems.major_categories).length ||
      !Object.keys(CheckBoxItems.middle_categories).length ||
      !Object.keys(CheckBoxItems.minor_categories).length
    ) {
      return results;
    }
    // チェック状態となる大分類
    const checkedMajors = Object.values(CheckBoxItems.major_categories).filter(
      (val) => {
        return val.checked;
      }
    );
    // チェック状態となる中分類
    const checkedMiddles = Object.values(
      CheckBoxItems.middle_categories
    ).filter((val) => {
      return val.checked;
    });

    const majors = masterData.major_categories.filter((major) => {
      return checkedMajors.find((data) => {
        return data.label === major.name;
      });
    });

    const middles = masterData.middle_categories.filter((middle) => {
      return checkedMiddles.find((data) => {
        return data.label === middle.name;
      });
    });

    /// マスターデータから大分類・中分類コードに紐づく小分類データを抽出
    const minors = masterData.minor_categories.filter((minor) => {
      return (
        majors.find((major) => {
          return major.code === minor.major_category_code;
        }) &&
        middles.find((middle) => {
          return middle.code === minor.middle_category_code;
        })
      );
    });

    Object.values(CheckBoxItems.minor_categories).forEach((data) => {
      if (
        minors.find((minor) => {
          return minor.name === data.label;
        }) !== undefined
      ) {
        results[data.label] = data;
      }
    });
    return results;
  };

  /**
   * チェックボックス用データ作成
   * 部品/エネルギー用
   * @param CheckBoxItems
   * @returns
   */
  const getCheckBoxInPartsOrEnegies = (CheckBoxItems: CheckBoxDataType) => {
    const results = {};
    if (
      !Object.keys(CheckBoxItems.major_categories).length ||
      !Object.keys(CheckBoxItems.middle_categories).length ||
      !Object.keys(CheckBoxItems.minor_categories).length ||
      !Object.keys(CheckBoxItems.parts_or_energies).length
    ) {
      return results;
    }
    // チェック状態となる大分類
    const checkedMajors = Object.values(CheckBoxItems.major_categories).filter(
      (val) => {
        return val.checked;
      }
    );
    // チェック状態となる中分類
    const checkedMiddles = Object.values(
      CheckBoxItems.middle_categories
    ).filter((val) => {
      return val.checked;
    });
    // チェック状態となる小分類
    const checkedMinors = Object.values(CheckBoxItems.minor_categories).filter(
      (val) => {
        return val.checked;
      }
    );

    const majors = masterData.major_categories.filter((major) => {
      return checkedMajors.find((data) => {
        return data.label === major.name;
      });
    });

    const middles = masterData.middle_categories.filter((middle) => {
      return checkedMiddles.find((data) => {
        return data.label === middle.name;
      });
    });

    const minors = masterData.minor_categories.filter((minor) => {
      return checkedMinors.find((data) => {
        return data.label === minor.name;
      });
    });

    /// マスターデータから大分類・中分類・小分類コードに紐づく部品/エネルギーデータを抽出
    const parts = masterData.parts_or_energies.filter((part) => {
      return (
        majors.find((major) => {
          return major.code === part.major_category_code;
        }) &&
        middles.find((middle) => {
          return middle.code === part.middle_category_code;
        }) &&
        minors.find((minor) => {
          return minor.code === part.minor_category_code;
        })
      );
    });

    Object.values(CheckBoxItems.parts_or_energies).forEach((data) => {
      if (
        parts.find((part) => {
          return part.name === data.label;
        }) !== undefined
      ) {
        results[data.label] = data;
      }
    });
    return results;
  };

  /**
   * セレクト選択値を検索条件に設定する
   * @param name
   * @param type
   * @param masterDatas
   */
  const setSearchDataBySelect = (
    name: string,
    type: string,
    masterDatas: MasterDatas,
    noSearch?: boolean
  ) => {
    switch (type) {
      case "products":
        if (name === "all") {
          searchData.filter_params.products = [];
        } else {
          const productData = masterDatas.products.filter((val) => {
            return val.name === name;
          });
          const products: RequestID[] = [];
          if (productData.length) {
            productData.forEach((val) => {
              products.push({
                id: val.id,
                name: val.name,
              });
            });
          }
          searchData.filter_params.products = products;
        }
        break;
      case "minor_categories":
        if (name === "all") {
          searchData.filter_params.minor_categories = [];
        } else {
          const minorData = masterDatas.minor_categories.filter((val) => {
            return val.name === name;
          });
          const minors: RequestCode[] = [];
          if (minorData.length) {
            minorData.forEach((val) => {
              minors.push({
                code: val.code,
                name: val.name,
              });
            });
          }
          searchData.filter_params.minor_categories = minors;
        }
        break;
      case "self_manufacturing":
        if (name === "all") {
          searchData.filter_params.self_manufacturing = [];
        } else {
          const selfManufacturingData = masterDatas.self_manufacturing.find(
            (val) => {
              return val.code === name;
            }
          );
          if (selfManufacturingData) {
            searchData.filter_params.self_manufacturing = [
              {
                code: selfManufacturingData.code,
                name: selfManufacturingData.name,
              },
            ];
          }
        }
        break;
    }
    setSearchData(searchData);
    if (!noSearch) setSearch(true);
  };

  /**
   * セレクト選択値を検索条件に設定する
   * @param name
   * @param type
   * @param masterDatas
   */
  const selectCellByHeatMap = (
    product: string,
    code: string,
    codeType: string,
    masterDatas: MasterDatas
  ) => {
    const productData = masterDatas.products.filter((val) => {
      return val.id === product;
    });
    if (product) {
      const products: RequestID[] = [];
      if (productData.length) {
        productData.forEach((val) => {
          products.push({
            id: val.id,
            name: val.name,
          });
        });
      }
      searchData.filter_params.products = products;
    }

    switch (codeType) {
      case "major_categories":
        if (code === "all") {
          searchData.filter_params.major_categories = [];
        } else {
          const majorData = masterDatas.major_categories.filter((val) => {
            return val.code === code;
          });
          const majors: RequestCode[] = [];
          if (majorData.length) {
            majorData.forEach((val) => {
              majors.push({
                code: val.code,
                name: val.name,
              });
            });
          }
          searchData.filter_params.major_categories = majors;
        }
        break;
      case "middle_categories":
        if (code === "all") {
          searchData.filter_params.middle_categories = [];
        } else {
          const middleData = masterDatas.middle_categories.filter((val) => {
            return val.code === code;
          });
          const middles: RequestCode[] = [];
          if (middleData.length) {
            middleData.forEach((val) => {
              middles.push({
                code: val.code,
                name: val.name,
              });
            });
          }
          searchData.filter_params.middle_categories = middles;
        }
        break;
      case "minor_categories":
        if (code === "all") {
          searchData.filter_params.minor_categories = [];
        } else {
          const minorData = masterDatas.minor_categories.filter((val) => {
            return val.code === code;
          });
          const minors: RequestCode[] = [];
          if (minorData.length) {
            minorData.forEach((val) => {
              minors.push({
                code: val.code,
                name: val.name,
              });
            });
          }
          searchData.filter_params.minor_categories = minors;
        }
        break;
      case "parts_or_energies":
        if (code === "all") {
          searchData.filter_params.parts_or_energies = [];
        } else {
          const partsData = masterDatas.parts_or_energies.filter((val) => {
            return val.id === code;
          });
          const partsEnergies: RequestID[] = [];
          if (partsData.length) {
            partsData.forEach((val) => {
              partsEnergies.push({
                id: val.id,
                name: val.name,
              });
            });
          }
          searchData.filter_params.parts_or_energies = partsEnergies;
        }
        break;
    }
    setSearchData(searchData);
    setSearch(true);
  };

  /**
   * Scopeの選択値から外れた項目をリクエストパラメータから外す処理
   * @param checkedItems
   * @returns
   */
  const getSearchDataEnergies = (checkedItems: {
    [key: string]: CheckBoxType;
  }): Array<object> => {
    const searchDataEnergies: Array<object> = [];
    // チェック状態となるスコープデータ
    const checkedScopes = Object.values(checkedItems).filter((val) => {
      return val.checked;
    });

    /// マスターデータからスコープコードに紐づくエネルギーデータを抽出
    const energies = masterData.energies.filter((energy) => {
      return checkedScopes.find((scope) => {
        return scope.code === energy.scope_code;
      });
    });

    Object.values(checkBoxData.energies).forEach((data) => {
      if (
        energies.find((energy) => {
          return energy.name === data.label;
        }) !== undefined
      ) {
        searchDataEnergies.push({
          id: data.code,
          name: data.label,
        });
      }
    });
    return searchDataEnergies;
  };

  /**
   *
   * @param checkedItems
   */
  const getSearchDataMiddle = (checkedItems: {
    [key: string]: CheckBoxType;
  }) => {
    const searchDataMiddle: Array<object> = [];
    // チェック状態となる大分類
    const checkedMajors = Object.values(checkedItems).filter((val) => {
      return val.checked;
    });

    const majors = masterData.major_categories.filter((major) => {
      return checkedMajors.find((data) => {
        return data.label === major.name;
      });
    });

    /// マスターデータから大分類コードに紐づく中分類データを抽出
    const middles = masterData.middle_categories.filter((middle) => {
      return majors.find((major) => {
        return major.code === middle.major_category_code;
      });
    });

    Object.values(masterData.middle_categories).forEach((data) => {
      if (
        middles.find((middle) => {
          return middle.name === data.name;
        }) !== undefined
      ) {
        searchDataMiddle.push({
          code: data.code,
          name: data.name,
        });
      }
    });
    return searchDataMiddle;
  };

  /**
   *
   * @param checkedItems
   */
  const getSearchDataMinor = (
    checkedItems: { [key: string]: CheckBoxType },
    type: string
  ) => {
    const searchDataMinor: Array<object> = [];
    // チェック状態となる大分類
    const checkedMajors =
      type === "major_categories"
        ? Object.values(checkedItems).filter((val) => {
            return val.checked;
          })
        : Object.values(checkBoxData.major_categories).filter((val) => {
            return val.checked;
          });
    // チェック状態となる中分類
    const checkedMiddles =
      type === "major_categories"
        ? Object.values(checkBoxData.middle_categories).filter((val) => {
            return val.checked;
          })
        : Object.values(checkedItems).filter((val) => {
            return val.checked;
          });

    const majors = masterData.major_categories.filter((major) => {
      return checkedMajors.find((data) => {
        return data.label === major.name;
      });
    });

    const middles = masterData.middle_categories.filter((middle) => {
      return checkedMiddles.find((data) => {
        return data.label === middle.name;
      });
    });

    /// マスターデータから大分類・中分類コードに紐づく小分類データを抽出
    const minors = masterData.minor_categories.filter((minor) => {
      return (
        majors.find((major) => {
          return major.code === minor.major_category_code;
        }) &&
        middles.find((middle) => {
          return middle.code === minor.middle_category_code;
        })
      );
    });

    Object.values(masterData.minor_categories).forEach((data) => {
      if (
        minors.find((minor) => {
          return minor.name === data.name;
        }) !== undefined
      ) {
        searchDataMinor.push({
          code: data.code,
          name: data.name,
        });
      }
    });
    return searchDataMinor;
  };

  /**
   *
   * @param checkedItems
   */
  const getSearchDataPartsOrEnergies = (
    checkedItems: { [key: string]: CheckBoxType },
    type: string
  ) => {
    const searchDataPartsOrEnergies: Array<object> = [];
    // チェック状態となる大分類
    let checkedMajors: CheckBoxType[] = [];
    // チェック状態となる中分類
    let checkedMiddles: CheckBoxType[] = [];
    // チェック状態となる小分類
    let checkedMinors: CheckBoxType[] = [];
    switch (type) {
      case "major_categories":
        checkedMajors = Object.values(checkedItems).filter((val) => {
          return val.checked;
        });
        checkedMiddles = Object.values(checkBoxData.middle_categories).filter(
          (val) => {
            return val.checked;
          }
        );
        checkedMinors = Object.values(checkBoxData.minor_categories).filter(
          (val) => {
            return val.checked;
          }
        );
        break;
      case "middle_categories":
        checkedMajors = Object.values(checkBoxData.major_categories).filter(
          (val) => {
            return val.checked;
          }
        );
        checkedMiddles = Object.values(checkedItems).filter((val) => {
          return val.checked;
        });
        checkedMinors = Object.values(checkBoxData.minor_categories).filter(
          (val) => {
            return val.checked;
          }
        );
        break;
      case "minor_categories":
        checkedMajors = Object.values(checkBoxData.major_categories).filter(
          (val) => {
            return val.checked;
          }
        );
        checkedMiddles = Object.values(checkBoxData.middle_categories).filter(
          (val) => {
            return val.checked;
          }
        );
        checkedMinors = Object.values(checkedItems).filter((val) => {
          return val.checked;
        });
        break;
    }

    const majors = masterData.major_categories.filter((major) => {
      return checkedMajors.find((data) => {
        return data.label === major.name;
      });
    });

    const middles = masterData.middle_categories.filter((middle) => {
      return checkedMiddles.find((data) => {
        return data.label === middle.name;
      });
    });

    const minors = masterData.minor_categories.filter((minor) => {
      return checkedMinors.find((data) => {
        return data.label === minor.name;
      });
    });

    /// マスターデータから大分類・中分類・小分類コードに紐づく部品/エネルギーデータを抽出
    const parts = masterData.parts_or_energies.filter((part) => {
      return (
        majors.find((major) => {
          return major.code === part.major_category_code;
        }) &&
        middles.find((middle) => {
          return middle.code === part.middle_category_code;
        }) &&
        minors.find((minor) => {
          return minor.code === part.minor_category_code;
        })
      );
    });

    Object.values(masterData.parts_or_energies).forEach((data) => {
      if (
        parts.find((part) => {
          return part.name === data.name;
        }) !== undefined
      ) {
        searchDataPartsOrEnergies.push({
          id: data.id,
          name: data.name,
        });
      }
    });
    return searchDataPartsOrEnergies;
  };

  /**
   * 検索条件設定
   * @param checkedItems
   */
  const setSearchDataBySelectData = (
    checkedItems: { [key: string]: CheckBoxType },
    type: string,
    allChecked: boolean
  ) => {
    if (!Object.entries(checkedItems).length) return searchData;
    let searchDataResult: Array<object> = [];
    let searchDataEnergies: Array<object> = [];
    let searchDataMiddle: Array<object> = [];
    let searchDataMinor: Array<object> = [];
    let searchDataPartsOrEnergies: Array<object> = [];
    //リクエストパラメータデータ生成
    switch (type) {
      case "scopes":
        Object.values(checkedItems).forEach((val, index) => {
          if (val.checked && !allChecked) {
            searchDataResult.push({
              scope_type: val.code,
              name: val.label,
            });
          }
          checkBoxData[type][val.label] = val;
        });
        searchDataEnergies = getSearchDataEnergies(checkedItems);
        searchData.filter_organization_params.energies =
          searchDataEnergies as RequestID[];
        break;
      case "energies":
      case "organizations":
      case "products":
      case "parts_or_energies":
        Object.values(checkedItems).forEach((val, index) => {
          if (val.checked && !allChecked) {
            searchDataResult.push({
              id: val.code,
              name: val.label,
            });
          }
          checkBoxData[type][val.label] = val;
        });
        break;
      case "major_categories":
        Object.values(checkedItems).forEach((val, index) => {
          if (val.checked && !allChecked) {
            searchDataResult.push({
              code: val.code,
              name: val.label,
            });
          }
          checkBoxData[type][val.label] = val;
        });
        // 大分類の選択値から外れた項目をリクエストパラメータから外す処理
        searchDataMiddle = getSearchDataMiddle(checkedItems);
        searchDataPartsOrEnergies = getSearchDataPartsOrEnergies(
          checkedItems,
          type
        );
        searchData.filter_params.middle_categories =
          searchDataMiddle as RequestCode[];
        searchData.filter_params.parts_or_energies =
          searchDataPartsOrEnergies as RequestID[];
        if (appContext?.sideItem !== "facility") {
          searchDataMinor = getSearchDataMinor(checkedItems, type);
          searchData.filter_params.minor_categories =
            searchDataMinor as RequestCode[];
        }
        break;
      case "middle_categories":
        Object.values(checkedItems).forEach((val, index) => {
          if (val.checked && !allChecked) {
            searchDataResult.push({
              code: val.code,
              name: val.label,
            });
          }
          checkBoxData[type][val.label] = val;
        });
        // 中分類の選択値から外れた項目をリクエストパラメータから外す処理
        if (appContext?.sideItem !== "facility") {
          searchDataMinor = getSearchDataMinor(checkedItems, type);
          searchData.filter_params.minor_categories =
            searchDataMinor as RequestCode[];
        }
        searchDataPartsOrEnergies = getSearchDataPartsOrEnergies(
          checkedItems,
          type
        );
        searchData.filter_params.parts_or_energies =
          searchDataPartsOrEnergies as RequestID[];
        break;
      case "minor_categories":
        Object.values(checkedItems).forEach((val, index) => {
          if (val.checked && !allChecked) {
            searchDataResult.push({
              code: val.code,
              name: val.label,
            });
          }
          checkBoxData[type][val.label] = val;
        });
        // 小分類の選択値から外れた項目をリクエストパラメータから外す処理
        searchDataPartsOrEnergies = getSearchDataPartsOrEnergies(
          checkedItems,
          type
        );
        searchData.filter_params.parts_or_energies =
          searchDataPartsOrEnergies as RequestID[];
        break;
    }
    //リクエストパラメータデータ設定
    switch (type) {
      case "organizations":
        searchData.filter_organization_params.organizations =
          searchDataResult as RequestID[];
        break;
      case "scopes":
        searchData.filter_organization_params.scopes =
          searchDataResult as RequestScopeType[];
        break;
      case "energies":
        searchData.filter_organization_params.energies =
          searchDataResult as RequestID[];
        break;
      case "major_categories":
        searchData.filter_params.major_categories =
          searchDataResult as RequestCode[];
        break;
      case "middle_categories":
        searchData.filter_params.middle_categories =
          searchDataResult as RequestCode[];
        break;
      case "minor_categories":
        searchData.filter_params.minor_categories =
          searchDataResult as RequestCode[];
        break;
      case "products":
        searchData.filter_params.products = searchDataResult as RequestID[];
        break;
      case "parts_or_energies":
        searchData.filter_params.parts_or_energies =
          searchDataResult as RequestID[];
        break;
    }
    setSearchData(Object.assign({}, searchData));
    setCheckBoxData(Object.assign({}, checkBoxData));
  };

  /**
   * 円グラフにて選択した項目の絞り込み処理を実行
   * @param data
   * @param type
   */
  const selectCellByPieGraph = (data: DatamartEmissionsData, type: string) => {
    const searchDataResult: Array<object> = [];
    switch (type) {
      case "scopes":
        Object.values(checkBoxData.scopes).forEach((scope) => {
          if (scope.code === data.id) {
            searchDataResult.push({
              scope_type: data.id,
              name: data.name,
            });
            checkBoxData.scopes[scope.label].checked = true;
          } else {
            checkBoxData.scopes[scope.label].checked = false;
          }
        });
        setSearchDataBySelectData(checkBoxData.scopes, type, false);
        break;
      case "energies":
        Object.values(checkBoxData.energies).forEach((energy) => {
          if (energy.code === data.id) {
            searchDataResult.push({
              id: data.id,
              name: data.name,
            });
            checkBoxData.energies[energy.label].checked = true;
          } else {
            checkBoxData.energies[energy.label].checked = false;
          }
        });
        setSearchDataBySelectData(checkBoxData.energies, type, false);
        break;
      case "organizations":
        Object.values(checkBoxData.organizations).forEach((organization) => {
          if (organization.code === data.id) {
            searchDataResult.push({
              id: data.id,
              name: data.name,
            });
            checkBoxData.organizations[organization.label].checked = true;
          } else {
            checkBoxData.organizations[organization.label].checked = false;
          }
        });
        setSearchDataBySelectData(checkBoxData.organizations, type, false);
        break;
    }
    setSearch(true);
  };

  /**
   * 円グラフにて選択した項目を外した状態の絞り込み処理を実行
   * @param data
   * @param type
   */
  const removeSelectCellByPieGraph = (type: string) => {
    const searchDataResult: Array<object> = [];
    switch (type) {
      case "scopes":
        Object.values(checkBoxData.scopes).forEach((scope) => {
          searchDataResult.push({
            scope_type: scope.code,
            name: scope.label,
          });
          checkBoxData.scopes[scope.label].checked = true;
        });
        setSearchDataBySelectData(checkBoxData.scopes, type, false);
        break;
      case "energies":
        Object.values(checkBoxData.energies).forEach((energy) => {
          searchDataResult.push({
            id: energy.code,
            name: energy.label,
          });
          checkBoxData.energies[energy.label].checked = true;
        });
        setSearchDataBySelectData(checkBoxData.energies, type, false);
        break;
      case "organizations":
        Object.values(checkBoxData.organizations).forEach((organization) => {
          searchDataResult.push({
            id: organization.code,
            name: organization.label,
          });
          checkBoxData.organizations[organization.label].checked = true;
        });
        setSearchDataBySelectData(checkBoxData.organizations, type, false);
        break;
    }
    setSearch(true);
  };

  /**
   * 横棒グラフにて選択した項目の絞り込み処理を実行
   * @param data
   * @param type
   */
  const selectCellByBesideBar = (selectCells: {
    [key: string]: RequestCode[];
  }) => {
    let param = {} as DatamartProductRequest;
    const searchDataCopy = structuredClone(searchData);
    searchDataCopy.filter_params.self_manufacturing = [];
    Object.keys(selectCells).forEach((key) => {
      switch (key) {
        case "by_energy":
          searchDataCopy.filter_params.parts_or_energies = selectCells[key].map(
            (val) => {
              return {
                id: val.code,
                name: val.name,
              };
            }
          );
          break;
        case "by_organization":
          selectCells[key].forEach((val) => {
            if (val.code === "01") {
              searchDataCopy.filter_params.self_manufacturing =
                selectCells[key];
            } else {
              searchDataCopy.filter_params.minor_categories = selectCells[key];
            }
          });
          break;
        case "in_major_category":
          searchDataCopy.filter_params.self_manufacturing = selectCells[key];
          break;
        case "in_own_middle_category":
          searchDataCopy.filter_params.self_manufacturing = [
            {
              code: "01",
              name: "自社製造",
            },
          ];
          searchDataCopy.filter_params.middle_categories = selectCells[key];
          break;
        case "in_other_middle_category":
          searchDataCopy.filter_params.self_manufacturing = [
            {
              code: "99",
              name: "取引先・その他",
            },
          ];
          searchDataCopy.filter_params.middle_categories = selectCells[key];
          break;
        case "in_own_minor_category":
          searchDataCopy.filter_params.self_manufacturing = [
            {
              code: "01",
              name: "自社製造",
            },
          ];
          searchDataCopy.filter_params.minor_categories = selectCells[key];
          break;
        case "in_other_minor_category":
          searchDataCopy.filter_params.self_manufacturing = [
            {
              code: "99",
              name: "取引先・その他",
            },
          ];
          searchDataCopy.filter_params.minor_categories = selectCells[key];
          break;
      }
    });
    param.dashboard_types = ProductDashboardType;
    param.filter_params = searchDataCopy.filter_params;
    param.gco2e_unit_type = searchDataCopy.gco2e_unit_type;
    param.period_type = searchDataCopy.period_type;
    return param;
  };

  /**
   * 横棒グラフにて選択した項目を外した場合の絞り込み処理を実行
   * @param data
   * @param type
   */
  const removeSelectCellByBesideBar = (
    data: DatamartEmissionsData,
    type: string
  ) => {};

  /**
   * 検索条件に応じて、選択値を設定する
   */
  const setSelectItem = (
    type,
    selectItems?: {
      value: string;
      label: string;
    }[]
  ) => {
    if (type === "products") {
      const products = searchData.filter_params.products;
      if (products && products.length !== 0) {
        return products[0].name;
      }
      return masterData.products && masterData.products.length !== 0
        ? masterData.products[0].name
        : "";
    } else if (type === "minor_categories") {
      const minor_categories = searchData.filter_params.minor_categories;
      if (minor_categories && minor_categories.length !== 0) {
        if (
          minor_categories.find((val) => {
            return val.code !== minor_categories[0].code;
          }) !== undefined
        ) {
          return "all";
        }
        if (
          selectItems?.length &&
          selectItems.find((val) => {
            return val.value === minor_categories[0].name;
          }) !== undefined
        ) {
          return minor_categories[0].name;
        }
      }
      return "all";
    } else if (type === "self_manufacturing") {
      const self_manufacturing = searchData.filter_params.self_manufacturing;
      if (self_manufacturing && self_manufacturing.length !== 0) {
        return self_manufacturing[0].code;
      }
      return "all";
    }
    return "";
  };

  return {
    setSearchDatabyMasterData,
    createSelectProduct,
    createSelectMinorCategories,
    createSelectSelfManufacturing,
    getCheckBoxInMiddleCategory,
    getCheckBoxInMinorCategory,
    getCheckBoxInPartsOrEnegies,
    getCheckBoxInEnergiesData,
    setSearchDataBySelect,
    setSearchDataBySelectData,
    selectCellByPieGraph,
    selectCellByHeatMap,
    removeSelectCellByPieGraph,
    selectCellByBesideBar,
    removeSelectCellByBesideBar,
    setSelectItem,
  };
};

export default SearchFeatures;
