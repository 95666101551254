import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React from "react";
import classes from "./SelectItem.module.scss";

type SelectItemProps = {
  title: string;
  selectValue: string;
  values: {
    value: string;
    label: string;
  }[];
  className?: string;
  handleClick: (event: SelectChangeEvent<string>) => void;
};

const SelectItem: React.FC<SelectItemProps> = (props) => {
  return (
    <Box className={props.className}>
      <Typography
        sx={{ fontSize: 8, textAlign: "left", pt: 1, pl: 1 }}
        className={classes.selectCategory}
      >
        {props.title}
      </Typography>
      <Select
        value={props.selectValue}
        onChange={props.handleClick}
        displayEmpty
        className={classes.selectCategory}
        size="small"
        sx={{ fontSize: 8, backgroundColor: "#ffffff" }}
        inputProps={{ "aria-label": "Without label" }}
      >
        {props.values.map((value) => {
          return (
            <MenuItem value={value.value} key={value.value}>
              {value.label}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default SelectItem;
