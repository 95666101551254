import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import React from "react";
import FileUpload from "@mui/icons-material/FileUpload";
import classes from "./FileUploadDialog.module.scss";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

type FileUploadDialogProps = {
  className?: string;
  open: boolean;
  files: File[];
  handleClose: () => void;
  handleUpload: () => void;
  onDrop: (acceptedFiles) => void;
};

const FileUploadDialog: React.FC<FileUploadDialogProps> = (props) => {
  const { t } = useTranslation();
  // ドロップ処理
  const onDrop = (acceptedFiles) => {
    props.onDrop(acceptedFiles);
  };

  // ドラッグ&ドロップ機能の定義設定
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog,
  } = useDropzone({
    onDrop,
    noClick: true,
    accept: {
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-excel": [".xls"],
    },
  });

  return (
    <Dialog
      className={isDragActive ? classes.activeDrag : classes.dialog}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle className={classes.title}>
        {t("dialog.fileUpload.title")}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div
          {...getRootProps({ className: "dropzone" })}
          className={classes.fileList}
        >
          {props.files.length === 0 && (
            <div className={classes.dragAndDropArea}>
              <FileUpload color="disabled" className={classes.fileUpload} />
              <input {...getInputProps()} />
              <p>{t("dialog.fileUpload.dragDrop")}</p>
            </div>
          )}
          <List>
            {props.files.map((file, index) => (
              <ListItem key={index}>
                <ListItemText
                  className={classes.fileName}
                  primary={file.name}
                />
              </ListItem>
            ))}
          </List>
        </div>
        <Button
          variant="contained"
          className={classes.selectFile}
          onClick={openFileDialog}
        >
          {t("dialog.fileUpload.selectFile")}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("dialog.fileUpload.cancel")}
        </Button>
        <Button
          className={classes.button}
          onClick={props.handleUpload}
          color="primary"
          disabled={props.files.length === 0}
        >
          {t("dialog.fileUpload.upload")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUploadDialog;
