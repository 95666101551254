import React, { createContext, useState } from "react";
import DatamartSearchData, {
  defaultDatamartSearchData,
} from "../types/DatamartSearchData.tsx";
import CheckBoxDataType, {
  defaultCheckBoxType,
} from "../types/SearchCheckBoxType.tsx";

type DatamartSearchProps = {
  resetStatus: boolean;
  setResetStatus: React.Dispatch<React.SetStateAction<boolean>>;
  searchData: DatamartSearchData;
  setSearchData: React.Dispatch<React.SetStateAction<DatamartSearchData>>;
  checkBoxData: CheckBoxDataType;
  setCheckBoxData: React.Dispatch<React.SetStateAction<CheckBoxDataType>>;
  exeSearch: boolean;
  setSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchOpen: boolean;
  setSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  unitSwitch: string;
  setUnitSwitch: React.Dispatch<React.SetStateAction<string>>;
};

// データマート検索用コンテキスト
const DatamartSearchContext = createContext<DatamartSearchProps>({
  resetStatus: false,
  setResetStatus: () => {},
  searchData: defaultDatamartSearchData,
  setSearchData: () => {},
  checkBoxData: defaultCheckBoxType,
  setCheckBoxData: () => {},
  exeSearch: false,
  setSearch: () => {},
  searchOpen: false,
  setSearchOpen: () => {},
  unitSwitch: "",
  setUnitSwitch: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const DatamartSearchProvider: React.VFC<Props> = ({ children }) => {
  const [resetStatus, setResetStatus] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<DatamartSearchData>(
    defaultDatamartSearchData
  );
  const [checkBoxData, setCheckBoxData] =
    useState<CheckBoxDataType>(defaultCheckBoxType);
  const [exeSearch, setSearch] = useState<boolean>(true);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [unitSwitch, setUnitSwitch] = useState<string>("company");

  return (
    <DatamartSearchContext.Provider
      value={{
        resetStatus,
        setResetStatus,
        searchData,
        setSearchData,
        checkBoxData,
        setCheckBoxData,
        exeSearch,
        setSearch,
        searchOpen,
        setSearchOpen,
        unitSwitch,
        setUnitSwitch,
      }}
    >
      {children}
    </DatamartSearchContext.Provider>
  );
};

export default DatamartSearchContext;
