import Constant from "./Constant.tsx";

const CheckValue = {
  /**
   * 空データチェック処理
   * @param values
   * @returns
   */
  isEmptyValues: (values: string[]) => {
    for (const i in values) {
      if (!values[i]) return false;
    }
    return true;
  },

  /**
   * バリデーションチェック（数字のみ）
   * @param value
   * @returns
   */
  validationValueNum: (value: string) => {
    const pattern = Constant.REG_EXP_NUM;
    return pattern.test(value);
  },

  /**
   * バリデーションチェック（英数字のみ）
   * @param value
   * @returns
   */
  validationValueNumABC: (value: string) => {
    const pattern = Constant.REG_EXP_NUM_ABC;
    return pattern.test(value);
  },

  /**
   * バリデーションチェック（メールアドレス形式）
   * @param value
   * @returns
   */
  validationValueMail: (value: string) => {
    const pattern = Constant.REG_EXP_MAIL;
    return pattern.test(value);
  },
};

export default CheckValue;
