import { ReactComponent as EnergyImage } from "../../../assets/images/Datamart/icon_energy.svg";
import { ReactComponent as ScopeImage } from "../../../assets/images/Datamart/icon_scope.svg";
import { Box, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import classes from "./PieGraph.module.scss";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  CorporateFare,
  Fullscreen,
  FullscreenExit,
  PhotoCamera,
} from "@mui/icons-material";
import DatamartEmissionsData from "../../../types/response/DatamartEmissionsData";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import Color from "../../../types/Color.tsx";
import CustomTooltip from "./CustomTooltip.tsx";
import { ScreenFuatures } from "../../../features/ScreenFuatures.tsx";
import DatamartCompanyContext from "../../../contexts/DatamartCompanyContext.tsx";
import DatamartFeatures from "../../../features/DatamartFeatures.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";

type PieGraphProps = {
  title: string;
  type: string;
  data: DatamartEmissionsData[];
};

const PieGraph: React.FC<PieGraphProps> = (props) => {
  const { selectCellByPieGraph, removeSelectCellByPieGraph } = SearchFeatures();
  const { getPieChartData } = DatamartFeatures();
  const handle = useFullScreenHandle();
  const { photoSave } = ScreenFuatures();
  const { selectPieGraph, setSelectPieGraph, selectCells, setSelectCells } =
    useContext(DatamartCompanyContext);
  const dispData = getPieChartData(props.data, props.type);
  const total = dispData.reduce((sum, entry) => sum + Number(entry.value), 0);
  const [isHovered, setHovered] = useState(false);

  const getColor = (index: number, id) => {
    if (props.type === "scopes") {
      return Color.scope[Number(id) - 1];
    } else if (props.type === "energies") {
      return Color.pieChart[index % Color.pieChart.length];
    }
    return Color.normal[index % Color.normal.length];
  };

  const getCenterIcon = () => {
    switch (props.type) {
      case "scopes":
        return (
          <ScopeImage
            style={{
              fontSize: handle.active ? "20vw" : "4vw",
              color: "#b8b8b8",
              stroke: "#b8b8b8",
              fill: "#b8b8b8",
            }}
          />
        );
      case "energies":
        return (
          <EnergyImage
            style={{
              fontSize: handle.active ? "20vw" : "4vw",
              color: "#b8b8b8",
              stroke: "#b8b8b8",
              fill: "#b8b8b8",
            }}
          />
        );
      case "organizations":
        return (
          <CorporateFare
            style={{
              fontSize: handle.active ? "20vw" : "3.5vw",
              color: "#b8b8b8",
            }}
          />
        );
    }
  };

  /**
   * 円グラフセル選択処理
   * @param data
   */
  const handleCellClick = (
    event: React.MouseEvent<SVGElement, MouseEvent>,
    data: DatamartEmissionsData
  ) => {
    event.stopPropagation();
    selectCells[props.type] = data.id;
    setSelectCells(selectCells);
    const index = selectPieGraph.indexOf(props.type);
    if (index === -1) {
      selectPieGraph.push(props.type);
      setSelectPieGraph(selectPieGraph);
    }
    selectCellByPieGraph(data, props.type);
  };

  /**
   * 円グラフ枠選択処理
   * @param data
   */
  const handleBoxClick = (e) => {
    const elm = document.getElementById("pieGraphButton_" + props.type);
    if (elm && !elm.contains(e.target)) {
      const index = selectPieGraph.indexOf(props.type);
      if (index !== -1) {
        selectPieGraph.splice(index, 1);
        setSelectPieGraph(selectPieGraph);
        removeSelectCellByPieGraph(props.type);
      }
      delete selectCells[props.type];
      setSelectCells(selectCells);
    }
  };

  return (
    <div
      className={classes.graphBox}
      onClick={handleBoxClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FullScreen handle={handle} className={classes.fullscreenPanel}>
        {handle.active && (
          <Typography
            sx={{
              textAlign: "left",
            }}
            color="text.secondary"
            gutterBottom
            className={classes.title}
          >
            {props.title}
          </Typography>
        )}
        <ResponsiveContainer
          width="100%"
          height="98%"
          className={classes.chart}
          id={"pieGraph_" + props.type}
        >
          <PieChart>
            <Pie
              data={dispData}
              cx="50%"
              cy="50%"
              innerRadius="70%"
              outerRadius="100%"
              startAngle={90} // スタート位置を真上に設定
              endAngle={-270} // エンド位置を調整
              fill="#8884d8"
              dataKey="value"
              activeShape={false}
            >
              {dispData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getColor(index, entry.id)}
                  stroke={
                    selectCells[props.type] === entry.id
                      ? CommonUtils.shadeColor(getColor(index, entry.id), -20)
                      : "none"
                  }
                  strokeWidth={
                    selectCells[props.type] === entry.id ? "0.5vh" : "0.5vh"
                  }
                  onClick={(e) => handleCellClick(e, entry)}
                />
              ))}
            </Pie>
            <Tooltip
              content={(props) => <CustomTooltip {...props} total={total} />}
            />
          </PieChart>
        </ResponsiveContainer>
        <Box
          sx={{
            transform: "translate(-50%, -50%)",
          }}
          className={handle.active ? classes.iconBoxFull : classes.iconBox}
        >
          {getCenterIcon()}
        </Box>
        {isHovered && (
          <Box
            className={classes.buttonDiv}
            id={"pieGraphButton_" + props.type}
          >
            <div
              onClick={() =>
                photoSave(
                  document.getElementById("pieGraph_" + props.type),
                  props.title,
                  handle.active
                )
              }
              className={classes.screenButton}
              style={{ right: handle.active ? "5%" : "10%" }}
            >
              <PhotoCamera />
            </div>
            {!handle.active ? (
              <div onClick={handle.enter} className={classes.fullscreen}>
                <Fullscreen />
              </div>
            ) : (
              <div onClick={handle.exit} className={classes.fullscreen}>
                <FullscreenExit />
              </div>
            )}
          </Box>
        )}
      </FullScreen>
    </div>
  );
};

export default PieGraph;
