import { useContext } from "react";
import UserContext from "../contexts/UserContext.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";
import { useLocation, useNavigate } from "react-router-dom";

// ログイン処理後の共通処理
const MainContentFeatures = () => {
  const userContext = useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * プライマリ・セカンダリユーザー判定処理
   */
  const checkPrimarySecondary = () => {};

  const checkNavigation = (naviFlags) => {
    const pathName = location.pathname;
    if (pathName === "/product" && !naviFlags.productSpecificFlag) {
      return navigate("/home");
    } else if (pathName === "/energy_saving" && !naviFlags.energySavingFlag) {
      return navigate("/home");
    } else if (
      pathName === "/product_free_analysis" &&
      !naviFlags.freeAnalysisFlag
    ) {
      return navigate("/home");
    }
  };

  /**
   * ロール情報、オプションフラグ設定処理
   */
  const setRoleAndOption = () => {
    const user = userContext?.userInfo as UserInfo;
    if (user) {
      const organizations = user.organizations;
      const contracts = user.contracts;
      let role: string[] = [];
      if (organizations && organizations.length) {
        organizations.forEach((val) => {
          role = Array.from(new Set([...role, ...val.role_id]));
        });
        userContext?.setRole(role);
      }
      if (contracts && contracts.length) {
        const energySavingFlag = contracts.some((val) => {
          return user.email === val.email && val.energy_saving_flag === "1";
        });
        const freeAnalysisFlag = contracts.some((val) => {
          return user.email === val.email && val.free_analysis_flag === "1";
        });
        const productSpecificFlag = contracts.some((val) => {
          return user.email === val.email && val.product_specific_flag === "1";
        });
        userContext?.setEnergySavingFlag(energySavingFlag);
        userContext?.setFreeAnalysisFlag(freeAnalysisFlag);
        userContext?.setProductSpecificFlag(productSpecificFlag);
        checkNavigation({
          energySavingFlag: energySavingFlag,
          freeAnalysisFlag: freeAnalysisFlag,
          productSpecificFlag: productSpecificFlag,
        });
      }
    }
  };

  return { checkPrimarySecondary, setRoleAndOption };
};

export default MainContentFeatures;
