import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import SideMenuForm from "../../forms/SideMenu/SideMenuForm.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import MainComponentForm from "../../forms/Common/MainComponentForm.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import MainContentFeatures from "../../../features/MainContentFeatures.tsx";

type CommonLayoutProps = {
  children: React.ReactNode;
  isSuccess: boolean;
  isLoading: boolean;
};

const CommonLayout: React.FC<CommonLayoutProps> = ({
  children,
  isSuccess,
  isLoading,
}) => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const { getUserApi, userApiLoading } = useUser();
  const { setRoleAndOption } = MainContentFeatures();

  useEffect(() => {
    if (!isSuccess || !CommonUtils.isObjectEmpty(userContext?.userInfo)) return;
    // ユーザー情報取得API
    if (appContext?.accessToken.sub) {
      getUserApi(appContext?.accessToken.sub);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext?.userInfo, appContext?.accessToken, isSuccess]);

  const isApiLoading = () => {
    if (!isLoading && isSuccess && userContext?.userInfo && !userApiLoading) {
      setRoleAndOption();
      return true;
    }
    return false;
  };

  return (
    <>
      {isApiLoading() && (
        <Box display={"flex"}>
          <SideMenuForm />
          <MainComponentForm children={children} />
        </Box>
      )}
      {!isApiLoading() && <CircularProgressDialog />}
    </>
  );
};

export default CommonLayout;
