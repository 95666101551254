import { Box, Container } from "@mui/material";
import React from "react";
import ResetPasswordForm from "../../forms/Login/ResetPasswordForm.tsx";

const ResetPasswordLayout = () => {
  return (
    <>
      <Container>
        <Box
          height={"100vh"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ResetPasswordForm />
        </Box>
      </Container>
      {/* 今後はログインの右側部分を実装する場合、ここにGridを入れる */}
    </>
  );
};

export default ResetPasswordLayout;
