import React, { useContext, useEffect } from "react";
import SingleDispForm from "../../forms/Company/SingleDispForm.tsx";
import PieChartForm from "../../forms/Company/PieChartForm.tsx";
import TrendChartForm from "../../forms/Company/TrendChartForm.tsx";
import classes from "./CompanyLayout.module.scss";
import { Box } from "@mui/material";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import { useDatamartOrganization } from "../../../hooks/useDatamartOrganization.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";

type CompanyLayoutProps = {};

const CompanyLayout: React.FC<CompanyLayoutProps> = (props) => {
  const searchContext = useContext(DatamartSearchContext);
  const { getDatamartOrganizationApi, datamartData, isLoading } =
    useDatamartOrganization();

  useEffect(() => {
    if (!searchContext?.exeSearch) return;
    // データマート取得API
    getDatamartOrganizationApi();
    searchContext.setSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext?.exeSearch]);

  return (
    <>
      {!isLoading && (
        <>
          <Box className={classes.singleDisp}>
            <SingleDispForm data={datamartData.statistics} />
          </Box>
          <Box className={classes.pieChart}>
            <PieChartForm data={datamartData.emissions_chart} />
          </Box>
          <Box className={classes.trendChart}>
            <TrendChartForm data={datamartData} />
          </Box>
        </>
      )}
      {isLoading && <CircularProgressDialog />}
    </>
  );
};

export default CompanyLayout;
