import React, { useContext, useState } from "react";
import classes from "./DisplaySwitchingField.module.scss";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { UnitType } from "../../../types/enum/UnitTypeEnum.tsx";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import { useTranslation } from "react-i18next";

type DisplaySwitchingFieldProps = {
  type: string;
};

const DisplaySwitchingField: React.FC<DisplaySwitchingFieldProps> = (props) => {
  const { t } = useTranslation();
  const { UnitTypeEnum, UnitTypeKgEnum, getUnitName } = UnitType();
  const { searchData, setSearchData, setSearch, unitSwitch, setUnitSwitch } =
    useContext(DatamartSearchContext);
  const UNIT_TYPES = props.type === "kg" ? UnitTypeKgEnum : UnitTypeEnum;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "unit" || (newValue === null && unitSwitch === "unit")) {
      setUnitSwitch("unit");
      handleClickOpen();
    } else if (
      newValue === "company" ||
      (newValue === null && unitSwitch === "company")
    ) {
      setUnitSwitch("company");
      searchData.gco2e_unit_type =
        props.type === "kg" ? "kg_per_unit_managed" : "per_unit_managed";
      setSearchData(searchData);
      setSearch(true);
    }
  };

  const selectUnitButton = (gco2e_unit_type) => {
    searchData.gco2e_unit_type = gco2e_unit_type;
    setSearchData(searchData);
    setSearch(true);
  };

  return (
    <>
      <Box className={classes.displaySwitching}>
        <Box className={classes.label}>
          {`${t("summary.unitSwitching.title")}`}
        </Box>
        <ToggleButtonGroup
          color="primary"
          value={unitSwitch}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          className={classes.toggleButtonGroup}
        >
          <ToggleButton value="company" className={classes.toggleButtonGroup}>
            {t("summary.unitSwitching.company")}
          </ToggleButton>
          <ToggleButton value="unit" className={classes.toggleButtonGroup}>
            {t("summary.unitSwitching.unit")}
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        className={classes.dialog}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          <div style={{ fontSize: "3.5vh", float: "left" }}>
            {t("summary.unitDialog.title")}
          </div>
          <div style={{ fontSize: "3.5vh", float: "right" }}>{`${t(
            "summary.unitDialog.titleUnit"
          )}：${getUnitName(props.type, searchData.gco2e_unit_type)}`}</div>
        </DialogTitle>
        <DialogContent>
          {UNIT_TYPES.map((units) => (
            <Stack
              direction="row"
              spacing={3}
              justifyContent="flex-start"
              className={classes.stack}
              sx={{ mt: "1vh" }}
            >
              {units.map((unit) => (
                <>
                  {unit.name && (
                    <BaseButton
                      label={unit.name}
                      handleClick={() => selectUnitButton(unit.code)}
                    />
                  )}
                  {!unit.name && (
                    <Box
                      sx={{ height: "100%", width: "100%", pl: "1vw" }}
                    ></Box>
                  )}
                </>
              ))}
            </Stack>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DisplaySwitchingField;
