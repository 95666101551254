import React, { useContext } from "react";
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import classes from "./ActivityHistoryField.module.scss";
import UploadHistoriesContext from "../../../contexts/UploadHistoriesContext.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import { useTranslation } from "react-i18next";
import { UploadsHistories } from "../../../types/response/UploadsHistoriesResponse.tsx";

const ActivityHistoryField = () => {
  const { t } = useTranslation();
  const uploadHistoiesContext = useContext(UploadHistoriesContext);
  const uploadHistoies = uploadHistoiesContext?.uploadHistoies.history || {
    history: [] as UploadsHistories[],
  };

  return (
    <Paper elevation={1} className={classes.activityHistory}>
      <Typography variant="body1" component="div" className={classes.title}>
        {t("top.activityHistory.title")}
      </Typography>
      <Divider />
      <Box className={classes.activeInfo}>
        <TableContainer className={classes.activeInfoTable}>
          <Table
            size="small"
            sx={{ minWidth: 700, tableLayout: "fixed", width: "50vw" }}
          >
            <TableHead>
              <TableRow key="th">
                <TableCell sx={{ width: "13%" }}>
                  {t("top.activityHistory.date")}
                </TableCell>
                <TableCell sx={{ width: "11%" }}>
                  {t("top.activityHistory.time")}
                </TableCell>
                <TableCell sx={{ width: "16%" }}>
                  {t("top.activityHistory.contractId")}
                </TableCell>
                <TableCell sx={{ width: "40%" }}>
                  {t("top.activityHistory.result")}
                </TableCell>
                <TableCell sx={{ width: "20%" }}>
                  {t("top.activityHistory.uploadFile")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadHistoies &&
                uploadHistoies.map((value, i) => {
                  return (
                    <TableRow key={"td_" + i}>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: "13%" }}
                      >
                        {CommonUtils.formatDateYYYYMMDD_slash(
                          value.upload_datetime
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: "11%" }}
                      >
                        {CommonUtils.formatDatehhmmss(value.upload_datetime)}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: "16%" }}
                      >
                        {value.upload_organization_id}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: "40%" }}
                      >
                        {value.upload_result}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        sx={{ width: "20%" }}
                      >
                        {decodeURIComponent(value.upload_file_name)}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default ActivityHistoryField;
