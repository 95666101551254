import React, { useContext } from "react";
import { Box } from "@mui/material";
import classes from "./MainComponentForm.module.scss";
import AppContext from "../../../contexts/AppContext.tsx";

type MainComponentFormProps = {
  children: React.ReactNode;
};

const MainComponentForm: React.FC<MainComponentFormProps> = ({ children }) => {
  const appContext = useContext(AppContext);

  return (
    <main
      className={
        appContext?.sideOpen
          ? classes.openMainContent
          : classes.notOpenMainContent
      }
      ref={appContext?.pdfDownloadRef}
      id="pdfDownloadMainContent"
    >
      <Box className={classes.mainContent}>{children}</Box>
    </main>
  );
};

export default MainComponentForm;
