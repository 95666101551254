import { Paper, Tooltip, Typography } from "@mui/material";
import React, { useContext } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TrendGraph from "../../parts/Chart/TrendGraph.tsx";
import classes from "./TotalTrendGraphField.module.scss";
import DatamartDataOrganization from "../../../types/response/DatamartDataOrganization.tsx";
import DatamartDataScope from "../../../types/response/DatamartDataScope.tsx";
import { useTranslation } from "react-i18next";
import DatamartCompanyContext from "../../../contexts/DatamartCompanyContext.tsx";

type TotalTrendGraphFieldProps = {
  data: {
    data_by_organization: DatamartDataOrganization[];
    data_by_scope: DatamartDataScope[];
  };
};

const TotalTrendGraphField: React.FC<TotalTrendGraphFieldProps> = (props) => {
  const { t } = useTranslation();
  const { tab, setTab } = useContext(DatamartCompanyContext);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Paper elevation={1} className={classes.trendGraph}>
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "left",
        }}
        color="text.secondary"
        gutterBottom
        className={classes.title}
      >
        {t("company.totalTrend.title")}
      </Typography>
      <Tooltip title={t("company.totalTrend.tooltip")}>
        <HelpOutlineIcon
          className={classes.toolTipIcon}
          sx={{ color: "text.secondary" }}
        />
      </Tooltip>
      <TabContext value={tab}>
        <TabList
          onChange={handleChange}
          aria-label="totalTrendGraph"
          className={classes.tabList}
        >
          <Tab
            label={t("company.totalTrend.tabScope")}
            value="1"
            className={classes.tab}
          />
          <Tab
            label={t("company.totalTrend.tabOrganization")}
            value="2"
            className={classes.tab}
          />
        </TabList>
        <TabPanel value="1" className={classes.tabPanel}>
          <TrendGraph
            title={t("company.totalTrend.totalTrendScope")}
            data={props.data ? props.data.data_by_scope : []}
            type="scope"
          />
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanel}>
          <TrendGraph
            title={t("company.totalTrend.totalTrendOrganization")}
            data={props.data ? props.data.data_by_organization : []}
            type="organization"
          />
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default TotalTrendGraphField;
