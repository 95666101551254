import React from "react";
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import classes from "./NoticeContentField.module.scss";
import { useTranslation } from "react-i18next";
import { NoticeInfo } from "../../../types/response/NoticeResponse.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";

type NoticeContentFieldProps = {
  notices: NoticeInfo[];
};

const NoticeContentField: React.FC<NoticeContentFieldProps> = (props) => {
  const { t } = useTranslation();
  return (
    <Paper elevation={1} className={classes.noticeContent}>
      <Typography variant="body1" component="div" className={classes.title}>
        {t("top.notice.title")}
      </Typography>
      <Divider />
      <Box className={classes.noticeInfo}>
        <TableContainer className={classes.noticeInfoTable}>
          <Table
            size="small"
            sx={{ minWidth: 400, tableLayout: "fixed", width: "100%" }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "20%" }}>
                  {t("top.notice.date")}
                </TableCell>
                <TableCell sx={{ width: "20%" }}>
                  {t("top.notice.category")}
                </TableCell>
                <TableCell sx={{ width: "60%" }}>
                  {t("top.notice.content")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.notices.map((notice) => {
                return (
                  <TableRow>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: "20%" }}
                    >
                      {CommonUtils.formatDateYYYYMMDD_slash(
                        notice.released_at_str
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: "20%" }}
                    >
                      {notice.category}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      sx={{ width: "60%" }}
                    >
                      {notice.content}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default NoticeContentField;
