import React from "react";
import classes from "./SettingLayout.module.scss";
import { Box } from "@mui/material";
import LanguageForm from "../../forms/Setting/LanguageForm.tsx";
import NewPasswordForm from "../../forms/Setting/NewPasswordForm.tsx";

type SettingLayoutProps = {};

const SettingLayout: React.FC<SettingLayoutProps> = (props) => {
  return (
    <>
      <Box className={classes.language}>
        <LanguageForm />
      </Box>
      <Box className={classes.newPassword}>
        <NewPasswordForm />
      </Box>
    </>
  );
};

export default SettingLayout;
