import CommonUtils from "../../common/utils/CommonUtils.tsx";
import ErrorResponse from "./ErrorResponse";

const today = new Date();

export const defaultMasterDatas = {
  scopes: [],
  organizations: [],
  energies: [],
  major_categories: [],
  middle_categories: [],
  minor_categories: [],
  products: [],
  parts_or_energies: [],
  self_manufacturing: [],
  from_initial_year_month: CommonUtils.formatDateYYYYMMDD(today),
  to_initial_year_month: CommonUtils.formatDateYYYYMMDD(today),
};

export interface MasterDatas {
  scopes: Scope[];
  organizations: MasterData[];
  energies: EnergiesData[];
  major_categories: CodeCategory[];
  middle_categories: CodeCategory[];
  minor_categories: CodeCategory[];
  products: MasterData[];
  parts_or_energies: IdCategory[];
  self_manufacturing: CodeData[];
  from_initial_year_month: string; // YYYY-MM-01形式
  to_initial_year_month: string; // YYYY-MM-01形式
}

export type Scope = {
  name: string;
  scope_type: string;
};

export type MasterData = {
  id: string;
  name: string;
};

export type EnergiesData = {
  scope_code: string;
  id: string;
  name: string;
};

export type CodeCategory = {
  code: string;
  name: string;
  major_category_code: string;
  middle_category_code: string;
};

export type IdCategory = {
  id: string;
  name: string;
  major_category_code: string;
  middle_category_code: string;
  minor_category_code: string;
};

export type CodeData = {
  code: string;
  name: string;
};

type MasterDataResponse = MasterDatas | ErrorResponse;

export default MasterDataResponse;
