import React from "react";
import { IconButton, Typography } from "@mui/material";
import classes from "./LogoutButton.module.scss";
import { useLogin } from "../../../hooks/useLogin.tsx";
import { useTranslation } from "react-i18next";

type LogoutButtonProps = {
};

const LogoutButton: React.FC<LogoutButtonProps> = (props) => {
  const { t } = useTranslation();
  const { useLogoutApi } = useLogin();

  const Logout = () => {
    useLogoutApi();
  };

  return (
    <IconButton className={classes.logoutButton} onClick={Logout}>
      <Typography className={classes.logoutButtonText}>
        {t("parts.accountMenu.logout")}
      </Typography>
    </IconButton>
  );
};

export default LogoutButton;
