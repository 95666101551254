import { Box, Grid } from "@mui/material";
import React from "react";
import classes from "./TrendChartForm.module.scss";
import TotalTrendGraphField from "../../fields/Company/TotalTrendGraphField.tsx";
import SpreadSheetField from "../../fields/Company/SpreadSheetField.tsx";
import EmissionsTrendGraphField from "../../fields/Company/EmissionsTrendGraphField.tsx";
import { DatamartOrganization } from "../../../types/response/DatamartOrganizationResponse.tsx";

type TrendChartFormProps = {
  data: DatamartOrganization;
};

const TrendChartForm: React.FC<TrendChartFormProps> = (props) => {
  return (
    <>
      <Grid container spacing={1} className={classes.trendChartForm}>
        <Grid item xs={6} className={classes.trendChartItem}>
          <TotalTrendGraphField data={props.data.total_amount_graph} />
        </Grid>
        <Grid item xs={6} className={classes.trendChartItem}>
          <Box className={classes.trendGraph}>
            <EmissionsTrendGraphField data={props.data.base_unit_graph} />
          </Box>
          <Box className={classes.spreadSheet}>
            <SpreadSheetField data={props.data.base_unit_table} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TrendChartForm;
