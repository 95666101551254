import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  ComposedChart,
  XAxis,
  YAxis,
  Brush,
  Bar,
  Area,
  Text,
} from "recharts";
import classes from "./TrendGraph.module.scss";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Fullscreen, FullscreenExit, PhotoCamera } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import DatamartDataOrganization from "../../../types/response/DatamartDataOrganization";
import DatamartDataScope from "../../../types/response/DatamartDataScope";
import Color from "../../../types/Color.tsx";
import { useTranslation } from "react-i18next";
import { ScreenFuatures } from "../../../features/ScreenFuatures.tsx";
import CommonTooltip from "./CommonTooltip.tsx";

type TrendGraphProps = {
  title: string;
  data: DatamartDataOrganization[] | DatamartDataScope[];
  type: string;
};

const TrendGraph: React.FC<TrendGraphProps> = (props) => {
  const { t } = useTranslation();
  const handle = useFullScreenHandle();
  const [graphData, setGraphData] = useState<object[]>([]);
  const [graphKeys, setGraphKeys] = useState<string[]>([]);
  const [hoverItem, setHoverItem] = useState<string>("");
  const [isHovered, setHovered] = useState(false);
  const { photoSave } = ScreenFuatures();

  const getColor = (index: number, graphType: string, key: string) => {
    if (props.type === "scope") {
      const id = key.slice(-1);
      if (graphType === "bar") return Color.scope[Number(id) - 1];
      if (graphType === "area") return Color.scope[Number(id) - 1];
    }
    if (graphType === "bar") return Color.normal[index % Color.normal.length];
    if (graphType === "area") return Color.normal[index % Color.normal.length];
  };

  useEffect(() => {
    if (!props.data) return;
    const results: Array<object> = [];
    const keys: Array<string> = [];
    props.data.forEach((value, index) => {
      const result = {};
      if ("organizations" in value) {
        const organizations = value.organizations;
        result["date"] = value.month;
        organizations.forEach((organization) => {
          result[organization.organization_name] = organization.amount;
          result[
            organization.organization_name + t("company.totalTrend.pysm")
          ] = organization.amount_pysm;
          if (index === 0) keys.push(organization.organization_name);
        });
        results.push(result);
      } else {
        const scopes = value.scopes;
        result["date"] = value.month;
        scopes.forEach((scope) => {
          result["scope" + scope.scope] = scope.amount;
          result["scope" + scope.scope + t("company.totalTrend.pysm")] =
            scope.amount_pysm;
          if (index === 0) keys.push("scope" + scope.scope);
        });
        results.push(result);
      }
    });
    setGraphData(results);
    setGraphKeys(keys);
  }, [props.data]);

  // Y軸のラベルコンポーネント
  const CustomYAxisLabel = ({ value }) => {
    return (
      <Text
        transform="rotate(-90)"
        textAnchor="middle"
        className={handle.active ? classes.yAxisLabelFull : classes.yAxisLabel}
      >
        {value}
      </Text>
    );
  };

  return (
    <Box
      className={classes.graphBox}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FullScreen handle={handle} className={classes.fullscreenPanel}>
        {handle.active && (
          <Typography
            sx={{
              textAlign: "left",
            }}
            color="text.secondary"
            gutterBottom
            className={classes.title}
          >
            {props.title}
          </Typography>
        )}
        <ResponsiveContainer
          width={handle.active ? "90%" : "100%"}
          height={handle.active ? "100%" : "90%"}
          className={handle.active ? classes.chartFull : classes.chart}
          id={"trendGraph_" + props.type}
        >
          <ComposedChart
            data={graphData}
            height={300}
            margin={{
              bottom: handle.active ? 5 : 5,
              top: handle.active ? 10 : 0,
            }}
          >
            <XAxis
              dataKey="date"
              angle={-60}
              fontSize={handle.active ? "1.5vh" : "1.5vh"}
              tickMargin={handle.active ? 30 : 15}
              type="category"
              interval={0}
              height={handle.active ? 80 : 50}
            />
            <YAxis
              label={<CustomYAxisLabel value={t("parts.trendGraph")} />}
              fontSize={handle.active ? "1.5vh" : "1.5vh"}
              className={handle.active ? classes.yAxisFull : ""}
            />
            <Brush startIndex={0} height={10} stroke="#a8a8a8" />
            {graphKeys.map((key, i) => {
              return (
                <>
                  <Area
                    type="linear"
                    stackId="1"
                    dataKey={key + t("company.totalTrend.pysm")}
                    stroke={getColor(i, "area", key)}
                    fill={getColor(i, "area", key)}
                    opacity={0.7}
                    fillOpacity={0.1}
                    key={i + "_"}
                    dot={{ r: 2, fillOpacity: 0.1 }}
                    activeDot={{
                      onPointerOver: () => {
                        setHoverItem(key + t("company.totalTrend.pysm"));
                      },
                    }}
                  />
                  <Bar
                    stackId="2"
                    dataKey={key}
                    stroke={getColor(i, "bar", key)}
                    fill={getColor(i, "bar", key)}
                    maxBarSize={10}
                    opacity={0.7}
                    key={i}
                    onMouseOver={() => {
                      setHoverItem(key);
                    }}
                  />
                </>
              );
            })}
            <Tooltip
              labelStyle={{ fontSize: "1.5vh" }}
              itemStyle={{ fontSize: "1.5vh", WebkitTextStroke: "0.1px #000" }}
              content={(props) => <CommonTooltip {...props} item={hoverItem} />}
            />
            <Legend
              className={classes.legend}
              wrapperStyle={{
                fontSize: handle.active ? "1.5vh" : "1.5vh",
                paddingTop: handle.active ? 10 : 10,
                opacity: 0.7,
              }}
            />
          </ComposedChart>
        </ResponsiveContainer>
        {isHovered && (
          <Box
            className={classes.buttonDiv}
            style={{
              right: handle.active ? "1%" : "0%",
              bottom: handle.active ? "0%" : "-2%",
            }}
          >
            <div
              onClick={() =>
                photoSave(
                  document.getElementById("trendGraph_" + props.type),
                  props.title,
                  handle.active
                )
              }
              className={classes.screenButton}
            >
              <PhotoCamera />
            </div>
            {!handle.active ? (
              <div onClick={handle.enter} className={classes.fullscreen}>
                <Fullscreen />
              </div>
            ) : (
              <div onClick={handle.exit} className={classes.fullscreenExit}>
                <FullscreenExit />
              </div>
            )}
          </Box>
        )}
      </FullScreen>
    </Box>
  );
};

export default TrendGraph;
