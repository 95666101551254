import React, { createContext, useState } from "react";
import UploadsHistoriesResponse, {
  UploadsHistories,
} from "../types/response/UploadsHistoriesResponse.tsx";

type Props = {
  children: React.ReactNode;
};

type UploadHistoiesProps = {
  uploadHistoies: UploadsHistoriesResponse;
  setUploadHistories: React.Dispatch<
    React.SetStateAction<UploadsHistoriesResponse>
  >;
};

// ファイルアップロード履歴コンテキスト
const UploadHistoriesContext = createContext<UploadHistoiesProps>({
  uploadHistoies: { history: [] as UploadsHistories[] },
  setUploadHistories: () => {},
});

export const UploadHistoriesProvider: React.VFC<Props> = ({ children }) => {
  const [uploadHistoies, setUploadHistories] =
    useState<UploadsHistoriesResponse>({ history: [] as UploadsHistories[] });

  return (
    <UploadHistoriesContext.Provider
      value={{
        uploadHistoies,
        setUploadHistories,
      }}
    >
      {children}
    </UploadHistoriesContext.Provider>
  );
};

export default UploadHistoriesContext;
