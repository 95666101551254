import { Grid } from "@mui/material";
import React, { useContext, useEffect } from "react";
import classes from "./PieChartForm.module.scss";
import PieGraphField from "../../fields/Company/PieGraphField.tsx";
import DatamartEmissionsData from "../../../types/response/DatamartEmissionsData.tsx";
import { useTranslation } from "react-i18next";
import DatamartCompanyContext from "../../../contexts/DatamartCompanyContext.tsx";

type PieChartFormProps = {
  data: {
    data_by_energy: DatamartEmissionsData[];
    data_by_organization: DatamartEmissionsData[];
    data_by_scope: DatamartEmissionsData[];
  };
};

const PieChartForm: React.FC<PieChartFormProps> = (props) => {
  const { t } = useTranslation();
  const { selectPieGraph, setSavePieGraph } = useContext(
    DatamartCompanyContext
  );

  /**
   * 円グラフのセルが選択された際に、
   */
  useEffect(() => {
    if (selectPieGraph.length === 0) {
      setSavePieGraph(props.data);
    }
  }, [selectPieGraph]);

  return (
    <>
      <Grid container spacing={1} className={classes.pieChartForm}>
        <Grid item xs={4} className={classes.pieChartItem}>
          <PieGraphField
            title={t("company.pieChart.scopes")}
            type="scopes"
            data={props.data ? props.data.data_by_scope : []}
          />
        </Grid>
        <Grid item xs={4} className={classes.pieChartItem}>
          <PieGraphField
            title={t("company.pieChart.energies")}
            type="energies"
            data={props.data ? props.data.data_by_energy : []}
          />
        </Grid>
        <Grid item xs={4} className={classes.pieChartItem}>
          <PieGraphField
            title={t("company.pieChart.organizations")}
            type="organizations"
            data={props.data ? props.data.data_by_organization : []}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PieChartForm;
