import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as IconImage } from "../../../assets/images/Search/icon_date.svg";
import { Box } from "@mui/material";
import DatePicker from "react-datepicker";
import classes from "./SearchDateField.module.scss";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import { useTranslation } from "react-i18next";

type SearchDateFieldProps = {
  title: string;
  isBase?: boolean;
  fromDate: string;
  toDate: string;
};

const SearchDateField: React.FC<SearchDateFieldProps> = (props) => {
  const { t, i18n } = useTranslation();
  const appContext = useContext(AppContext);
  const searchContext = useContext(DatamartSearchContext);
  const [fromDate, setFromDate] = useState<Date>(
    CommonUtils.toDateYYYY_MM(props.fromDate)
  );
  const [toDate, setToDate] = useState<Date>(
    CommonUtils.toDateYYYY_MM(props.toDate)
  );

  useEffect(() => {
    handleFromChange(CommonUtils.toDateYYYY_MM(props.fromDate));
    handleToChange(CommonUtils.toDateYYYY_MM(props.toDate));
  }, [searchContext.resetStatus]);

  const handleFromChange = (date: Date | null) => {
    const searchData = searchContext?.searchData;
    if (date && searchData) {
      setFromDate(date);
      switch (appContext?.sideItem) {
        case "company":
          searchData.filter_organization_params.from_month =
            CommonUtils.formatDateYYYYMMDD(date);
          break;
        case "summary":
        case "product":
        case "facility":
          if (props.isBase) {
            searchData.filter_params.from_base_year_month =
              CommonUtils.formatDateYYYYMMDD(date);
          } else {
            searchData.filter_params.from_target_year_month =
              CommonUtils.formatDateYYYYMMDD(date);
          }
          break;
      }
      searchContext.setSearchData(searchData);
      if (date.getTime() > toDate.getTime()) {
        setToDate(date);
      }
    }
  };

  const handleToChange = (date: Date | null) => {
    const searchData = searchContext?.searchData;
    if (date && searchData) {
      setToDate(date);
      switch (appContext?.sideItem) {
        case "company":
          searchData.filter_organization_params.to_month =
            CommonUtils.formatDateYYYYMMDD(date);
          break;
        case "summary":
        case "product":
        case "facility":
          if (props.isBase) {
            searchData.filter_params.to_base_year_month =
              CommonUtils.formatDateYYYYMMDD(date);
          } else {
            searchData.filter_params.to_target_year_month =
              CommonUtils.formatDateYYYYMMDD(date);
          }
          break;
      }
      searchContext.setSearchData(searchData);
    }
  };

  return (
    <>
      <Box className={classes.iconField}>
        <IconImage />
        {props.title}
      </Box>
      <Box sx={{ fontSize: "2vh" }}>
        <DatePicker
          selected={fromDate}
          dateFormat="yyyy/MM"
          onChange={handleFromChange}
          showMonthYearPicker
          renderMonthContent={(month, shortMonth) => {
            return (
              <span>
                {i18n.language === "ja"
                  ? (month + 1).toString() + "月"
                  : shortMonth}
              </span>
            );
          }}
          minDate={new Date(2010, 0)}
          maxDate={new Date(2030, 11)}
          className={classes.dateField}
        />
        {t("common.tofrom")}
        <DatePicker
          selected={toDate}
          dateFormat="yyyy/MM"
          onChange={handleToChange}
          showMonthYearPicker
          renderMonthContent={(month, shortMonth) => {
            return (
              <span>
                {i18n.language === "ja"
                  ? (month + 1).toString() + "月"
                  : shortMonth}
              </span>
            );
          }}
          minDate={fromDate}
          maxDate={new Date(2030, 11)}
          className={classes.dateField}
        />
      </Box>
    </>
  );
};

export default SearchDateField;
