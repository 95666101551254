import { useContext } from "react";
import UserContext from "../contexts/UserContext.tsx";
import { UserInfo } from "../types/response/UserInfoResponse.tsx";

export const UserFuatures = () => {
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;

  /**
   * ユーザー名取得処理
   * @returns
   */
  const getUserName = () => {
    if (userInfo) {
      const firstName = userInfo.first_name || "";
      const lastName = userInfo.last_name || "";
      if (firstName && lastName) {
        return lastName + " " + firstName;
      } else if (firstName) {
        return firstName;
      }
      return lastName;
    }
    return "";
  };

  return { getUserName };
};
