import React from "react";
import { Box } from "@mui/material";
import AccountCreateForm from "../../forms/AccountCreate/AccountCreateForm.tsx";

type AccountCreateLayoutProps = {};

const AccountCreateLayout: React.FC<AccountCreateLayoutProps> = (props) => {
  return (
    <Box>
      <AccountCreateForm />
    </Box>
  );
};

export default AccountCreateLayout;
