import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import InquiryLayout from "../../layouts/Inquiry/InquiryLayout.tsx";

const InquiryPage = () => {
  return (
    <ContentLayout>
      <InquiryLayout />
    </ContentLayout>
  );
};

export default InquiryPage;
