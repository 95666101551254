import { Box, Container } from "@mui/material";
import React from "react";
import ResetMailForm from "../../forms/Login/ResetMailForm.tsx";

const ResetMailLayout = () => {
  return (
    <>
      <Container>
        <Box
          height={"100vh"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <ResetMailForm />
        </Box>
      </Container>
      {/* 今後はログインの右側部分を実装する場合、ここにGridを入れる */}
    </>
  );
};

export default ResetMailLayout;
