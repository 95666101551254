import React from "react";
import { Box } from "@mui/material";
import classes from "./PCFLineGraphForm.module.scss";
import PCFLineGraphField from "../../fields/Facility/PCFLineGraphField.tsx";
import { DatamartEquipment } from "../../../types/response/DatamartEquipmentResponse.tsx";
import GoalValueField from "../../fields/Facility/GoalValueField.tsx";

type PCFLineGraphFormProps = {
  data: DatamartEquipment;
  emissionValue: string;
};

const PCFLineGraphForm: React.FC<PCFLineGraphFormProps> = (props) => {
  return (
    <>
      <Box className={classes.targetValue}>
        <GoalValueField />
      </Box>
      <Box className={classes.lineGraph}>
        <PCFLineGraphField
          data={props.data}
          emissionValue={props.emissionValue}
        />
      </Box>
    </>
  );
};

export default PCFLineGraphForm;
