import React, { useContext, useEffect } from "react";
import SummaryLayout from "../../layouts/Summary/SummaryLayout.tsx";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import FullScreenLayout from "../../layouts/Common/FullScreenLayout.tsx";
import { DatamartSummaryProvider } from "../../../contexts/DatamartSummaryContext.tsx";

const SummaryPage = () => {
  const { searchData, setSearchData, setSearch, setUnitSwitch } = useContext(
    DatamartSearchContext
  );

  useEffect(() => {
    searchData.gco2e_unit_type = "per_unit_managed";
    setUnitSwitch("company");
    setSearchData(searchData);
    setSearch(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DatamartSummaryProvider>
      <ContentLayout>
        <FullScreenLayout>
          <SummaryLayout />
        </FullScreenLayout>
      </ContentLayout>
    </DatamartSummaryProvider>
  );
};
export default SummaryPage;
