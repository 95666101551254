import React from "react";
import classes from "./LeftContentForm.module.scss";
import { Box } from "@mui/material";
import UserContractInfoField from "../../fields/Top/UserContractInfoField.tsx";

type LeftContentFormProps = {};

const LeftContentForm: React.FC<LeftContentFormProps> = (props) => {
  return (
    <Box className={classes.contractInfo}>
      <UserContractInfoField />
    </Box>
  );
};

export default LeftContentForm;
