import FileType from "../types/FileType.tsx";
import { UploadsHistories } from "../types/response/UploadsHistoriesResponse.tsx";

const FileFeatures = {
  /**
   * アップロード・削除ボタン表示制御データ生成
   */
  getIconDispData: (
    datas: UploadsHistories[],
    companyFile: FileType[],
    companyFileLocations: FileType[],
    productFile: FileType[],
    energySavingFile: FileType[]
  ) => {
    if (!datas)
      return {
        companyFile: companyFile,
        companyFileLocations: companyFileLocations,
        productFile: productFile,
        energySavingFile: energySavingFile,
      };

    return {
      companyFile: companyFile,
      companyFileLocations: companyFileLocations,
      productFile: productFile,
      energySavingFile: energySavingFile,
    };
  },

  readFileAsArrayBuffer: (files: File[]) => {
    return new Promise((resolve, reject) => {
      files.map((file) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
        return reader;
      });
    });
  },
};

export default FileFeatures;
