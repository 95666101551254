const Digits = {
  JaDigits: [
    "千",
    "万",
    "十万",
    "百万",
    "千万",
    "億",
    "十億",
    "百億",
    "千億",
    "兆",
    "十兆",
    "百兆",
    "千兆",
    "京",
    "十京",
    "百京",
    "千京",
    "垓",
    "十垓",
    "百垓",
    "千垓",
    "秭",
    "十秭",
    "百秭",
    "千秭",
  ],
  EnDigits: [
    "1K",
    "10K",
    "100K",
    "1M",
    "10M",
    "100M",
    "1B",
    "10B",
    "100B",
    "1T",
    "10T",
    "100T",
    "1Qa",
    "10Qa",
    "100Qa",
    "1Qi",
    "10Qi",
    "100Qi",
    "1Sx",
    "10Sx",
    "100Sx",
    "1Sp",
    "10Sp",
    "100Sp",
    "1Oc",
  ],
};

export default Digits;
