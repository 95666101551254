import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Line,
  Bar,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
} from "recharts";
import classes from "./PCFGraph.module.scss";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Fullscreen, FullscreenExit, PhotoCamera } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Color from "../../../types/Color.tsx";
import { useTranslation } from "react-i18next";
import { ScreenFuatures } from "../../../features/ScreenFuatures.tsx";
import Digits from "../../../types/Digits.tsx";
import CommonTooltip from "./CommonTooltip.tsx";

type PCFGraphProps = {
  title: string;
  data: {
    graphData: Array<object>;
    rightKeys: Array<string>;
    leftKeys: Array<string>;
    integerPartDigits: number;
  };
  leftLabel: string;
  rightLabel: string;
  targetValue?: number;
};

const PCFGraph: React.FC<PCFGraphProps> = (props) => {
  const handle = useFullScreenHandle();
  const { t, i18n } = useTranslation();
  const { photoSave } = ScreenFuatures();
  const [graphData, setGraphData] = useState<Array<object>>([]);
  const [hoverItem, setHoverItem] = useState<string>("");
  const [isHovered, setHovered] = useState(false);

  const dispTargetValue = () => {
    if (props.targetValue !== null && props.targetValue !== undefined) {
      return props.targetValue / Math.pow(10, props.data.integerPartDigits);
    }
    return 0;
  };

  /**
   * 描画用データ作成
   */
  useEffect(() => {
    const data = props.data.graphData;
    const leftKeys = props.data.leftKeys;
    if (data && data.length) {
      data.forEach((value) => {
        leftKeys.forEach((key) => {
          value[key] = value[key] / Math.pow(10, props.data.integerPartDigits);
        });
      });
      setGraphData(data);
    }
  }, [props.data]);

  /**
   * 左ラベル表示処理
   */
  const dispLeftLabel = () => {
    if (props.data.integerPartDigits > 2) {
      const digitsIndex = props.data.integerPartDigits - 3;
      const dispDigit =
        i18n.language === "ja"
          ? Digits.JaDigits[digitsIndex]
          : Digits.EnDigits[digitsIndex];
      return `${props.leftLabel}(${dispDigit})`;
    }
    return props.leftLabel;
  };
  return (
    <Box
      className={classes.graphBox}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FullScreen handle={handle} className={classes.fullscreenPanel}>
        {handle.active && (
          <Typography
            sx={{
              textAlign: "left",
            }}
            color="text.secondary"
            gutterBottom
            className={classes.title}
          >
            {props.title}
          </Typography>
        )}
        <ResponsiveContainer
          width="100%"
          height="110%"
          className={handle.active ? classes.chartFull : classes.chart}
          id={"pcfGraph_" + props.title}
        >
          <ComposedChart
            data={graphData}
            margin={{
              top: 20,
              right: 5,
              bottom: 20,
              left: 5,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="date"
              fontSize={handle.active ? "1.0vw" : "0.6vw"}
              type="category"
              interval={0}
              tickMargin={handle.active ? 30 : 15}
              height={handle.active ? 80 : 40}
              angle={-60}
            />
            <YAxis
              yAxisId="left"
              orientation="left"
              label={{
                value: dispLeftLabel(),
                angle: -90,
                position: "insideRight",
                offset: handle.active ? 55 : 50,
              }}
              className={
                handle.active ? classes.yAxisLabelFull : classes.yAxisLabel
              }
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              label={{
                value: props.rightLabel,
                angle: 90,
                position: "insideRight",
                offset: handle.active ? 5 : 10,
                textAnchor: "middle",
              }}
              className={
                handle.active ? classes.yAxisLabelFull : classes.yAxisLabel
              }
            />
            <Tooltip
              labelStyle={{ fontSize: "1.5vh" }}
              itemStyle={{ fontSize: "1.5vh", WebkitTextStroke: "0.1px #000" }}
              content={(props) => <CommonTooltip {...props} item={hoverItem} />}
            />
            <Legend
              wrapperStyle={{
                fontSize: handle.active ? "1.5vh" : "1.5vh",
                bottom: "1.5vh",
                textAnchor: "middle",
                width: handle.active ? "95%" : "90%",
              }}
            />
            {props.data.leftKeys.map((key, i) => {
              return (
                <Bar
                  yAxisId="left"
                  dataKey={key}
                  stackId="a"
                  fill={Color.normal[i % Color.normal.length]}
                  onMouseOver={() => {
                    setHoverItem(key);
                  }}
                />
              );
            })}
            {props.data.rightKeys.map((key, i) => {
              return (
                <Line
                  yAxisId="right"
                  type="monotone"
                  dataKey={key}
                  stroke={Color.normal[i % Color.normal.length]}
                  pointerEvents="auto"
                  activeDot={{
                    onPointerOver: () => {
                      setHoverItem(key);
                    },
                  }}
                />
              );
            })}
            {props.targetValue !== undefined && props.targetValue !== 0 && (
              <ReferenceLine
                y={dispTargetValue()}
                label={{
                  value: t("parts.pcfGraph.targetValue"),
                  position: "left",
                  fontSize: handle.active ? "2vh" : "1.5vh",
                  offset: handle.active ? -70 : -35,
                }}
                stroke="#2bff2b"
                strokeWidth={3}
                yAxisId="left"
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
        {isHovered && (
          <Box className={classes.buttonDiv}>
            <div
              onClick={() =>
                photoSave(
                  document.getElementById("pcfGraph_" + props.title),
                  props.title,
                  handle.active
                )
              }
              className={classes.screenButton}
              style={{ right: handle.active ? "3%" : "5%" }}
            >
              <PhotoCamera />
            </div>
            {!handle.active ? (
              <div onClick={handle.enter} className={classes.fullscreen}>
                <Fullscreen />
              </div>
            ) : (
              <div onClick={handle.exit} className={classes.fullscreenExit}>
                <FullscreenExit />
              </div>
            )}
          </Box>
        )}
      </FullScreen>
    </Box>
  );
};

export default PCFGraph;
