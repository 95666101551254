import React, { useContext } from "react";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import { Divider, Typography } from "@mui/material";
import classes from "./NewPasswordForm.module.scss";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTranslation } from "react-i18next";
import NewPasswordField from "../../fields/Setting/NewPasswordField.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import MessageType from "../../../types/Message.tsx";
import { usePasswordChange } from "../../../hooks/usePasswordChange.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import { useUser } from "../../../hooks/useUser.tsx";

const NewPasswordForm = () => {
  const { t } = useTranslation();
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const {
    passwordChangeApi,
    checkInputData,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    reNewPassword,
    setReNewPassword,
    isLoading,
  } = usePasswordChange();
  const { putLanguageApi } = useUser();

  const updatePassword = () => {
    if (checkInputData()) {
      // パスワード変更API実行
      passwordChangeApi();
    }
    const language = localStorage.getItem("language");
    if (language && userInfo.language !== language) {
      // 言語設定API実行
      putLanguageApi(language);
    }
  };

  return (
    <>
      {!isLoading && (
        <>
          <Typography variant="h5" component="div" className={classes.title}>
            {t("setting.newPassword.title")}
          </Typography>
          <Divider />
          <div className={classes.newPassword}>
            <NewPasswordField
              password={password}
              setPassword={setPassword}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              reNewPassword={reNewPassword}
              setReNewPassword={setReNewPassword}
            />
            <BaseButton
              label={t("setting.newPassword.button")}
              className={classes.reset_button}
              handleClick={updatePassword}
            />
          </div>
          <div className={classes.enterCharacters}>
            <Typography
              variant="body1"
              component="div"
              className={classes.explain}
            >
              {t("setting.newPassword.enterCharacters")}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.explain}
            >
              {t("setting.newPassword.alphabetNumber")}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              className={classes.explain}
            >
              {t("setting.newPassword.symbol")}
            </Typography>
          </div>
        </>
      )}
      {isLoading && <CircularProgressDialog />}
    </>
  );
};

export default NewPasswordForm;
