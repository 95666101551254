import { useContext, useState } from "react";
import FileApi from "../features/api/FileApi.tsx";
import { FileTypeEnum, FileTypes_Ja } from "../types/enum/FileTypeEnum.tsx";
import FileDownloadRequest from "../types/request/FileDownloadRequest.tsx";
import FileUploadRequest from "../types/request/FileUploadRequest.tsx";
import UploadsHistoriesResponse from "../types/response/UploadsHistoriesResponse.tsx";
import UploadsHisotriesRequest from "../types/request/UploadsHisotriesRequest.tsx";
import AppContext from "../contexts/AppContext.tsx";
import MessageType from "../types/Message.tsx";
import FileDeleteRequest from "../types/request/FileDeleteRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import UploadHistoriesContext from "../contexts/UploadHistoriesContext.tsx";
import CommonUtils from "../common/utils/CommonUtils.tsx";

export const useFile = () => {
  const Message = MessageType();
  const { onCommonError, onCommonBlobError } = ApiCommon();
  const appContext = useContext(AppContext);
  const uploadHistoiesContext = useContext(UploadHistoriesContext);
  const file_types: UploadsHisotriesRequest = {
    file_types: FileTypes_Ja,
  };

  const [fileType, setFileType] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState();
  const [historiesResponse, setHistoriesResponse] =
    useState<UploadsHistoriesResponse>({ history: [] });
  const [uploadRequest, setUploadRequest] = useState<FileUploadRequest>({
    file_type: "",
    files: new FormData(),
  });
  const [downloadRequest, setDownloadRequest] = useState<FileDownloadRequest>(
    {} as FileDownloadRequest
  );
  const [deleteRequest, setDeleteRequest] = useState<FileDeleteRequest>({
    file_type: "",
    organization_id: "",
    delete_from: "",
    delete_to: "",
  });

  /**
   * アップロード履歴API成功時
   */
  const onUploadsHisoriesSuccess = (data) => {
    setLoading(false);
    setHistoriesResponse(data);
    uploadHistoiesContext?.setUploadHistories(data);
  };

  /**
   * アップロード履歴API失敗時
   */
  const onUploadsHisoriesError = (error) => {
    setLoading(false);
    onCommonError(error);
  };

  /**
   * アップロード履歴API処理
   * @returns
   */
  const getUploadsHistories = async () => {
    setLoading(true);
    exeUploadsHistories.refetch();
  };

  // アップロード履歴API実行
  const exeUploadsHistories = FileApi.UploadsHistories(
    file_types,
    onUploadsHisoriesSuccess,
    onUploadsHisoriesError
  );

  /**
   * アップロードAPI成功時
   */
  const onUploadFilesSuccess = (data) => {
    if (!isLoading) return;
    setLoading(false);
    setResponse(data);
    appContext?.setAlertMessage(Message.SUCCESS_UPLOAD_FILES);
    appContext?.setSuccessOpen(true);
    // アップロード履歴API再実行で、レンダリングを行う
    exeUploadsHistories.refetch();
  };

  /**
   * アップロードAPI失敗時
   */
  const onUploadFilesError = (error) => {
    if (!isLoading) return;
    setLoading(false);
    onCommonError(error);
  };

  /**
   * アップロードAPI処理
   * @returns
   */
  const uploadFiles = async (type: string, files: File[]) => {
    if (isLoading) return;
    setFileType(type);
    uploadRequest.file_type = type;
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("files", file, encodeURIComponent(file.name));
    });
    uploadRequest.files = formData;
    setUploadRequest(uploadRequest);
    setLoading(true);
    exeUploadFiles.refetch();
  };

  // アップロードAPI実行
  const exeUploadFiles = FileApi.UploadFiles(
    uploadRequest,
    onUploadFilesSuccess,
    onUploadFilesError
  );

  /**
   * ダウンロードAPI成功時
   */
  const onDownloadFilesSuccess = (data) => {
    if (!isLoading) return;
    setLoading(false);
    setDownloadRequest({} as FileDownloadRequest);
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement("a");
    link.href = url;
    const headers = data.headers;
    const contentDisposition = headers["content-disposition"];

    let fileName = "";
    const fileNameMatch =
      contentDisposition && contentDisposition.match(/filename\*=utf-8''(.+)/);
    if (fileNameMatch && fileNameMatch[1]) {
      fileName = decodeURIComponent(fileNameMatch[1]);
    }
    if (!fileName) {
      // リンクを削除
      link.remove();
      return;
    }
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    // リンクを削除
    link.remove();
  };

  /**
   * ダウンロードAPI失敗時
   */
  const onDownloadFilesError = (error) => {
    if (!isLoading) return;
    setLoading(false);
    setDownloadRequest({} as FileDownloadRequest);
    onCommonBlobError(error);
  };

  /**
   * ダウンロードAPI処理
   * @returns
   */
  const downloadFiles = async (
    type: string,
    oragnizationId: string,
    date?: string
  ) => {
    if (isLoading) return;
    setFileType(type);
    downloadRequest.file_type = type;
    downloadRequest.organization_id = oragnizationId;
    if (date) downloadRequest.first_day_of_target_month = date;
    setDownloadRequest(downloadRequest);
    setLoading(true);
    exeDownloadFiles.refetch({ queryKey: ["downloadFiles", type] });
  };

  const getDownloadRequest = () => {
    downloadRequest.time_zone_offset = CommonUtils.getTimeZoneOffset();
    return downloadRequest;
  };

  // ダウンロードAPI実行
  const exeDownloadFiles = FileApi.DownloadFiles(
    getDownloadRequest(),
    onDownloadFilesSuccess,
    onDownloadFilesError
  );

  /**
   * 削除API成功時
   */
  const onDeleteFilesSuccess = (data) => {
    if (!isLoading) return;
    setLoading(false);
    appContext?.setAlertMessage(Message.SUCCESS_DELETE_FILES);
    appContext?.setSuccessOpen(true);
    // アップロード履歴API再実行で、レンダリングを行う
    exeUploadsHistories.refetch();
  };

  /**
   * 削除API失敗時
   */
  const onDeleteFilesError = (error) => {
    if (!isLoading) return;
    setLoading(false);
    onCommonError(error);
  };

  /**
   * 削除API処理
   * @returns
   */
  const deleteFiles = async (
    type: string,
    fromDate: string,
    toDate: string,
    oragnizationId: string
  ) => {
    if (isLoading) return;
    setFileType(type);
    if (type === FileTypeEnum.energy_usage_multi_months) {
      deleteRequest.file_type = FileTypeEnum.energy_usage;
    } else {
      deleteRequest.file_type = type;
    }
    deleteRequest.organization_id = oragnizationId;
    deleteRequest.delete_from = fromDate;
    deleteRequest.delete_to = toDate;
    setDeleteRequest(deleteRequest);
    setLoading(true);
    exeDeleteFiles.refetch();
  };

  const getDeleteRequest = () => {
    return deleteRequest;
  };

  // 削除API実行
  const exeDeleteFiles = FileApi.DeleteFiles(
    getDeleteRequest(),
    onDeleteFilesSuccess,
    onDeleteFilesError
  );

  return {
    getUploadsHistories,
    uploadFiles,
    downloadFiles,
    deleteFiles,
    isLoading,
    response,
    historiesResponse,
  };
};
