import React, { useEffect } from "react";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import { Box, Link, Stack } from "@mui/material";
import econipass from "../../../assets/images/EcoNiPass.png";
import { useResetPassword } from "../../../hooks/useResetPassword.tsx";
import ResetMailField from "../../fields/Login/ResetMailField.tsx";
import classes from "./ResetMailForm.module.scss";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTranslation } from "react-i18next";

const ResetMailForm = () => {
  const { t } = useTranslation();
  const { useResetMailApi, response, isLoading, errorMessage } =
    useResetPassword();

  const MailSend = () => {
    useResetMailApi();
  };

  useEffect(() => {}, [response]);

  return (
    <Stack spacing={2}>
      <Box>
        <img src={econipass} width={"350vw"} height={"100vw"} alt="" />
      </Box>
      <h3>{t("resetSendMail.title")}</h3>
      {!response && (
        <>
          <Box>
            <ResetMailField />
          </Box>
          <Box sx={{ pt: "3vh" }}>
            <BaseButton
              label={t("resetSendMail.sendMail")}
              className={classes.send_button}
              handleClick={MailSend}
            />
          </Box>
          <Link href="/login" underline="none">
            {t("resetSendMail.backLogin")}
          </Link>
        </>
      )}
      {response && (
        <>
          <div
            dangerouslySetInnerHTML={{ __html: t("resetSendMail.sendedMail") }}
          />
        </>
      )}
      {errorMessage && (
        <Box sx={{ py: 1 }} color={"red"} fontWeight={"bold"}>
          {errorMessage}
        </Box>
      )}
      {isLoading && (
        <CircularProgressDialog title={t("circularProgress.sendingMail")} />
      )}
    </Stack>
  );
};

export default ResetMailForm;
