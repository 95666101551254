import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import HeaderForm from "../../forms/Header/HeaderForm.tsx";
import SideMenuForm from "../../forms/SideMenu/SideMenuForm.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import MainComponentForm from "../../forms/Common/MainComponentForm.tsx";
import { useMasterData } from "../../../hooks/useMasterData.tsx";
import { useUser } from "../../../hooks/useUser.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import MainContentFeatures from "../../../features/MainContentFeatures.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import { useLocation } from "react-router-dom";
import MasterContext from "../../../contexts/MasterContext.tsx";
import { MasterDatas } from "../../../types/response/MasterDataResponse.tsx";

type CommonLayoutProps = {
  children: React.ReactNode;
  isSuccess: boolean;
  isLoading: boolean;
};

const CommonLayout: React.FC<CommonLayoutProps> = ({
  children,
  isSuccess,
  isLoading,
}) => {
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData as MasterDatas;
  const { getMasterDataApi, masterApiLoading, setMasterApiLoading } =
    useMasterData();
  const { getUserApi, userApiLoading } = useUser();
  const { setRoleAndOption } = MainContentFeatures();
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    // ユーザー情報取得API
    if (appContext?.accessToken.sub && (!userInfo || !userInfo.id)) {
      getUserApi();
    }
    if (CommonUtils.checkSearchPath(location)) {
      // マスターデータ取得API
      getMasterDataApi();
    } else {
      setMasterApiLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userContext?.userInfo, appContext?.accessToken, isSuccess]);

  useEffect(() => {
    if (
      !isLoading &&
      isSuccess &&
      userContext?.userInfo &&
      !masterApiLoading &&
      !userApiLoading
    ) {
      setLoading(true);
      setRoleAndOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoading,
    isSuccess,
    userContext?.userInfo,
    masterApiLoading,
    userApiLoading,
  ]);

  return (
    <>
      {loading && (
        <Box display={"flex"}>
          <HeaderForm />
          <SideMenuForm />
          <MainComponentForm children={children} />
        </Box>
      )}
      {!loading && <CircularProgressDialog />}
    </>
  );
};

export default CommonLayout;
