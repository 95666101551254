import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import { TargetInfo } from "../types/response/TargetResponse.tsx";
import TargetApi from "../features/api/TargetApi.tsx";
import DatamartSearchContext from "../contexts/DatamartSearchContext.tsx";
import DatamartSummaryContext from "../contexts/DatamartSummaryContext.tsx";
import DatamartFacilityContext from "../contexts/DatamartFacilityContext.tsx";
import DatamartProductContext from "../contexts/DatamartProductContext.tsx";

export const useTarget = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const [targets, setTargets] = useState<TargetInfo>({
    product_target_value: 0,
    equipment_target_value: 0,
  } as TargetInfo);
  const [targetLoading, setTargetLoading] = useState(false);
  const [productTargetValue, setProductTargetValue] = useState(0);
  const [equipmentTargetValue, setEquipmentTargetValue] = useState(0);
  const { searchData } = useContext(DatamartSearchContext);
  const summaryContext = useContext(DatamartSummaryContext);
  const facilityContext = useContext(DatamartFacilityContext);
  const productContext = useContext(DatamartProductContext);

  /**
   * 目標値取得API成功時
   */
  const onSuccess = (data) => {
    setTargetLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setProductTargetValue(data.product_target_value);
    setEquipmentTargetValue(data.equipment_target_value);
    if (summaryContext) {
      summaryContext.setTargets(data);
    }
    if (facilityContext) {
      facilityContext.setTargets(data);
    }
    if (productContext) {
      productContext.setTargets(data);
    }
    setTargets(data);
  };

  /**
   * 目標値取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setTargetLoading(false);
  };

  /**
   * 目標値取得API
   */
  const getTargetApi = () => {
    if (
      !searchData ||
      !searchData.filter_params.products ||
      !searchData.filter_params.products.length
    ) {
      return;
    }
    setTargetLoading(true);
    exeGetTarget.refetch();
  };

  const getProductCode = () => {
    if (
      !searchData ||
      !searchData.filter_params.products ||
      !searchData.filter_params.products.length
    ) {
      return "";
    }
    return searchData.filter_params.products[0].id;
  };

  // 目標値取得API実行
  const exeGetTarget = TargetApi.GetTargets(
    getProductCode(),
    onSuccess,
    onError
  );

  /**
   * 目標値更新API成功時
   */
  const onPutSuccess = (data) => {
    setTargetLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    if (summaryContext) {
      summaryContext.setTargets({
        product_target_value: productTargetValue,
        equipment_target_value: equipmentTargetValue,
      });
    }
    if (facilityContext) {
      facilityContext.setTargets({
        product_target_value: productTargetValue,
        equipment_target_value: equipmentTargetValue,
      });
    }
    if (productContext) {
      productContext.setTargets({
        product_target_value: productTargetValue,
        equipment_target_value: equipmentTargetValue,
      });
    }
    setTargets({
      product_target_value: productTargetValue,
      equipment_target_value: equipmentTargetValue,
    });
  };

  /**
   * 目標値更新API
   */
  const putTargetApi = (productTarget: number, equipmentTarget: number) => {
    if (!searchData || !searchData.filter_params.products.length) {
      return;
    }
    setTargetLoading(true);
    exePutTarget.mutate({
      product_code: searchData.filter_params.products[0].id,
      product_target_value: productTarget,
      equipment_target_value: equipmentTarget,
    });
  };

  // 目標値更新API実行
  const exePutTarget = TargetApi.PutTargets(onPutSuccess, onError);

  return {
    getTargetApi,
    putTargetApi,
    targetLoading,
    targets,
    productTargetValue,
    setProductTargetValue,
    equipmentTargetValue,
    setEquipmentTargetValue,
  };
};
