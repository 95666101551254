import React from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import classes from "./PasswordText.module.scss";

type PasswordTextProps = {
  label: string;
  classNameForm?: string;
  className?: string;
  value?: string;
  required?: boolean;
  inputProps?: {};
  handleInput?: (event) => void;
  handleChange?: (event) => void;
};

const PasswordText: React.FC<PasswordTextProps> = (props) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{ pt: "1.5vh" }}
      variant="filled"
      className={`${classes.passwordForm} ${props.classNameForm}`}
    >
      <InputLabel htmlFor="password-text" className={classes.password}>
        {props.label}
      </InputLabel>
      <OutlinedInput
        id="password-text"
        type={showPassword ? "text" : "password"}
        required={props.required ? true : false}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={props.label}
        className={props.className}
        defaultValue={props.value ? props.value : ""}
        inputProps={props.inputProps ? props.inputProps : {}}
        onInput={props.handleInput ? props.handleInput : undefined}
        onChange={props.handleChange ? props.handleChange : undefined}
      />
    </FormControl>
  );
};

export default PasswordText;
