import {
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import classes from "./PDSTableDialog.module.scss";
import DatamartPdsTable from "../../../types/response/DatamartPdsTable";
import { useTranslation } from "react-i18next";

type PDSTableDialogProps = {
  className?: string;
  pdsData: DatamartPdsTable[];
  open: boolean;
  handleClose: () => void;
};

const PDSTableDialog: React.FC<PDSTableDialogProps> = (props) => {
  const { t } = useTranslation();
  const getTotalScore = (pdsData: DatamartPdsTable[]) => {
    let totalScore = 0;
    pdsData.forEach((data) => {
      totalScore += Number(data.pds_score);
    });
    return totalScore;
  };

  return (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle className={classes.title}>
        {t("dialog.pds.title")}
      </DialogTitle>
      <DialogContent>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table
            sx={{ minWidth: "25vw", width: "25vw" }}
            aria-label="pds_matrix"
          >
            <TableHead>
              <TableRow>
                <TableCell>{t("dialog.pds.energy")}</TableCell>
                <TableCell>{t("dialog.pds.pdsScore")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key="all"
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {t("dialog.pds.amount")}
                </TableCell>
                <TableCell>{getTotalScore(props.pdsData)}</TableCell>
              </TableRow>
              {props.pdsData.map((data) => (
                <TableRow
                  key={data.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {data.name}
                  </TableCell>
                  <TableCell align="right">{data.pds_score}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default PDSTableDialog;
