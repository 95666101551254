import React, { useContext, useState } from "react";
import classes from "./TopContentForm.module.scss";
import { Box } from "@mui/material";
import DisplaySwitchingField from "../../fields/Summary/DisplaySwitchingField.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import MasterContext from "../../../contexts/MasterContext.tsx";
import {
  defaultMasterDatas,
  MasterDatas,
} from "../../../types/response/MasterDataResponse.tsx";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import { useTranslation } from "react-i18next";
import GoalValueField from "../../fields/Product/GoalValueField.tsx";

type TopContentFormProps = {};

const TopContentForm: React.FC<TopContentFormProps> = (props) => {
  const { t } = useTranslation();
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;
  const { createSelectProduct, setSearchDataBySelect, setSelectItem } =
    SearchFeatures();
  const [selectProduct, setSelectProduct] = useState(setSelectItem("products"));

  const handleSelectClick = (event) => {
    const name = event.target.value;
    setSelectProduct(name);
    setSearchDataBySelect(name, "products", masterData);
  };
  return (
    <div className={classes.topContent}>
      <Box className={classes.topContentLeft}>
        <SelectItem
          title={t("product.productSelectTitle")}
          handleClick={handleSelectClick}
          selectValue={selectProduct || ""}
          values={createSelectProduct(masterData)}
          className={classes.selectItem}
          classNameSelect={classes.selectBox}
        />
      </Box>
      <Box className={classes.topContentRight}>
        <GoalValueField />
      </Box>
      <Box className={classes.topContentRight}>
        <DisplaySwitchingField type="per" />
      </Box>
    </div>
  );
};

export default TopContentForm;
