import React from "react";
import classes from "./RightContentForm.module.scss";
import { Box } from "@mui/material";
import NoticeContentField from "../../fields/Top/NoticeContentField.tsx";
import ActivityHistoryField from "../../fields/Top/ActivityHistoryField.tsx";
import { NoticeInfo } from "../../../types/response/NoticeResponse.tsx";

type RightContentFormProps = {
  notices: NoticeInfo[];
};

const RightContentForm: React.FC<RightContentFormProps> = (props) => {
  return (
    <>
      <Box className={classes.noticeContent}>
        <NoticeContentField notices={props.notices} />
      </Box>
      <Box className={classes.activityHistory}>
        <ActivityHistoryField />
      </Box>
    </>
  );
};

export default RightContentForm;
