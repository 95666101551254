import { Paper, Tooltip, Typography } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import React, { useContext } from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import classes from "./EmissionsTrendGraphField.module.scss";
import LineGraph from "../../parts/Chart/LineGraph.tsx";
import DatamartYoyData from "../../../types/response/DatamartYoyData.tsx";
import { useTranslation } from "react-i18next";
import DatamartCompanyContext from "../../../contexts/DatamartCompanyContext.tsx";

type EmissionsTrendGraphFieldProps = {
  data: {
    average_yoy_data: DatamartYoyData[];
    yoy_data: DatamartYoyData[];
  };
};

const EmissionsTrendGraphField: React.FC<EmissionsTrendGraphFieldProps> = (
  props
) => {
  const { t } = useTranslation();
  const { trendTab, setTrendTab } = useContext(DatamartCompanyContext);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTrendTab(newValue);
  };

  return (
    <Paper elevation={1} className={classes.emissionsGraph}>
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "left",
        }}
        color="text.secondary"
        gutterBottom
        className={classes.title}
      >
        {t("company.emissionTrend.title")}
      </Typography>
      <Tooltip title={t("company.emissionTrend.tooltip")}>
        <HelpOutlineIcon
          className={classes.toolTipIcon}
          sx={{ color: "text.secondary" }}
        />
      </Tooltip>
      <TabContext value={trendTab}>
        <TabList
          onChange={handleChange}
          aria-label="totalTrendGraph"
          className={classes.tabList}
        >
          <Tab
            label={t("company.emissionTrend.tabYoy")}
            value="1"
            className={classes.tab}
          />
          <Tab
            label={t("company.emissionTrend.tabAveYoy")}
            value="2"
            className={classes.tab}
          />
        </TabList>
        <TabPanel value="1" className={classes.tabPanel}>
          <LineGraph
            title={t("company.emissionTrend.yoyScopeGraph")}
            data={props.data ? props.data.yoy_data : []}
            yLabel={t("company.emissionTrend.yoyScopeYLabel")}
          />
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanel}>
          <LineGraph
            title={t("company.emissionTrend.aveYoyScopeGraph")}
            data={props.data ? props.data.average_yoy_data : []}
            yLabel={t("company.emissionTrend.aveYoyScopeYLabel")}
          />
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default EmissionsTrendGraphField;
