import React, { useContext, useState } from "react";
import PCFHeatMapField from "../../fields/Facility/PCFHeatMapField.tsx";
import { Box } from "@mui/material";
import classes from "./PCFHeatMapForm.module.scss";
import DisplaySwitchingField from "../../fields/Summary/DisplaySwitchingField.tsx";
import DatamartPcfHeatmap from "../../../types/response/DatamartPcfHeatmap.tsx";
import EmissionSwitchingField from "../../fields/Facility/EmissionSwitchingField.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import MasterContext from "../../../contexts/MasterContext.tsx";
import {
  defaultMasterDatas,
  MasterDatas,
} from "../../../types/response/MasterDataResponse.tsx";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import { useTranslation } from "react-i18next";

type PCFHeatMapFormProps = {
  data: {
    major_category_data: DatamartPcfHeatmap[];
    middle_category_data: DatamartPcfHeatmap[];
    minor_category_data: DatamartPcfHeatmap[];
    parts_or_energies_data: DatamartPcfHeatmap[];
  };
  emissionValue: string;
};

const PCFHeatMapForm: React.FC<PCFHeatMapFormProps> = (props) => {
  const { t } = useTranslation();
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;
  const {
    createSelectSelfManufacturing,
    setSearchDataBySelect,
    setSelectItem,
  } = SearchFeatures();
  const [selectSelfManufacturing, setSelectSelfManufacturing] = useState(
    setSelectItem("self_manufacturing")
  );
  const handleSelectClick = (event) => {
    const id = event.target.value;
    setSelectSelfManufacturing(id);
    setSearchDataBySelect(id, "self_manufacturing", masterData);
  };

  return (
    <>
      <Box className={classes.topContent}>
        <Box className={classes.displaySwitching}>
          <DisplaySwitchingField type="kg" />
        </Box>
        <Box className={classes.displaySwitching}>
          <EmissionSwitchingField value={props.emissionValue} />
        </Box>
        <Box className={classes.displaySwitching}>
          <SelectItem
            title={t("facility.categorySelectTitle")}
            handleClick={handleSelectClick}
            selectValue={selectSelfManufacturing}
            values={createSelectSelfManufacturing(masterData)}
            className={classes.selectItem}
            classNameSelect={classes.selectBox}
          />
        </Box>
      </Box>
      <Box className={classes.pcfHeatMap}>
        <PCFHeatMapField
          data={props.data}
          emissionValue={props.emissionValue}
        />
      </Box>
    </>
  );
};

export default PCFHeatMapForm;
