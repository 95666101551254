import { useQuery } from "react-query";
import axios from "axios";
import InquiriesRequest from "../../types/request/InquiriesRequest";

const InquiryApi = {
  /**
   * お問い合わせ送信API
   * @param request
   */
  PostInquiry: (request: InquiriesRequest, onSuccess, onError) => {
    return useQuery(
      "postInquiry",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/inquiries/",
          request,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default InquiryApi;
