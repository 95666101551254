import { useEffect } from "react";
import { useRefresh } from "../../../hooks/useRefresh.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import { useNavigate } from "react-router-dom";

const RootPage = () => {
  const navigate = useNavigate();
  const { checkExpiryDate } = useRefresh();

  /**
   * ログイン状態に応じて、以下のページにアクセスしていく
   * ログイン状態且つ、有効期限内：ホーム画面
   * ログイン未状態：ログイン画面
   */
  useEffect(() => {
    if (!CommonUtils.isLogin()) return navigate("/login");
    if (!checkExpiryDate()) {
      return navigate("/login");
    }
    return navigate("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });
};

export default RootPage;
