import React, { useContext, useEffect } from "react";
import classes from "./SummaryLayout.module.scss";
import PCFHeatMapForm from "../../forms/Summary/PCFHeatMapForm.tsx";
import { Box, Grid } from "@mui/material";
import PCFSingleDisplayForm from "../../forms/Summary/PCFSingleDisplayForm.tsx";
import PCFLineGraphForm from "../../forms/Summary/PCFLineGraphForm.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import { useDatamartSummary } from "../../../hooks/useDatamartSummary.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTarget } from "../../../hooks/useTarget.tsx";
import DatamartSummaryContext from "../../../contexts/DatamartSummaryContext.tsx";

type SummaryLayoutProps = {};

const SummaryLayout: React.FC<SummaryLayoutProps> = (props) => {
  const searchContext = useContext(DatamartSearchContext);
  const { saveSummaryData, selectCells } = useContext(DatamartSummaryContext);
  const { getDatamartSummaryApi, datamartData, isLoading } =
    useDatamartSummary();
  const { getTargetApi, targetLoading } = useTarget();

  useEffect(() => {
    if (!searchContext?.exeSearch) return;
    // データマート取得API
    getDatamartSummaryApi();
    // 目標値取得API
    getTargetApi();
    searchContext.setSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext?.exeSearch]);

  return (
    <>
      {!isLoading && !targetLoading && (
        <>
          <Box className={classes.pcfHeatMap}>
            <PCFHeatMapForm
              data={
                selectCells.length
                  ? saveSummaryData.pcf_heatmap
                  : datamartData.pcf_heatmap
              }
            />
          </Box>
          <Box className={classes.pcf} sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <PCFSingleDisplayForm
                  data={datamartData.statistics}
                  pdsData={datamartData.pds_table}
                />
              </Grid>
              <Grid item xs={8}>
                <PCFLineGraphForm data={datamartData} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {(isLoading || targetLoading) && <CircularProgressDialog />}
    </>
  );
};

export default SummaryLayout;
