import { useMutation } from "react-query";
import axios from "axios";
import UserInfoResponse from "../../types/response/UserInfoResponse";

const UserApi = {
  /**
   * ユーザー情報取得API
   * @param useId
   */
  GetUserInfo: (onSuccess, onError) => {
    return useMutation(
      async (userId: string) => {
        const response = await axios.get<UserInfoResponse>(
          process.env.REACT_APP_HOST_URL + "/v1/admin/users/" + userId,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default UserApi;
