import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import TopLayout from "../../layouts/Top/TopLayout.tsx";
import { UploadHistoriesProvider } from "../../../contexts/UploadHistoriesContext.tsx";

const TopPage = () => {
  return (
    <UploadHistoriesProvider>
      <ContentLayout>
        <TopLayout />
      </ContentLayout>
    </UploadHistoriesProvider>
  );
};

export default TopPage;
