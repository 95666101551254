import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import React from "react";
import classes from "./CircularProgressDialog.module.scss";
import { useTranslation } from "react-i18next";

type CircularProgressDialogProps = {
  title?: string;
  className?: string;
};

const CircularProgressDialog: React.FC<CircularProgressDialogProps> = (
  props
) => {
  const { t } = useTranslation();
  const TransparentDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "none",
    },
  }));

  return (
    <TransparentDialog open={true}>
      <DialogContent>
        <CircularProgress size="100px" className={classes.circular} />
      </DialogContent>
      <DialogTitle className={classes.title}>
        {props.title ? props.title : t("circularProgress.reading")}
      </DialogTitle>
    </TransparentDialog>
  );
};

export default CircularProgressDialog;
