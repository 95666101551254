import React, { useContext } from "react";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import { Box, Link, Stack } from "@mui/material";
import econipass from "../../../assets/images/EcoNiPass.png";
import { useResetPassword } from "../../../hooks/useResetPassword.tsx";
import ResetPasswordField from "../../fields/Login/ResetPasswordField.tsx";
import classes from "./ResetPasswordForm.module.scss";
import { useLocation } from "react-router-dom";
import LoginContext from "../../../contexts/LoginContext.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTranslation } from "react-i18next";

const ResetPasswordForm = () => {
  const { t } = useTranslation();
  const loginContext = useContext(LoginContext);
  const { useResetPasswordApi, response, isLoading, errorMessage } =
    useResetPassword();
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  loginContext?.setResetToken(query.get("reset_token") || "");

  const ResetPassword = () => {
    useResetPasswordApi();
  };

  return (
    <Stack spacing={2}>
      <Box>
        <img src={econipass} width={"350vw"} height={"100vw"} alt="" />
      </Box>
      <h3>{t("passwordReset.title")}</h3>
      {loginContext?.resetToken && !response && (
        <>
          <Box>
            <ResetPasswordField />
          </Box>
          <Box sx={{ pt: "3vh" }}>
            <BaseButton
              label={t("passwordReset.resetButton")}
              className={classes.reset_button}
              handleClick={ResetPassword}
            />
          </Box>
        </>
      )}
      {loginContext?.resetToken && response && (
        <>
          <Box>{t("passwordReset.doneResetPassword")}</Box>
          <Link href="/login" underline="none">
            {t("passwordReset.backLogin")}
          </Link>
        </>
      )}
      {!loginContext?.resetToken && <Box>{t("passwordReset.errorLink")}</Box>}
      {errorMessage && (
        <Box sx={{ py: 1 }} color={"red"} fontWeight={"bold"}>
          {errorMessage}
        </Box>
      )}
      {isLoading && (
        <CircularProgressDialog title={t("circularProgress.running")} />
      )}
    </Stack>
  );
};

export default ResetPasswordForm;
