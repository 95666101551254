import React from "react";
import classes from "./HelpLayout.module.scss";
import CustomerHelpForm from "../../forms/Help/CustomerHelpForm.tsx";
import HelpLinkForm from "../../forms/Help/HelpLinkForm.tsx";
import { Box } from "@mui/material";

type HelpLayoutProps = {};

const HelpLayout: React.FC<HelpLayoutProps> = (props) => {
  return (
    <>
      <Box className={classes.customerHelp}>
        <CustomerHelpForm />
      </Box>
      <Box className={classes.helpLink}>
        <HelpLinkForm />
      </Box>
    </>
  );
};

export default HelpLayout;
