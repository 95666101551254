import React, { useState } from "react";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import classes from "./PCFSingleDisplayForm.module.scss";
import { Box, Grid } from "@mui/material";
import SingleDisplay from "../../parts/Item/SingleDisplay.tsx";
import DatamartStatistics from "../../../types/response/DatamartStatistics.tsx";
import DatamartPdsTable from "../../../types/response/DatamartPdsTable.tsx";
import PDSTableDialog from "../../parts/Dialog/PDSTableDialog.tsx";

type PCFSingleDisplayFormProps = {
  data: DatamartStatistics[];
  pdsData: DatamartPdsTable[];
};

const PCFSingleDisplayForm: React.FC<PCFSingleDisplayFormProps> = (props) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getData = (type) => {
    if (!props.data) {
      return {
        type: "",
        unit: "",
        value: 0,
      };
    }
    return (
      props.data.find((data) => {
        return data.type === type;
      }) || {
        type: "",
        unit: "",
        value: 0,
      }
    );
  };

  return (
    <div className={classes.singleDisplayContent}>
      <Box className={classes.singleDisplay}>
        <SingleDisplay data={getData("pcf")} type="summary" />
      </Box>
      <Box className={classes.singleDisplay}>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay data={getData("pcf_pysm")} type="summary" />
          </Grid>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay
              data={getData("pcf_pysm_changes_rate")}
              type="summary"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.singleDisplay}>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay data={getData("pcf_base")} type="summary" />
          </Grid>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay
              data={getData("pcf_base_changes_rate")}
              type="summary"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.pdsButtonItem}>
        <BaseButton
          label="PDS"
          handleClick={handleClickOpen}
          className={classes.pdsButton}
        />
      </Box>
      <Box className={classes.singleDisplay}>
        <SingleDisplay data={getData("offset_rate")} type="summary" />
      </Box>
      <Box className={classes.singleDisplay}>
        <SingleDisplay
          data={getData("recycle_material_use_rate")}
          type="summary"
        />
      </Box>
      <PDSTableDialog
        pdsData={props.pdsData}
        handleClose={handleClose}
        open={open}
      />
    </div>
  );
};

export default PCFSingleDisplayForm;
