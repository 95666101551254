import React, { createContext, useState } from "react";
import LoginRequest from "../types/LoginRequest.tsx";

// ログインコンテキスト
const LoginContext = createContext<LoginRequest | null>(null);

type Props = {
  children: React.ReactNode;
};

export const LoginProvider: React.VFC<Props> = ({ children }) => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [onetimePassword, setOnetimePassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [resetToken, setResetToken] = useState("");

  return (
    <LoginContext.Provider
      value={{
        id,
        setId,
        password,
        setPassword,
        onetimePassword,
        setOnetimePassword,
        rePassword,
        setRePassword,
        resetToken,
        setResetToken,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
