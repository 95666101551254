import { Alert, AlertTitle, Box, Collapse } from "@mui/material";
import React, { useContext } from "react";
import classes from "./SuccessAlert.module.scss";
import AppContext from "../../../contexts/AppContext.tsx";

const SuccessAlert: React.FC = (props) => {
  const appContext = useContext(AppContext);
  return (
    <Collapse in={appContext?.successOpen}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Alert
          severity="success"
          onClose={() => {
            appContext?.setSuccessOpen(false);
            appContext?.setAlertMessage("");
          }}
          className={classes.successAlert}
        >
          <AlertTitle>Success</AlertTitle>
          {appContext?.alertMessage}
        </Alert>
      </Box>
    </Collapse>
  );
};

export default SuccessAlert;
