import { useQuery } from "react-query";
import axios from "axios";
import UploadsHisotriesRequest from "../../types/request/UploadsHisotriesRequest";
import UploadsHistoriesResponse from "../../types/response/UploadsHistoriesResponse";
import FileUploadRequest from "../../types/request/FileUploadRequest";
import FileDownloadRequest from "../../types/request/FileDownloadRequest";
import FileOrganizationRequest from "../../types/request/FileOrganizationRequest.tsx";
import FileSummaryRequest from "../../types/request/FileSummaryRequest.tsx";
import FileProductRequest from "../../types/request/FileProductRequest.tsx";
import FileEquipmentRequest from "../../types/request/FileEquipmentRequest.tsx";
import FileDeleteRequest from "../../types/request/FileDeleteRequest.tsx";
import CommonUtils from "../../common/utils/CommonUtils.tsx";

const qs = require("qs");
const FileApi = {
  /**
   * アップロード履歴API
   * @param request
   */
  UploadsHistories: (request: UploadsHisotriesRequest, onSuccess, onError) => {
    return useQuery(
      "uploadsHistories",
      async () => {
        const response = await axios.get<UploadsHistoriesResponse[]>(
          process.env.REACT_APP_HOST_URL + "/v1/histories/uploads",
          {
            params: request,
            paramsSerializer: (params) =>
              qs.stringify(params, { arrayFormat: "repeat" }),
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * アップロードAPI
   * @param request
   */
  UploadFiles: (request: FileUploadRequest, onSuccess, onError) => {
    return useQuery(
      "uploadFiles",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL +
            "/v1/files/upload?file_type=" +
            request.file_type,
          request.files,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * ダウンロードAPI
   * @param request
   */
  DownloadFiles: (request: FileDownloadRequest, onSuccess, onError) => {
    return useQuery(
      "downloadFiles",
      async () => {
        const response = await axios.get<Blob>(
          process.env.REACT_APP_HOST_URL + "/v1/files/download",
          {
            params: request,
            responseType: "blob",
            headers: {
              Accept: "application/octet-stream",
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },

  /**
   * ファイルデータ削除API
   * @param request
   */
  DeleteFiles: (request: FileDeleteRequest, onSuccess, onError) => {
    return useQuery(
      "deleteFiles",
      async () => {
        const response = await axios.delete(
          process.env.REACT_APP_HOST_URL + "/v1/files/delete",
          {
            data: request,
            headers: {
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
  /**
   * 企業軸帳票出力API
   * @param request
   */
  DownloadOrganization: (
    request: FileOrganizationRequest,
    onSuccess,
    onError
  ) => {
    return useQuery(
      "downloadOrganization",
      async () => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL +
            "/v1/files/outputs/organization/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: {
              Accept: "application/octet-stream",
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
  /**
   * サマリ帳票出力API
   * @param request
   */
  DownloadSummary: (request: FileSummaryRequest, onSuccess, onError) => {
    return useQuery(
      "downloadSummary",
      async () => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL + "/v1/files/outputs/summary/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: {
              Accept: "application/octet-stream",
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
  /**
   * 製品軸帳票出力API
   * @param request
   */
  DownloadProduct: (request: FileProductRequest, onSuccess, onError) => {
    return useQuery(
      "downloadProduct",
      async () => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL + "/v1/files/outputs/product/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: {
              Accept: "application/octet-stream",
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
  /**
   * 設備帳票出力API
   * @param request
   */
  DownloadEquipment: (request: FileEquipmentRequest, onSuccess, onError) => {
    return useQuery(
      "downloadEquipment",
      async () => {
        const response = await axios.post<Blob>(
          process.env.REACT_APP_HOST_URL +
            "/v1/files/outputs/equipment/download",
          request,
          {
            responseType: "blob",
            params: {
              time_zone_offset: CommonUtils.getTimeZoneOffset(),
            },
            headers: {
              Accept: "application/octet-stream",
              authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        return { data: response.data, headers: response.headers };
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default FileApi;
