import { Paper, Typography } from "@mui/material";
import React from "react";
import classes from "./SingleDisplay.module.scss";
import DatamartStatistics from "../../../types/response/DatamartStatistics.tsx";
import { SingleDisplayType } from "../../../types/enum/SingleDisplayEnum.tsx";

type SingleDisplayProps = {
  data: DatamartStatistics;
  type: string;
};

const SingleDisplay: React.FC<SingleDisplayProps> = (props) => {
  const {
    SingleDisplayEnumEquipment,
    SingleDisplayEnumOrganization,
    SingleDisplayEnumProduct,
    SingleDisplayEnumSummary,
  } = SingleDisplayType();

  const getTitle = () => {
    switch (props.type) {
      case "organization":
        return SingleDisplayEnumOrganization[props.data.type];
      case "summary":
        return SingleDisplayEnumSummary[props.data.type];
      case "product":
        return SingleDisplayEnumProduct[props.data.type];
      case "equipment":
        return SingleDisplayEnumEquipment[props.data.type];
      default:
        return "";
    }
  };

  const createSingleData = (value: number | null) => {
    if (value === undefined || value === null) {
      return "";
    }
    if (value < 0) {
      return "▲" + Math.abs(value).toLocaleString();
    }
    return value.toLocaleString();
  };

  return (
    <Paper elevation={1} className={classes.singleDisplay}>
      <Typography color="text.secondary" gutterBottom className={classes.label}>
        {getTitle()}
      </Typography>
      <Typography variant="h5" component="div" className={classes.data}>
        {createSingleData(props.data.value)}
      </Typography>
      <Typography
        sx={{ textAlign: "right" }}
        color="text.secondary"
        className={classes.unit}
      >
        {props.data.unit}
      </Typography>
    </Paper>
  );
};

export default SingleDisplay;
