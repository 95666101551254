import React, { createContext, useState } from "react";
import DatamartEmissionsData from "../types/response/DatamartEmissionsData";

type PieGraphType = {
  data_by_energy: DatamartEmissionsData[];
  data_by_organization: DatamartEmissionsData[];
  data_by_scope: DatamartEmissionsData[];
};

type DatamartCompanyProps = {
  selectPieGraph: string[];
  setSelectPieGraph: React.Dispatch<React.SetStateAction<string[]>>;
  savePieGraph: PieGraphType;
  setSavePieGraph: React.Dispatch<React.SetStateAction<PieGraphType>>;
  selectCells: { [key: string]: string };
  setSelectCells: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  trendTab: string;
  setTrendTab: React.Dispatch<React.SetStateAction<string>>;
};

// 企業別データマート画面用コンテキスト
const DatamartCompanyContext = createContext<DatamartCompanyProps>({
  selectPieGraph: [],
  setSelectPieGraph: () => {},
  savePieGraph: {
    data_by_energy: [],
    data_by_organization: [],
    data_by_scope: [],
  },
  setSavePieGraph: () => {},
  selectCells: {},
  setSelectCells: () => {},
  tab: "1",
  setTab: () => {},
  trendTab: "1",
  setTrendTab: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const DatamartCompanyProvider: React.VFC<Props> = ({ children }) => {
  const [selectPieGraph, setSelectPieGraph] = useState<string[]>([]);
  const [savePieGraph, setSavePieGraph] = useState<PieGraphType>({
    data_by_energy: [],
    data_by_organization: [],
    data_by_scope: [],
  });
  const [selectCells, setSelectCells] = useState<{ [key: string]: string }>({});
  const [tab, setTab] = useState<string>("1");
  const [trendTab, setTrendTab] = useState<string>("1");

  return (
    <DatamartCompanyContext.Provider
      value={{
        selectPieGraph,
        setSelectPieGraph,
        savePieGraph,
        setSavePieGraph,
        selectCells,
        setSelectCells,
        tab,
        setTab,
        trendTab,
        setTrendTab,
      }}
    >
      {children}
    </DatamartCompanyContext.Provider>
  );
};

export default DatamartCompanyContext;
