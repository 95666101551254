import React, { useContext, useEffect } from "react";
import classes from "./PCFLineGraphField.module.scss";
import { Paper, Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PCFGraph from "../../parts/Chart/PCFGraph.tsx";
import { DatamartSummary } from "../../../types/response/DatamartSummaryResponse.tsx";
import DatamartFeatures from "../../../features/DatamartFeatures.tsx";
import { useTranslation } from "react-i18next";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import DatamartSummaryContext from "../../../contexts/DatamartSummaryContext.tsx";

type PCFLineGraphFieldProps = {
  data: DatamartSummary;
};

const PCFLineGraphField: React.FC<PCFLineGraphFieldProps> = (props) => {
  const { t } = useTranslation();
  const { getChartData, createChartData } = DatamartFeatures();
  const { searchData } = useContext(DatamartSearchContext);
  const { targets, selectCells, saveSummaryData, tab, setTab } = useContext(
    DatamartSummaryContext
  );
  const product = searchData.filter_params.products;

  /**
   * タブ切り替え
   * @param event
   * @param newValue
   */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Paper elevation={1} className={classes.pcfLineGraph} sx={{ mb: 1 }}>
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "left",
        }}
        color="text.secondary"
        gutterBottom
        className={classes.title}
      >
        {t("summary.lineGraph.title")}
      </Typography>
      <TabContext value={tab}>
        <TabList
          onChange={handleChange}
          aria-label="pcfLineGraph"
          className={classes.tabList}
        >
          <Tab
            label={t("summary.lineGraph.tabYearMonth")}
            value="1"
            className={classes.tab}
          />
          <Tab
            label={t("summary.lineGraph.tabCalendarYear")}
            value="2"
            className={classes.tab}
          />
          <Tab
            label={t("summary.lineGraph.tabFiscalYear")}
            value="3"
            className={classes.tab}
          />
          <Tab
            label={t("summary.lineGraph.tabBusinessYear")}
            value="4"
            className={classes.tab}
          />
        </TabList>
        <TabPanel value="1" className={classes.tabPanel} sx={{ pt: 5 }}>
          <Paper elevation={1} className={classes.pcfGraph}>
            <PCFGraph
              title={t("summary.lineGraph.yearMonth.pcfGraphTitle")}
              data={createChartData(
                getChartData(props.data.pcf_chart, "year_month"),
                getChartData(props.data.pcf_graph, "year_month"),
                t("summary.lineGraph.yearMonth.pcfGraphRightLabel")
              )}
              leftLabel={t("summary.lineGraph.yearMonth.pcfGraphLeftLabel")}
              rightLabel={t("summary.lineGraph.yearMonth.pcfGraphRightLabel")}
              targetValue={targets.product_target_value}
            />
          </Paper>
          <Paper elevation={1} className={classes.pcfGraph}>
            <PCFGraph
              title={t("summary.lineGraph.yearMonth.productGraphTitle")}
              data={createChartData(
                [
                  {
                    title: product.length ? product[0].name : "",
                    data:
                      selectCells.length === 0
                        ? props.data.production_volume_chart.year_month
                        : saveSummaryData.production_volume_chart.year_month,
                  },
                ],
                [
                  {
                    title: product.length ? product[0].name : "",
                    data:
                      selectCells.length === 0
                        ? props.data.production_volume_graph.year_month
                        : saveSummaryData.production_volume_graph.year_month,
                  },
                ],
                t("summary.lineGraph.yearMonth.productGraphRightLabel")
              )}
              leftLabel={t("summary.lineGraph.yearMonth.productGraphLeftLabel")}
              rightLabel={t(
                "summary.lineGraph.yearMonth.productGraphRightLabel"
              )}
            />
          </Paper>
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanel}>
          <Paper elevation={1} className={classes.pcfGraph}>
            <PCFGraph
              title={t("summary.lineGraph.calendarYear.pcfGraphTitle")}
              data={createChartData(
                getChartData(props.data.pcf_chart, "calendar_year"),
                getChartData(props.data.pcf_graph, "calendar_year"),
                t("summary.lineGraph.calendarYear.pcfGraphRightLabel")
              )}
              leftLabel={t("summary.lineGraph.calendarYear.pcfGraphLeftLabel")}
              rightLabel={t(
                "summary.lineGraph.calendarYear.pcfGraphRightLabel"
              )}
              targetValue={targets.product_target_value}
            />
          </Paper>
          <Paper elevation={1} className={classes.pcfGraph}>
            <PCFGraph
              title={t("summary.lineGraph.calendarYear.productGraphTitle")}
              data={createChartData(
                [
                  {
                    title: product.length ? product[0].name : "",
                    data:
                      selectCells.length === 0
                        ? props.data.production_volume_chart.calendar_year
                        : saveSummaryData.production_volume_chart.calendar_year,
                  },
                ],
                [
                  {
                    title: product.length ? product[0].name : "",
                    data:
                      selectCells.length === 0
                        ? props.data.production_volume_graph.calendar_year
                        : saveSummaryData.production_volume_graph.calendar_year,
                  },
                ],
                t("summary.lineGraph.calendarYear.productGraphRightLabel")
              )}
              leftLabel={t(
                "summary.lineGraph.calendarYear.productGraphLeftLabel"
              )}
              rightLabel={t(
                "summary.lineGraph.calendarYear.productGraphRightLabel"
              )}
            />
          </Paper>
        </TabPanel>
        <TabPanel value="3" className={classes.tabPanel}>
          <Paper elevation={1} className={classes.pcfGraph}>
            <PCFGraph
              title={t("summary.lineGraph.fiscalYear.pcfGraphTitle")}
              data={createChartData(
                getChartData(props.data.pcf_chart, "organization_fiscal_year"),
                getChartData(props.data.pcf_graph, "organization_fiscal_year"),
                t("summary.lineGraph.fiscalYear.pcfGraphRightLabel")
              )}
              leftLabel={t("summary.lineGraph.fiscalYear.pcfGraphLeftLabel")}
              rightLabel={t("summary.lineGraph.fiscalYear.pcfGraphRightLabel")}
              targetValue={targets.product_target_value}
            />
          </Paper>
          <Paper elevation={1} className={classes.pcfGraph}>
            <PCFGraph
              title={t("summary.lineGraph.fiscalYear.productGraphTitle")}
              data={createChartData(
                [
                  {
                    title: product.length ? product[0].name : "",
                    data:
                      selectCells.length === 0
                        ? props.data.production_volume_chart
                            .organization_fiscal_year
                        : saveSummaryData.production_volume_chart
                            .organization_fiscal_year,
                  },
                ],
                [
                  {
                    title: product.length ? product[0].name : "",
                    data:
                      selectCells.length === 0
                        ? props.data.production_volume_graph
                            .organization_fiscal_year
                        : saveSummaryData.production_volume_graph
                            .organization_fiscal_year,
                  },
                ],
                t("summary.lineGraph.fiscalYear.productGraphRightLabel")
              )}
              leftLabel={t(
                "summary.lineGraph.fiscalYear.productGraphLeftLabel"
              )}
              rightLabel={t(
                "summary.lineGraph.fiscalYear.productGraphRightLabel"
              )}
            />
          </Paper>
        </TabPanel>
        <TabPanel value="4" className={classes.tabPanel}>
          <Paper elevation={1} className={classes.pcfGraph}>
            <PCFGraph
              title={t("summary.lineGraph.businessYear.pcfGraphTitle")}
              data={createChartData(
                getChartData(props.data.pcf_chart, "general_business_year"),
                getChartData(props.data.pcf_graph, "general_business_year"),
                t("summary.lineGraph.businessYear.pcfGraphRightLabel")
              )}
              leftLabel={t("summary.lineGraph.businessYear.pcfGraphLeftLabel")}
              rightLabel={t(
                "summary.lineGraph.businessYear.pcfGraphRightLabel"
              )}
              targetValue={targets.product_target_value}
            />
          </Paper>
          <Paper elevation={1} className={classes.pcfGraph}>
            <PCFGraph
              title={t("summary.lineGraph.businessYear.productGraphTitle")}
              data={createChartData(
                [
                  {
                    title: product.length ? product[0].name : "",
                    data:
                      selectCells.length === 0
                        ? props.data.production_volume_chart
                            .general_business_year
                        : saveSummaryData.production_volume_chart
                            .general_business_year,
                  },
                ],
                [
                  {
                    title: product.length ? product[0].name : "",
                    data:
                      selectCells.length === 0
                        ? props.data.production_volume_graph
                            .general_business_year
                        : saveSummaryData.production_volume_graph
                            .general_business_year,
                  },
                ],
                t("summary.lineGraph.businessYear.productGraphRightLabel")
              )}
              leftLabel={t(
                "summary.lineGraph.businessYear.productGraphLeftLabel"
              )}
              rightLabel={t(
                "summary.lineGraph.businessYear.productGraphRightLabel"
              )}
            />
          </Paper>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default PCFLineGraphField;
