import React, { useContext, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import classes from "./ProductLayout.module.scss";
import LeftContentForm from "../../forms/Product/LeftContentForm.tsx";
import RightContentForm from "../../forms/Product/RightContentForm.tsx";
import TopContentForm from "../../forms/Product/TopContentForm.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import { useDatamartProduct } from "../../../hooks/useDatamartProduct.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTarget } from "../../../hooks/useTarget.tsx";
import DatamartProductContext from "../../../contexts/DatamartProductContext.tsx";

type ProductLayoutProps = {};

const ProductLayout: React.FC<ProductLayoutProps> = (props) => {
  const searchContext = useContext(DatamartSearchContext);
  const { selectCells, exeBesideBarSearch } = useContext(
    DatamartProductContext
  );
  const {
    getDatamartProductApi,
    getDatamartProductMutationApi,
    datamartData,
    isLoading,
  } = useDatamartProduct();
  const { getTargetApi, targetLoading } = useTarget();

  useEffect(() => {
    if (!searchContext?.exeSearch) return;
    // // データマート取得API
    getDatamartProductApi();
    // 目標値取得API
    getTargetApi();
    searchContext.setSearch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchContext?.exeSearch]);

  useEffect(() => {
    if (!exeBesideBarSearch) return;
    getDatamartProductMutationApi(selectCells, datamartData, targetLoading);
  }, [exeBesideBarSearch]);

  return (
    <>
      {!isLoading && !targetLoading && (
        <>
          <Box className={classes.headerContent}>
            <TopContentForm />
          </Box>
          <Box className={classes.mainContent}>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <LeftContentForm data={datamartData} />
              </Grid>
              <Grid item xs={7}>
                <RightContentForm data={datamartData} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {(isLoading || targetLoading) && <CircularProgressDialog />}
    </>
  );
};

export default ProductLayout;
