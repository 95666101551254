import React, { useContext, useEffect } from "react";
import AppContext from "../../../contexts/AppContext.tsx";
import { useNavigate } from "react-router-dom";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import { useRefresh } from "../../../hooks/useRefresh.tsx";
import CommonLayout from "./CommonLayout.tsx";
import { useTranslation } from "react-i18next";

type ContentLayoutProps = {
  children: React.ReactNode;
};

const ContentLayout: React.FC<ContentLayoutProps> = ({ children }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  const { refreshApi, checkExpiryDate, isLoading, isSuccess } = useRefresh();

  useEffect(() => {
    if (!CommonUtils.isLogin()) return navigate("/admin/login");
    if (!checkExpiryDate()) {
      // リフレッシュAPI
      refreshApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appContext?.sideItem]);

  useEffect(() => {
    const language = localStorage.getItem("language");
    if (language) {
      i18n.changeLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CommonLayout
      children={children}
      isLoading={isLoading}
      isSuccess={isSuccess}
    />
  );
};

export default ContentLayout;
