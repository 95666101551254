import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import NoticeCreateLayout from "../../layouts/NoticeCreate/NoticeCreateLayout.tsx";

const NoticeCreatePage = () => {
  return (
    <ContentLayout>
      <NoticeCreateLayout />
    </ContentLayout>
  );
};

export default NoticeCreatePage;
