import { useTranslation } from "react-i18next";
import SideMenu from "./SideMenu";

const SideMenusType = () => {
  const { t } = useTranslation();
  const SideMenus: SideMenu[] = [
    {
      id: "account-create",
      title: t("type.sideMenu.sideMenus.account-create"),
      children: [],
    },
    {
      id: "notice-management",
      title: t("type.sideMenu.sideMenus.notice-management"),
      children: [],
    },
  ];

  return {
    SideMenus,
  };
};

export default SideMenusType;
