import React, { useContext, useEffect } from "react";
import classes from "./TopLayout.module.scss";
import { Box, Grid } from "@mui/material";
import TopContentForm from "../../forms/Top/TopContentForm.tsx";
import LeftContentForm from "../../forms/Top/LeftContentForm.tsx";
import RightContentForm from "../../forms/Top/RightContentForm.tsx";
import UploadHistoriesContext from "../../../contexts/UploadHistoriesContext.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useFile } from "../../../hooks/useFile.tsx";
import { useNotices } from "../../../hooks/useNotices.tsx";

type TopLayoutProps = {};

const TopLayout: React.FC<TopLayoutProps> = (props) => {
  const uploadHistoiesContext = useContext(UploadHistoriesContext);
  const { getUploadsHistories, historiesResponse, isLoading } = useFile();
  const { getNoticesApi, noticeLoading, notices } = useNotices();
  useEffect(() => {
    // お知らせ情報取得
    getNoticesApi();
    // アップロード履歴取得API
    getUploadsHistories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!historiesResponse.length) return;
    uploadHistoiesContext?.setUploadHistories([...historiesResponse]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historiesResponse]);

  return (
    <>
      {!isLoading && !noticeLoading && (
        <>
          <Box className={classes.topContent}>
            <TopContentForm />
          </Box>
          <Box className={classes.mainContent}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <LeftContentForm />
              </Grid>
              <Grid item xs={6}>
                <RightContentForm notices={notices} />
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {(isLoading || noticeLoading) && <CircularProgressDialog />}
    </>
  );
};

export default TopLayout;
