import React from "react";
import { useTranslation } from "react-i18next";
import { TooltipProps } from "recharts";

const CustomTooltip: React.FC<
  Partial<TooltipProps<any, any>> & { total: number }
> = ({ active, payload, total }) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    const percentage = ((payload[0].value / total) * 100).toFixed(0); // 割合を計算
    const color = payload[0].payload.fill; // 該当する色を取得
    return (
      <div
        style={{
          backgroundColor: "#fff",
          border: "0.1px solid",
          borderColor: color,
          padding: "0px 1vh",
          position: "relative",
          // WebkitTextStroke: "0.1px #000",
          color: "#000",
          zIndex: 50000,
          fontSize: "2vh",
        }}
      >
        <p>{`${payload[0].name}: ${payload[0].value}`}</p>
        <p>{`${t("company.toolChip")}: ${percentage}%`}</p>
      </div>
    );
  }

  return "";
};

export default CustomTooltip;
