import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import NoticeApi from "../features/api/NoticeApi.tsx";
import { NoticeInfo } from "../types/response/NoticeResponse.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";

export const useNotices = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const [notices, setNotices] = useState<NoticeInfo[]>([]);
  const [noticeLoading, setNoticeLoading] = useState(false);

  /**
   * おしらせ一覧取得API成功時
   */
  const onSuccess = (data) => {
    setNoticeLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setNotices(data);
  };

  /**
   * おしらせ一覧取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setNoticeLoading(false);
  };

  /**
   * おしらせ一覧取得取得
   */
  const getNoticesApi = () => {
    setNoticeLoading(true);
    exeGetNotices.refetch();
  };

  // おしらせ一覧取得API実行
  const exeGetNotices = NoticeApi.GetNotices(onSuccess, onError);

  return { getNoticesApi, noticeLoading, notices };
};
