import React from "react";
import PCFHeatMapField from "../../fields/Summary/PCFHeatMapField.tsx";
import classes from "./PCFHeatMapForm.module.scss";
import { Box } from "@mui/material";
import DisplaySwitchingField from "../../fields/Summary/DisplaySwitchingField.tsx";
import DatamartPcfHeatmap from "../../../types/response/DatamartPcfHeatmap.tsx";

type PCFHeatMapFormProps = {
  data: {
    major_category_data: DatamartPcfHeatmap[];
    middle_category_data: DatamartPcfHeatmap[];
    minor_category_data: DatamartPcfHeatmap[];
    parts_or_energies_data: DatamartPcfHeatmap[];
  };
};

const PCFHeatMapForm: React.FC<PCFHeatMapFormProps> = (props) => {
  return (
    <>
      <Box className={classes.displaySwitching}>
        <DisplaySwitchingField type="per" />
      </Box>
      <Box className={classes.pcfHeatMap}>
        <PCFHeatMapField data={props.data} />
      </Box>
    </>
  );
};

export default PCFHeatMapForm;
