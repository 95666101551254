enum DashboardTypeEnum {
  statistics = "statistics",
  emissions_chart = "emissions_chart",
  emissions_graph = "emissions_graph",
  total_amount_graph = "total_amount_graph",
  base_unit_graph = "base_unit_graph",
  base_unit_table = "base_unit_table",
  pcf_heatmap = "pcf_heatmap",
  emission_heatmap = "emission_heatmap",
  pcf_chart = "pcf_chart",
  pcf_graph = "pcf_graph",
  amount_chart = "amount_chart",
  amount_graph = "amount_graph",
  pds_table = "pds_table",
  production_volume_graph = "production_volume_graph",
}

export const OrganizationDashboardType: string[] = [
  DashboardTypeEnum.statistics,
  DashboardTypeEnum.emissions_chart,
  DashboardTypeEnum.total_amount_graph,
  DashboardTypeEnum.base_unit_graph,
  DashboardTypeEnum.base_unit_table,
];

export const SummaryDashboardType: string[] = [
  DashboardTypeEnum.statistics,
  DashboardTypeEnum.pcf_heatmap,
  DashboardTypeEnum.pcf_chart,
  DashboardTypeEnum.pcf_graph,
  DashboardTypeEnum.pds_table,
  DashboardTypeEnum.production_volume_graph,
];

export const ProductDashboardType: string[] = [
  DashboardTypeEnum.statistics,
  DashboardTypeEnum.pcf_chart,
  DashboardTypeEnum.pcf_graph,
  DashboardTypeEnum.amount_chart,
  DashboardTypeEnum.amount_graph,
  DashboardTypeEnum.pds_table,
  DashboardTypeEnum.emissions_chart,
];

export const EquipmentDashboardType: string[] = [
  DashboardTypeEnum.statistics,
  DashboardTypeEnum.pcf_heatmap,
  DashboardTypeEnum.emission_heatmap,
  DashboardTypeEnum.emissions_chart,
  DashboardTypeEnum.emissions_graph,
  DashboardTypeEnum.pcf_chart,
  DashboardTypeEnum.pcf_graph,
  DashboardTypeEnum.amount_chart,
  DashboardTypeEnum.amount_graph,
  DashboardTypeEnum.pds_table,
];
