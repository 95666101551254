import RequestID from "./RequestID";
import RequestScopeType from "./RequestScopeType";

export const defaultOrganizationRequest = {
  file_type: "",
  filter_params: {
    from_month: "",
    to_month: "",
    scopes: [],
    organizations: [],
    energies: [],
  },
};

// 企業別帳票出力APIリクエスト
interface FileOrganizationRequest {
  // ファイルタイプ
  file_type: string;
  // 絞り込み条件
  filter_params: {
    // 開始日
    from_month: string;
    // 終了日
    to_month: string;
    // スコープ
    scopes: RequestScopeType[];
    // ユーザー
    organizations: RequestID[];
    // エネルギー
    energies: RequestID[];
  };
}

export default FileOrganizationRequest;
