import React, { createContext, useState } from "react";
import MasterDataResponse from "../types/response/MasterDataResponse.tsx";

type MasterProps = {
  masterData: MasterDataResponse;
  setMasterData: React.Dispatch<React.SetStateAction<MasterDataResponse>>;
};

// マスターデータコンテキスト
const MasterContext = createContext<MasterProps | null>(null);

type Props = {
  children: React.ReactNode;
};

export const MasterProvider: React.VFC<Props> = ({ children }) => {
  const [masterData, setMasterData] = useState<MasterDataResponse>(
    {} as MasterDataResponse
  );

  return (
    <MasterContext.Provider
      value={{
        masterData,
        setMasterData,
      }}
    >
      {children}
    </MasterContext.Provider>
  );
};

export default MasterContext;
