import MessageType from "../types/Message.tsx";

export const useAlert = () => {
  const Message = MessageType();
  const getStatusErrorMessage = (status: string) => {
    switch (status) {
      case "400":
      case "401":
      case "422":
        break;
      case "403":
        localStorage.clear();
        return Message.ERROR_STATUS_403;
    }
    return Message.ERROR_SYSTEM_ERROR;
  };

  return {
    getStatusErrorMessage,
  };
};
