import React, { useContext, useEffect } from "react";
import { Box, List } from "@mui/material";
import classes from "./SideMenuField.module.scss";
import SideMenu, { SideMenuChild } from "../../../types/SideMenu.tsx";
import SideMenuItem from "../../parts/ListItem/SideMenuItem.tsx";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import AppContext from "../../../contexts/AppContext.tsx";
import { ReactComponent as Symbol } from "../../../assets/images/logo_symbol.svg";
import { useNavigate } from "react-router-dom";
import SideMenusType from "../../../types/SideMenus.tsx";
import UserContext from "../../../contexts/UserContext.tsx";
import LogoutButton from "./LogoutButton.tsx";

const SideMenuField = () => {
  const { SideMenus } = SideMenusType();
  const appContext = useContext(AppContext);
  const userContext = useContext(UserContext);
  const productSpecificFlag = userContext
    ? userContext.productSpecificFlag
    : false;
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    appContext?.setSideOpen(!appContext.sideOpen);
  };

  const handleListItemClick = (id: string) => {
    let selectedId = id;
    if (id === "product_parent") {
      selectedId = "summary";
    } else if (id === "company_parent") {
      selectedId = "company";
    }

    appContext?.setSideItem(selectedId);
    sideNavigate(selectedId);
  };

  const sideNavigate = (id: string) => {
    switch (id) {
      case "account-create":
        return navigate("/admin/account-create");
      case "notice-management":
        return navigate("/admin/notice-management");
      default:
        break;
    }
  };

  const isChildren = (item: SideMenu) => {
    if (
      item.children.length !== 0 &&
      item.children.find((value) => {
        return value.id === appContext?.sideItem;
      })
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // TODO 権限によるサイドメニューの表示一覧作成
  }, []);

  const checkOption = (child: SideMenuChild) => {
    if (child.id === "product") {
      return productSpecificFlag;
    }
    return true;
  };

  return (
    <>
      <Box className={classes.icons}>
        <Symbol />
      </Box>
      <List sx={{ pt: 0 }}>
        {SideMenus.map((item) => (
          <React.Fragment key={item.id}>
            <SideMenuItem
              id={item.id}
              title={item.title}
              icon={item.id}
              open={appContext?.sideOpen || false}
              selected={appContext?.sideItem === item.id}
              handleClick={(id) => handleListItemClick(id)}
            />
            {isChildren(item) && (
              <List component="div" disablePadding>
                {item.children?.map((child) => (
                  <>
                    {checkOption(child) && (
                      <SideMenuItem
                        id={child.id}
                        title={child.title}
                        icon={child.id}
                        open={appContext?.sideOpen || false}
                        isChild
                        selected={appContext?.sideItem === child.id}
                        handleClick={(id) => handleListItemClick(id)}
                      />
                    )}
                  </>
                ))}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
      {appContext?.sideOpen ? (
        <>
          <Box className={classes.logout_button}>
            <LogoutButton />
          </Box>
          <Box className={classes.open_button}>
            <KeyboardDoubleArrowLeft onClick={handleDrawerToggle} />
          </Box>
        </>
      ) : (
        <Box className={classes.close_button}>
          <KeyboardDoubleArrowRight onClick={handleDrawerToggle} />
        </Box>
      )}
    </>
  );
};

export default SideMenuField;
