import React, { useContext } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import classes from "./DataInputForm.module.scss";
import DataInputField from "../../fields/DataInput/DataInputField.tsx";
import FileType from "../../../types/FileType.tsx";
import DownloadButton from "../../parts/Button/DownloadButton.tsx";
import UploadButton from "../../parts/Button/UploadButton.tsx";
import DeleteButton from "../../parts/Button/DeleteButton.tsx";
import { useTranslation } from "react-i18next";
import DataInputFeatures from "../../../features/DataInputFeatures.tsx";
import UserContext from "../../../contexts/UserContext.tsx";

type DataInputFormProps = {
  dispData: {
    companyFile: FileType[];
    companyFileLocations: FileType[];
    productFile: FileType[];
    energySavingFile: FileType[];
  };
};

const DataInputForm: React.FC<DataInputFormProps> = (props) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const { isPrimaryUser, dispJaItem } = DataInputFeatures();

  return (
    <>
      <Grid container spacing={1} className={classes.format}>
        <Grid item xs={6} sx={{ height: "100%" }}>
          <Box sx={{ pb: "1vh", height: "57%" }}>
            <Paper
              elevation={1}
              sx={{ height: "100%", width: "100%", overflow: "auto" }}
            >
              <Typography
                variant="body1"
                component="div"
                className={classes.title}
              >
                {t("dataInput.companyTitle")}
              </Typography>
              <Box className={classes.dataInputField}>
                <DataInputField datas={props.dispData.companyFile} />
              </Box>
            </Paper>
          </Box>
          {isPrimaryUser() && (
            <Box sx={{ height: "43%" }}>
              <Paper
                elevation={1}
                sx={{ height: "100%", width: "100%", overflow: "auto" }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.title}
                >
                  {t("dataInput.multiCompanyTitle")}
                </Typography>
                <Box className={classes.dataInputField}>
                  <DataInputField
                    datas={props.dispData.companyFileLocations}
                    isMultiContract
                  />
                </Box>
              </Paper>
            </Box>
          )}
        </Grid>
        <Grid item xs={6} sx={{ height: "100%" }}>
          {userContext?.productSpecificFlag && (
            <Box sx={{ pb: "1vh", height: "70%" }}>
              <Paper
                elevation={1}
                sx={{ height: "100%", width: "100%", overflow: "auto" }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.title}
                >
                  {t("dataInput.productTitle")}
                </Typography>
                <Box className={classes.dataInputField}>
                  <DataInputField
                    datas={dispJaItem(props.dispData.productFile)}
                  />
                </Box>
              </Paper>
            </Box>
          )}
          {userContext?.energySavingFlag && (
            <Box sx={{ height: "30%" }}>
              <Paper
                elevation={1}
                sx={{ height: "100%", width: "100%", overflow: "auto" }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.title}
                >
                  {t("dataInput.energySavingTitle")}
                </Typography>
                <Box className={classes.dataInputField}>
                  <DataInputField
                    datas={dispJaItem(props.dispData.energySavingFile)}
                  />
                </Box>
              </Paper>
            </Box>
          )}
        </Grid>
      </Grid>

      <Paper elevation={1} className={classes.example}>
        <div className={classes.exampleBox}>
          <Box>
            <DownloadButton
              label="download"
              iconClassName={classes.icon}
              className={classes.iconButton}
              value={""}
              handleClick={() => {}}
            />
            {t("dataInput.commentDownload")}
          </Box>
          <Box>
            <UploadButton
              label="upload"
              iconClassName={classes.icon}
              className={classes.iconButton}
              value={""}
              handleClick={() => {}}
            />
            {t("dataInput.commentUpload")}
          </Box>
          <Box>
            <DeleteButton
              label="delete"
              iconClassName={classes.icon}
              className={classes.iconButton}
              value={""}
              handleClick={() => {}}
            />
            {t("dataInput.commentDelete")}
          </Box>
        </div>
      </Paper>
    </>
  );
};

export default DataInputForm;
