import { useQuery } from "react-query";
import axios from "axios";
import PasswordChangeRequest from "../../types/request/PasswordChangeRequest";

const PasswordChangeApi = {
  /**
   * パスワード変更API
   * @param request
   */
  PasswordChange: (request: PasswordChangeRequest, onSuccess, onError) => {
    return useQuery(
      "passwordChange",
      async () => {
        const response = await axios.put(
          process.env.REACT_APP_HOST_URL + "/v1/auth/password_change",
          request,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default PasswordChangeApi;
