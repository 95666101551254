import { Button } from "@mui/material";
import React from "react";
import classes from "./BaseButton.module.scss";

type BaseButtonProps = {
  label: string;
  className?: string;
  disabled?: boolean;
  handleClick: () => void;
};

const BaseButton: React.FC<BaseButtonProps> = (props) => {
  return (
    <Button
      variant="contained"
      style={{ textTransform: "none" }}
      className={`${classes.base_button} ${props.className}`}
      disabled={props.disabled}
      onClick={props.handleClick}
    >
      {props.label}
    </Button>
  );
};

export default BaseButton;
