import React, { useContext, useEffect, useState } from "react";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import classes from "./PCFSingleDisplayForm.module.scss";
import { Box, Grid } from "@mui/material";
import SingleDisplay from "../../parts/Item/SingleDisplay.tsx";
import DatamartStatistics from "../../../types/response/DatamartStatistics.tsx";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import MasterContext from "../../../contexts/MasterContext.tsx";
import {
  defaultMasterDatas,
  MasterDatas,
} from "../../../types/response/MasterDataResponse.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import PDSTableDialog from "../../parts/Dialog/PDSTableDialog.tsx";
import DatamartPdsTable from "../../../types/response/DatamartPdsTable.tsx";
import { useTranslation } from "react-i18next";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";

type PCFSingleDisplayFormProps = {
  data: DatamartStatistics[];
  pdsData: DatamartPdsTable[];
  emissionValue: string;
};

const PCFSingleDisplayForm: React.FC<PCFSingleDisplayFormProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;
  const { checkBoxData } = useContext(DatamartSearchContext);
  const { createSelectMinorCategories, setSearchDataBySelect, setSelectItem } =
    SearchFeatures();
  const selectItems = createSelectMinorCategories(masterData, checkBoxData);
  const [selectMinor, setSelectMinor] = useState("all");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectClick = (event) => {
    const name = event.target.value;
    setSelectMinor(name);
    setSearchDataBySelect(name, "minor_categories", masterData);
  };

  const getData = (type) => {
    if (!props.data) {
      return {
        type: "",
        unit: "",
        value: 0,
      };
    }
    return (
      props.data.find((data) => {
        return data.type === type;
      }) || {
        type: "",
        unit: "",
        value: 0,
      }
    );
  };

  useEffect(() => {
    setSelectMinor(setSelectItem("minor_categories", selectItems));
  }, [checkBoxData]);

  return (
    <div className={classes.singleDisplayContent}>
      <Box className={classes.subCategory}>
        <SelectItem
          title={t("facility.minorSelectTitle")}
          handleClick={handleSelectClick}
          selectValue={selectMinor}
          values={selectItems}
          className={classes.selectItem}
          classNameSelect={classes.selectBox}
        />
      </Box>
      <Box className={classes.singleDisplay}>
        <SingleDisplay
          data={getData(
            props.emissionValue === "pcf" ? "pcf" : "co2_emissions"
          )}
          type="equipment"
        />
      </Box>
      <Box className={classes.harfSingleBox}>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay
              data={getData(
                props.emissionValue === "pcf"
                  ? "pcf_pysm"
                  : "co2_emissions_pysm"
              )}
              type="equipment"
            />
          </Grid>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay
              data={getData(
                props.emissionValue === "pcf"
                  ? "pcf_pysm_changes_rate"
                  : "co2_emissions_pysm_changes_rate"
              )}
              type="equipment"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.harfSingleBox}>
        <Grid container spacing={1}>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay
              data={getData(
                props.emissionValue === "pcf"
                  ? "pcf_base"
                  : "co2_emissions_base"
              )}
              type="equipment"
            />
          </Grid>
          <Grid item xs={6} className={classes.harfSingleDisplay}>
            <SingleDisplay
              data={getData(
                props.emissionValue === "pcf"
                  ? "pcf_base_changes_rate"
                  : "co2_emissions_base_changes_rate"
              )}
              type="equipment"
            />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.pdsButton}>
        <BaseButton label="PDS" handleClick={handleClickOpen} />
      </Box>
      <Box className={classes.singleDisplay}>
        <SingleDisplay data={getData("offset_rate")} type="equipment" />
      </Box>
      <Box className={classes.singleDisplay}>
        <SingleDisplay
          data={getData("recycle_material_use_rate")}
          type="equipment"
        />
      </Box>
      <PDSTableDialog
        pdsData={props.pdsData}
        handleClose={handleClose}
        open={open}
      />
    </div>
  );
};

export default PCFSingleDisplayForm;
