import React, { useState } from "react";
import { ReactComponent as IconImage } from "../../../assets/images/Header/icon_filter.svg";
import { IconButton, Typography } from "@mui/material";
import classes from "./SearchField.module.scss";
import { useTranslation } from "react-i18next";

type SearchFieldProps = {
  handleClick: React.MouseEventHandler;
  isSearchOpen: boolean;
};

const SearchField: React.FC<SearchFieldProps> = (props) => {
  const { t } = useTranslation();
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const handleOpenSearch = (event) => {
    props.handleClick(event);
  };

  return (
    <>
      <IconButton
        id="searchingButton"
        className={classes.iconButton}
        onClick={handleOpenSearch}
      >
        <IconImage className={classes.iconImage} />
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1 }}
          className={classes.iconTitle}
        >
          {!isSearch && <>{t("header.search.notSearching")}</>}
          {isSearch && <>{t("header.search.searching")}</>}
        </Typography>
      </IconButton>
    </>
  );
};

export default SearchField;
