import { useContext } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";

export const ApiCommon = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);

  /**
   * API共通エラー処理
   */
  const onCommonError = (error, message?) => {
    const response = error.response;
    const errorMessage = message ? message : Message.ERROR_SYSTEM_ERROR;
    if (response && response.data && response.data.detail) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(response.data.detail);
      return;
    }
    appContext?.setErrorOpen(true);
    appContext?.setAlertMessage(errorMessage);
    return;
  };

  return { onCommonError };
};
