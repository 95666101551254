import React, { useContext } from "react";
import classes from "./FullScreenLayout.module.scss";
import { FullScreen } from "react-full-screen";
import AppContext from "../../../contexts/AppContext.tsx";
import { useTranslation } from "react-i18next";
import CloseButton from "../../parts/Button/CloseButton.tsx";

type FullScreenLayoutProps = {
  children: React.ReactNode;
};

const FullScreenLayout: React.FC<FullScreenLayoutProps> = ({ children }) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <>
      {appContext && (
        <FullScreen
          handle={appContext?.fullScreenHandle}
          className={
            appContext?.fullScreenHandle.active
              ? classes.activeFullscreenPanel
              : classes.fullscreenPanel
          }
        >
          {children}
          {appContext?.fullScreenHandle.active && (
            <CloseButton
              label={t("common.closeButton")}
              handleClick={appContext?.fullScreenHandle.exit}
              className={classes.closeButton}
              iconClassName={classes.iconCloseButton}
            />
          )}
          {/* {!appContext && { children }} */}
        </FullScreen>
      )}
    </>
  );
};

export default FullScreenLayout;
