import React from "react";
import { ReactComponent as IconImage } from "../../../assets/images/Header/icon_help.svg";
import { IconButton, Typography } from "@mui/material";
import classes from "./HelpField.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const HelpField = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleClick = (event) => {
    return navigate("/help");
  };
  return (
    <>
      <IconButton className={classes.iconButton} onClick={handleClick}>
        <IconImage className={classes.iconImage} />
        <Typography
          variant="body1"
          component="div"
          sx={{ flexGrow: 1 }}
          className={classes.iconTitle}
        >
          {t("header.help")}
        </Typography>
      </IconButton>
    </>
  );
};

export default HelpField;
