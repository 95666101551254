import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import classes from "./FileDownloadDialog.module.scss";
import DatePicker from "react-datepicker";
import SelectItem from "../Select/SelectItem.tsx";
import { useTranslation } from "react-i18next";
import DataInputFeatures from "../../../features/DataInputFeatures.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";

type FileDownloadDialogProps = {
  className?: string;
  open: boolean;
  isMultiContract?: boolean;
  isCutomerList: boolean;
  type: string;
  handleClose: () => void;
  handleDownload: (oragnizationId: string, date?: string) => void;
};

const FileDownloadDialog: React.FC<FileDownloadDialogProps> = (props) => {
  const { t } = useTranslation();
  const { getCutomerList, isDispDate, getOrganizationId } = DataInputFeatures();
  const [oragnizationId, setOrganizationId] = useState(getOrganizationId());
  const [date, setDate] = useState<Date>(new Date());
  const handleDateChange = (date: Date | null) => {
    if (date) {
      setDate(date);
    }
  };

  // 顧客選択イベント
  const handleSelectClick = (event) => {
    setOrganizationId(event.target.value);
  };

  const handleDownload = () => {
    if (isDispDate(props.type)) {
      props.handleDownload(
        oragnizationId,
        CommonUtils.formatDateYYYYMMDD(date)
      );
    } else {
      props.handleDownload(oragnizationId, "");
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle className={classes.title}>
        {t("dialog.fileDownload.title")}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {isDispDate(props.type) && (
          <Box sx={{ mb: 2 }} className={classes.dateDiv}>
            <Typography
              sx={{
                float: "left",
                mr: 1,
                ml: 1,
              }}
              className={classes.dateTitle}
            >
              {t("dialog.fileDownload.date")}
            </Typography>
            <DatePicker
              selected={date}
              dateFormat="yyyy/MM"
              onChange={handleDateChange}
              showMonthYearPicker
              renderMonthContent={(month, shortMonth, longMonth) => {
                return <span>{(month + 1).toString() + "月"}</span>;
              }}
              minDate={new Date(2010, 0)}
              maxDate={new Date(2030, 11)}
              className={classes.calender}
            />
          </Box>
        )}
        {props.isCutomerList && (
          <Box>
            <SelectItem
              title={t("dialog.fileDownload.cutomer")}
              selectValue={oragnizationId}
              className={classes.title}
              values={getCutomerList(props.isMultiContract || false)}
              handleClick={handleSelectClick}
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("dialog.fileDownload.cancel")}
        </Button>
        <Button
          className={classes.button}
          onClick={handleDownload}
          color="primary"
        >
          {t("dialog.fileDownload.download")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileDownloadDialog;
