import RequestCode from "./RequestCode";
import RequestID from "./RequestID";

export const defaultSummaryRequest = {
  file_type: "",
  filter_params: {
    from_base_year_month: "",
    to_base_year_month: "",
    from_target_year_month: "",
    to_target_year_month: "",
    major_categories: [],
    middle_categories: [],
    minor_categories: [],
    parts_or_energies: [],
    products: [],
  },
};

// サマリ帳票出力APIリクエスト
interface FileSummaryRequest {
  // ファイルタイプ
  file_type: string;
  // 絞り込み条件
  filter_params: {
    // 基準日開始日
    from_base_year_month: string;
    // 基準日終了日
    to_base_year_month: string;
    // 対象日開始日
    from_target_year_month: string;
    // 対象日終了日
    to_target_year_month: string;
    // 大分類
    major_categories: RequestCode[];
    // 中分類
    middle_categories: RequestCode[];
    // 小分類
    minor_categories: RequestCode[];
    // 部品/エネルギー
    parts_or_energies: RequestID[];
    // 製品
    products: RequestID[];
  };
}

export default FileSummaryRequest;
