import React, { createContext, useState } from "react";
import {
  DatamartSummary,
  defaultDatamartSummary,
} from "../types/response/DatamartSummaryResponse.tsx";
import { TargetInfo } from "../types/response/TargetResponse.tsx";

type DatamartSummaryProps = {
  saveSummaryData: DatamartSummary;
  setSaveSummaryData: React.Dispatch<React.SetStateAction<DatamartSummary>>;
  selectCells: string[];
  setSelectCells: React.Dispatch<React.SetStateAction<string[]>>;
  targets: TargetInfo;
  setTargets: React.Dispatch<React.SetStateAction<TargetInfo>>;
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  heatMapTab: number;
  setHeatMapTab: React.Dispatch<React.SetStateAction<number>>;
};

// サマリデータマート画面用コンテキスト
const DatamartSummaryContext = createContext<DatamartSummaryProps>({
  saveSummaryData: defaultDatamartSummary,
  setSaveSummaryData: () => {},
  selectCells: [],
  setSelectCells: () => {},
  targets: {
    product_target_value: 0,
    equipment_target_value: 0,
  } as TargetInfo,
  setTargets: () => {},
  tab: "1",
  setTab: () => {},
  heatMapTab: 0,
  setHeatMapTab: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const DatamartSummaryProvider: React.VFC<Props> = ({ children }) => {
  const [saveSummaryData, setSaveSummaryData] = useState<DatamartSummary>(
    defaultDatamartSummary
  );
  const [selectCells, setSelectCells] = useState<string[]>([]);
  const [targets, setTargets] = useState<TargetInfo>({
    product_target_value: 0,
    equipment_target_value: 0,
  } as TargetInfo);
  const [tab, setTab] = useState<string>("1");
  const [heatMapTab, setHeatMapTab] = useState<number>(0);

  return (
    <DatamartSummaryContext.Provider
      value={{
        saveSummaryData,
        setSaveSummaryData,
        selectCells,
        setSelectCells,
        targets,
        setTargets,
        tab,
        setTab,
        heatMapTab,
        setHeatMapTab,
      }}
    >
      {children}
    </DatamartSummaryContext.Provider>
  );
};

export default DatamartSummaryContext;
