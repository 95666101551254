import React from "react";
import { Box } from "@mui/material";
import NoticeCreateForm from "../../forms/NoticeCreateForm/NoticeCreateForm.tsx";

type NoticeCreateLayoutProps = {};

const NoticeCreateLayout: React.FC<NoticeCreateLayoutProps> = (props) => {
  return (
    <Box>
      <NoticeCreateForm />
    </Box>
  );
};

export default NoticeCreateLayout;
