import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import DatamartApi from "../features/api/DatamartApi.tsx";
import { EquipmentDashboardType } from "../types/enum/DashboardTypeEnum.tsx";
import DatamartSearchContext from "../contexts/DatamartSearchContext.tsx";
import {
  DatamartEquipment,
  defaultDatamartEquipment,
} from "../types/response/DatamartEquipmentResponse.tsx";
import DatamartEquipmentRequest from "../types/request/DatamartEquipmentRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import DatamartFacilityContext from "../contexts/DatamartFacilityContext.tsx";

export const useDatamartEquipment = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const searchContext = useContext(DatamartSearchContext);
  const { onCommonError } = ApiCommon();
  const [isLoading, setLoading] = useState(false);
  const [datamartData, setDatamartData] = useState<DatamartEquipment>(
    defaultDatamartEquipment
  );
  const { setSaveEquipmentData, selectCells } = useContext(
    DatamartFacilityContext
  );

  /**
   * 設備データマート取得API成功時
   */
  const onSuccess = (data: DatamartEquipment) => {
    setLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    } else if (!data.statistics.length) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_SEARCH_DATA);
      return;
    }
    if (!selectCells.length) {
      setSaveEquipmentData(data);
    }
    setDatamartData(data);
  };

  /**
   * 設備データマート取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setLoading(false);
  };

  /**
   * リクエストパラメータ生成処理
   * @returns
   */
  const createRequestParam = () => {
    let param = {} as DatamartEquipmentRequest;
    param.dashboard_types = EquipmentDashboardType;
    if (searchContext?.searchData) {
      param.filter_params = searchContext.searchData.filter_params;
      param.filter_params.self_manufacturing = param.filter_params
        .self_manufacturing
        ? param.filter_params.self_manufacturing
        : [];
      param.gco2e_unit_type = searchContext?.searchData.gco2e_unit_type;
      param.period_type = searchContext?.searchData.period_type;
    }
    return param;
  };

  /**
   * 設備データマート取得
   */
  const getDatamartEquipmentApi = () => {
    setLoading(true);
    exeDatamartEquipment.refetch();
  };

  // 設備データマート取得API実行
  const exeDatamartEquipment = DatamartApi.DatamartEquipment(
    createRequestParam(),
    onSuccess,
    onError
  );

  return { getDatamartEquipmentApi, datamartData, isLoading };
};
