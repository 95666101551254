import React from "react";
import { Box } from "@mui/material";
import classes from "./PCFLineGraphForm.module.scss";
import PCFLineGraphField from "../../fields/Summary/PCFLineGraphField.tsx";
import GoalValueField from "../../fields/Summary/GoalValueField.tsx";
import { DatamartSummary } from "../../../types/response/DatamartSummaryResponse.tsx";

type PCFLineGraphFormProps = {
  data: DatamartSummary;
};

const PCFLineGraphForm: React.FC<PCFLineGraphFormProps> = (props) => {
  return (
    <>
      <Box className={classes.targetValue}>
        <GoalValueField />
      </Box>
      <Box className={classes.lineGraph}>
        <PCFLineGraphField data={props.data} />
      </Box>
    </>
  );
};

export default PCFLineGraphForm;
