import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import DatamartApi from "../features/api/DatamartApi.tsx";
import { ProductDashboardType } from "../types/enum/DashboardTypeEnum.tsx";
import DatamartSearchContext from "../contexts/DatamartSearchContext.tsx";
import {
  DatamartProduct,
  defaultDatamartProduct,
} from "../types/response/DatamartProductResponse.tsx";
import DatamartProductRequest from "../types/request/DatamartProductRequest.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";
import DatamartProductContext from "../contexts/DatamartProductContext.tsx";
import SearchFeatures from "../features/SearchFeatures.tsx";
import RequestCode from "../types/request/RequestCode.tsx";

export const useDatamartProduct = () => {
  const Message = MessageType();
  const appContext = useContext(AppContext);
  const searchContext = useContext(DatamartSearchContext);
  const { setSaveProductData, setBesideBarSearch } = useContext(
    DatamartProductContext
  );
  const { onCommonError } = ApiCommon();
  const { selectCellByBesideBar } = SearchFeatures();
  const [isLoading, setLoading] = useState(false);
  const [datamartData, setDatamartData] = useState<DatamartProduct>(
    defaultDatamartProduct
  );

  /**
   * 製品軸データマート取得API成功時
   */
  const onSuccess = (data: DatamartProduct) => {
    setLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    } else if (!data.statistics.length) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_SEARCH_DATA);
      return;
    }
    setDatamartData(data);
    setSaveProductData(data);
  };

  /**
   * 製品軸データマート取得API成功時
   */
  const onSuccessMutation = (data: DatamartProduct) => {
    setLoading(false);
    if (!data) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    } else if (!data.statistics.length) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_SEARCH_DATA);
      return;
    }
    setDatamartData(data);
  };

  /**
   * 製品軸データマート取得API失敗時
   */
  const onError = (error) => {
    onCommonError(error);
    setLoading(false);
  };

  /**
   * リクエストパラメータ生成処理
   * @returns
   */
  const createRequestParam = () => {
    let param = {} as DatamartProductRequest;
    param.dashboard_types = ProductDashboardType;
    if (searchContext?.searchData) {
      param.filter_params = searchContext.searchData.filter_params;
      param.filter_params.self_manufacturing = param.filter_params
        .self_manufacturing
        ? param.filter_params.self_manufacturing
        : [];
      param.gco2e_unit_type = searchContext?.searchData.gco2e_unit_type;
      param.period_type = searchContext?.searchData.period_type;
    }
    return param;
  };

  /**
   * 製品軸データマート取得
   */
  const getDatamartProductApi = () => {
    setLoading(true);
    exeDatamartProduct.refetch();
  };

  const getDatamartProductMutationApi = (
    selectCells: { [key: string]: RequestCode[] },
    datamartData: DatamartProduct,
    targetLoading: boolean
  ) => {
    if (targetLoading) return;
    setLoading(true);
    const request = selectCellByBesideBar(selectCells);
    setBesideBarSearch(false);
    exeDatamartProductMutation.mutate(request);
  };

  // 製品軸データマート取得API実行
  const exeDatamartProduct = DatamartApi.DatamartProduct(
    createRequestParam(),
    onSuccess,
    onError
  );

  const exeDatamartProductMutation = DatamartApi.DatamartProductMutation(
    onSuccessMutation,
    onError
  );

  return {
    getDatamartProductApi,
    getDatamartProductMutationApi,
    datamartData,
    isLoading,
  };
};
