import { Paper, Typography } from "@mui/material";
import React from "react";
import classes from "./PieGraphField.module.scss";
import PieGraph from "../../parts/Chart/PieGraph.tsx";
import DatamartEmissionsData from "../../../types/response/DatamartEmissionsData.tsx";

type PieGraphFieldProps = {
  title: string;
  type: string;
  data: DatamartEmissionsData[];
};

const PieGraphField: React.FC<PieGraphFieldProps> = (props) => {
  return (
    <Paper elevation={1} className={classes.pieGraph}>
      <Typography
        sx={{
          fontWeight: "bold",
          textAlign: "left",
        }}
        color="text.secondary"
        gutterBottom
        className={classes.title}
      >
        {props.title}
      </Typography>
      <PieGraph title={props.title} type={props.type} data={props.data} />
    </Paper>
  );
};

export default PieGraphField;
