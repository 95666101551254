import { useQuery } from "react-query";
import axios from "axios";

const RefreshApi = {
  /**
   * リフレッシュAPI
   * @param request
   */
  RefreshToken: (onSuccess, onError) => {
    return useQuery(
      "refreshToken",
      async () => {
        const response = await axios.post(
          process.env.REACT_APP_HOST_URL + "/v1/auth/refresh",
          undefined,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
            withCredentials: true,
          }
        );
        return response.data;
      },
      {
        enabled: false,
        onSuccess: onSuccess,
        onError: onError,
        retry: 0,
      }
    );
  },
};

export default RefreshApi;
