import React, { useContext } from "react";
import classes from "./HelpLinkForm.module.scss";
import { ReactComponent as ManualImage } from "../../../assets/images/Help/icon_inquiry.svg";
import { ReactComponent as InquiryImage } from "../../../assets/images/Help/icon_help.svg";
import { ReactComponent as DownloadImage } from "../../../assets/images/Help/icon_download.svg";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { useFile } from "../../../hooks/useFile.tsx";
import { FileTypeEnum } from "../../../types/enum/FileTypeEnum.tsx";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../contexts/AppContext.tsx";
import MessageType from "../../../types/Message.tsx";
import DataInputFeatures from "../../../features/DataInputFeatures.tsx";

type HelpLinkFormProps = {};

const HelpLinkForm: React.FC<HelpLinkFormProps> = (props) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const Message = MessageType();
  const navigate = useNavigate();
  const { downloadFiles, isLoading } = useFile();
  const { getOrganizationId } = DataInputFeatures();

  const handleManualClick = (type: string) => {
    if (isLoading) {
      return;
    }
    const oragnizationId = getOrganizationId();
    if (!oragnizationId) {
      appContext?.setErrorOpen(true);
      appContext?.setAlertMessage(Message.ERROR_NO_ORGANIZATION_DATA);
      return;
    }
    downloadFiles(
      type === "organization"
        ? FileTypeEnum.manual_organization
        : FileTypeEnum.manual_product,
      oragnizationId
    );
  };

  const handleInquiryClick = () => {
    return navigate("/inquiry");
  };
  return (
    <Box className={classes.helpLinkBox}>
      <IconButton
        onClick={() => handleManualClick("organization")}
        className={classes.manualButton}
        sx={{ float: "left", mr: 2 }}
      >
        <Box className={classes.title}>
          <ManualImage className={classes.iconImage} />
          <Typography
            variant="body2"
            component="div"
            sx={{ flexGrow: 1, ml: 1 }}
            className={classes.iconTitle}
          >
            {t("help.manualOrganization")}
          </Typography>
        </Box>
        <Box className={classes.download}>
          <DownloadImage className={classes.iconImage} />
        </Box>
      </IconButton>
      <IconButton
        onClick={() => handleManualClick("product")}
        className={classes.manualButton}
        sx={{ float: "left", mr: 2 }}
      >
        <Box className={classes.title}>
          <ManualImage className={classes.iconImage} />
          <Typography
            variant="body2"
            component="div"
            sx={{ flexGrow: 1, ml: 1 }}
            className={classes.iconTitle}
          >
            {t("help.manualProduct")}
          </Typography>
        </Box>
        <Box className={classes.download}>
          <DownloadImage className={classes.iconImage} />
        </Box>
      </IconButton>
      <IconButton
        onClick={handleInquiryClick}
        className={classes.inquiryButton}
        sx={{ float: "left", mr: 2 }}
      >
        <InquiryImage className={classes.iconImage} />
        <Typography
          variant="body2"
          component="div"
          sx={{ flexGrow: 1, ml: 1 }}
          className={classes.iconTitle}
        >
          {t("help.inquiry")}
        </Typography>
      </IconButton>
    </Box>
    // <Grid container spacing={2}>
    //   <Grid item xs={4} className={classes.linkItem}>
    //   </Grid>
    //   <Grid item xs={4} className={classes.linkItem}>
    //   </Grid>
    //   <Grid item xs={4} className={classes.linkItem}>
    //   </Grid>
    // </Grid>
  );
};

export default HelpLinkForm;
