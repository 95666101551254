import React, { useContext, useEffect } from "react";
import LoginLayout from "../../layouts/Login/LoginLayout.tsx";
import { useLocation } from "react-router-dom";
import { useAlert } from "../../../hooks/useAlert.tsx";
import AppContext from "../../../contexts/AppContext.tsx";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const appContext = useContext(AppContext);
  const { i18n } = useTranslation();
  const { getStatusErrorMessage } = useAlert();
  const search = useLocation().search;

  useEffect(() => {
    // URLパラメータ確認
    const query = new URLSearchParams(search);
    const status = query.get("status");
    if (status && appContext) {
      appContext.setErrorOpen(true);
      appContext.setAlertMessage(getStatusErrorMessage(status));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // ブラウザ上の設定に言語選択がある場合、そちらをログイン画面の標準設定とする
    const language = localStorage.getItem("language");
    if (language) {
      i18n.changeLanguage(language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoginLayout />;
};

export default LoginPage;
