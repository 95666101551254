import React, { createContext, useRef, useState } from "react";
import AppTypes from "../types/AppTypes.tsx";
import SuccessAlert from "../components/parts/Alert/SuccessAlert.tsx";
import ErrorAlert from "../components/parts/Alert/ErrorAlert.tsx";
import WarningAlert from "../components/parts/Alert/WarningAlert.tsx";
import { JwtPayload } from "jwt-decode";
import { useFullScreenHandle } from "react-full-screen";

// システム共通コンテキスト
const AppContext = createContext<AppTypes | null>(null);

type Props = {
  children: React.ReactNode;
};

export const AppProvider: React.VFC<Props> = ({ children }) => {
  const [accessToken, setAccessToken] = useState<JwtPayload>({} as JwtPayload);
  const [idToken, setIdToken] = useState<JwtPayload>({} as JwtPayload);
  const [sideOpen, setSideOpen] = useState<boolean>(true);
  const [sideItem, setSideItem] = useState<string>("home");
  const [successOpen, setSuccess] = useState<boolean>(false);
  const [errorOpen, setError] = useState<boolean>(false);
  const [warningOpen, setWarning] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const pdfDownloadRef = useRef();
  const fullScreenHandle = useFullScreenHandle();

  /**
   * 成功アラート表示処理
   * @param isOpen
   */
  const setSuccessOpen = (isOpen: boolean) => {
    setSuccess(isOpen);
    setError(false);
    setWarning(false);
  };

  /**
   * 失敗アラート表示処理
   * @param isOpen
   */
  const setErrorOpen = (isOpen: boolean) => {
    setSuccess(false);
    setError(isOpen);
    setWarning(false);
  };

  /**
   * 警告アラート表示処理
   * @param isOpen
   */
  const setWarningOpen = (isOpen: boolean) => {
    setSuccess(false);
    setError(false);
    setWarning(isOpen);
  };

  return (
    <AppContext.Provider
      value={{
        accessToken,
        setAccessToken,
        idToken,
        setIdToken,
        sideOpen,
        setSideOpen,
        sideItem,
        setSideItem,
        successOpen,
        setSuccessOpen,
        errorOpen,
        setErrorOpen,
        warningOpen,
        setWarningOpen,
        alertMessage,
        setAlertMessage,
        pdfDownloadRef,
        fullScreenHandle,
      }}
    >
      <SuccessAlert />
      <ErrorAlert />
      <WarningAlert />
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
