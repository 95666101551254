import React from "react";
import { Box, TextField } from "@mui/material";

type InputTextOnLeftLabelProps = {
  label: string;
  labelClassName?: string;
  className?: string;
  value?: string | number;
  required?: boolean;
  type?: string;
  inputProps?: {};
  handleInput?: (event) => void;
  handleChange?: (event) => void;
};

const InputTextOnLeftLabel: React.FC<InputTextOnLeftLabelProps> = (props) => {
  return (
    <>
      <Box className={props.labelClassName}>{props.label}</Box>
      <TextField
        size="small"
        className={props.className}
        required={props.required ? true : false}
        defaultValue={
          props.value !== null && props.value !== undefined ? props.value : ""
        }
        type={props.type ? props.type : ""}
        inputProps={props.inputProps ? props.inputProps : {}}
        onInput={props.handleInput ? props.handleInput : undefined}
        onChange={props.handleChange ? props.handleChange : undefined}
      />
    </>
  );
};

export default InputTextOnLeftLabel;
