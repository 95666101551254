import { useContext, useState } from "react";
import LoginContext from "../contexts/LoginContext.tsx";
import CheckValue from "../../common/utils/CheckValue.tsx";
import MessageType from "../types/Message.tsx";
import ResetPasswordApi from "../features/api/ResetPasswordApi.tsx";

export const useResetPassword = () => {
  const Message = MessageType();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const loginContext = useContext(LoginContext);

  /**
   * パスワード再設定依頼メールAPI成功時
   */
  const onResetMailSuccess = (data) => {
    localStorage.setItem("mail", loginContext?.id || "");
    setIsLoading(false);
    setResponse(data);
  };

  /**
   * パスワード再設定依頼メールAPI失敗時
   */
  const onResetMailError = (error) => {
    setIsLoading(false);
    const response = error.response;
    if (response && response.data && response.data.detail) {
      setErrorMessage(response.data.detail);
      return;
    }
    setErrorMessage(Message.ERROR_SYSTEM_ERROR);
  };

  /**
   * パスワード再設定依頼メールAPI処理
   * @returns
   */
  const useResetMailApi = async () => {
    if (!CheckValue.isEmptyValues([loginContext?.id || ""])) {
      setIsLoading(false);
      setErrorMessage(Message.ERROR_NO_INPUT_DATA);
      return;
    }
    setIsLoading(true);
    setErrorMessage("");
    exeResetMail.refetch();
  };

  /**
   * パスワード再設定API成功時
   * アクセストークン、IDトークンをローカルストレージに格納し、TOP画面へ遷移する
   */
  const onResetPasswordSuccess = (data) => {
    setIsLoading(false);
    if (!data) {
      setErrorMessage(Message.ERROR_NO_RESPONSE_DATA);
      return;
    }
    setResponse(data);
  };

  /**
   * パスワード再設定API失敗時
   */
  const onResetPasswordError = (error) => {
    setIsLoading(false);
    const response = error.response;
    if (response && response.data && response.data.detail) {
      setErrorMessage(response.data.detail);
      return;
    }
    setErrorMessage(Message.ERROR_SYSTEM_ERROR);
  };

  /**
   * パスワード再設定API処理
   * @returns
   */
  const useResetPasswordApi = async () => {
    setIsLoading(true);
    setErrorMessage("");
    if (loginContext?.password !== loginContext?.rePassword) {
      setIsLoading(false);
      setErrorMessage(Message.ERROR_NO_MATCH_PASSWORD);
      return;
    }
    exeResetPassword.refetch();
  };

  // パスワード再設定依頼メールAPI実行
  const exeResetMail = ResetPasswordApi.ResetMail(
    loginContext?.id || "",
    onResetMailSuccess,
    onResetMailError
  );

  // パスワード再設定API実行
  const exeResetPassword = ResetPasswordApi.ResetPassword(
    localStorage.getItem("mail") || "",
    loginContext?.resetToken || "",
    loginContext?.password || "",
    onResetPasswordSuccess,
    onResetPasswordError
  );

  return {
    useResetMailApi,
    useResetPasswordApi,
    isLoading,
    response,
    errorMessage,
  };
};
