import React, { useContext } from "react";
import classes from "./PCFHeatMapField.module.scss";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import HeatMapItem from "../../parts/Item/HeatMapItem.tsx";
import DatamartPcfHeatmap from "../../../types/response/DatamartPcfHeatmap.tsx";
import { useTranslation } from "react-i18next";
import DatamartFacilityContext from "../../../contexts/DatamartFacilityContext.tsx";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "1vw" }} className={classes.tabPanelContent}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

type PCFHeatMapFieldProps = {
  data: {
    major_category_data: DatamartPcfHeatmap[];
    middle_category_data: DatamartPcfHeatmap[];
    minor_category_data: DatamartPcfHeatmap[];
    parts_or_energies_data: DatamartPcfHeatmap[];
  };
  emissionValue: string;
};

const PCFHeatMapField: React.FC<PCFHeatMapFieldProps> = (props) => {
  const { t } = useTranslation();
  const { heatMapTab, setHeatMapTab } = useContext(DatamartFacilityContext);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setHeatMapTab(newValue);
  };

  return (
    <Paper elevation={1} className={classes.pcfHeatMap}>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100%",
        }}
      >
        <Tabs
          orientation="vertical"
          value={heatMapTab}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
          className={classes.tabs}
        >
          <Tab
            label={t("facility.heatMap.tabMajor")}
            {...a11yProps(0)}
            className={classes.tabButton}
          />
          <Tab
            label={t("facility.heatMap.tabMiddle")}
            {...a11yProps(1)}
            className={classes.tabButton}
          />
          <Tab
            label={t("facility.heatMap.tabMinor")}
            {...a11yProps(2)}
            className={classes.tabButton}
          />
          <Tab
            label={t("facility.heatMap.tabProductEnergies")}
            {...a11yProps(3)}
            className={classes.tabButton}
          />
        </Tabs>
        <TabPanel value={heatMapTab} index={0}>
          <HeatMapItem
            title={t("facility.heatMap.heatMapMajor")}
            data={props.data ? props.data.major_category_data : []}
            type="facility"
            category="major_categories"
            emissionValue={props.emissionValue}
          />
        </TabPanel>
        <TabPanel value={heatMapTab} index={1}>
          <HeatMapItem
            title={t("facility.heatMap.heatMapMiddle")}
            data={props.data ? props.data.middle_category_data : []}
            type="facility"
            category="middle_categories"
            emissionValue={props.emissionValue}
          />
        </TabPanel>
        <TabPanel value={heatMapTab} index={2}>
          <HeatMapItem
            title={t("facility.heatMap.heatMapMinor")}
            data={props.data ? props.data.minor_category_data : []}
            type="facility"
            category="minor_categories"
            emissionValue={props.emissionValue}
          />
        </TabPanel>
        <TabPanel value={heatMapTab} index={3}>
          <HeatMapItem
            title={t("facility.heatMap.heatMapProductEnergies")}
            data={props.data ? props.data.parts_or_energies_data : []}
            type="facility"
            category="parts_or_energies"
            emissionValue={props.emissionValue}
          />
        </TabPanel>
      </Box>
    </Paper>
  );
};

export default PCFHeatMapField;
