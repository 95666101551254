import { Alert, AlertTitle, Box, Collapse } from "@mui/material";
import React, { useContext } from "react";
import classes from "./ErrorAlert.module.scss";
import AppContext from "../../../contexts/AppContext.tsx";

const ErrorAlert: React.FC = () => {
  const appContext = useContext(AppContext);
  return (
    <Collapse in={appContext?.errorOpen}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            appContext?.setErrorOpen(false);
            appContext?.setAlertMessage("");
          }}
          className={classes.errorAlert}
        >
          <AlertTitle>Error</AlertTitle>
          {appContext?.alertMessage}
        </Alert>
      </Box>
    </Collapse>
  );
};

export default ErrorAlert;
