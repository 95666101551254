import React from "react";
import DataInputLayout from "../../layouts/DataInput/DataInputLayout.tsx";
import { UploadHistoriesProvider } from "../../../contexts/UploadHistoriesContext.tsx";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";

const DataInputPage = () => {
  return (
    <UploadHistoriesProvider>
      <ContentLayout>
        <DataInputLayout />
      </ContentLayout>
    </UploadHistoriesProvider>
  );
};

export default DataInputPage;
