import React, { useContext } from "react";
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import classes from "./UserContractInfoField.module.scss";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import { useTranslation } from "react-i18next";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

const UserContractInfoField = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { getUserName } = UserFuatures();
  return (
    <Paper elevation={1} className={classes.userContract}>
      <Typography variant="body1" component="div" className={classes.title}>
        {`${getUserName()}${t("top.contract.title")}`}
      </Typography>
      <Divider />
      <Box className={classes.userInfo}>
        <TableContainer className={classes.userInfoTable}>
          <Table
            size="small"
            sx={{ minWidth: 750, tableLayout: "fixed", width: "73vw" }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "12%" }}>
                  {t("top.contract.plan")}
                </TableCell>
                <TableCell sx={{ width: "13%" }}>
                  {t("top.contract.cutomer")}
                </TableCell>
                <TableCell sx={{ width: "12%" }}>
                  {t("top.contract.company")}
                </TableCell>
                <TableCell sx={{ width: "12%" }}>
                  {t("top.contract.contractId")}
                </TableCell>
                <TableCell sx={{ width: "12%" }}>
                  {t("top.contract.startDate")}
                </TableCell>
                <TableCell sx={{ width: "12%" }}>
                  {t("top.contract.endDate")}
                </TableCell>
                <TableCell sx={{ width: "13%" }}>
                  {t("top.contract.model")}
                </TableCell>
                <TableCell sx={{ width: "13%" }}>
                  {t("top.contract.contract")}
                </TableCell>
                <TableCell sx={{ width: "12%" }}>
                  {t("top.contract.parentContractId")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userInfo.contracts &&
                userInfo.contracts.map((contract) => {
                  return (
                    <TableRow>
                      <TableCell
                        sx={{ width: "12%" }}
                        className={classes.tableCell}
                      >
                        {contract.plan_name}
                      </TableCell>
                      <TableCell
                        sx={{ width: "13%" }}
                        className={classes.tableCell}
                      >
                        {contract.organization_id}
                      </TableCell>
                      <TableCell
                        sx={{ width: "12%" }}
                        className={classes.tableCell}
                      >
                        {contract.company_name}
                      </TableCell>
                      <TableCell
                        sx={{ width: "12%" }}
                        className={classes.tableCell}
                      >
                        {contract.contract_id}
                      </TableCell>
                      <TableCell
                        sx={{ width: "12%" }}
                        className={classes.tableCell}
                      >
                        {contract.start_date}
                      </TableCell>
                      <TableCell
                        sx={{ width: "12%" }}
                        className={classes.tableCell}
                      >
                        {contract.end_date}
                      </TableCell>
                      <TableCell
                        sx={{ width: "13%" }}
                        className={classes.tableCell}
                      >
                        {contract.model_name}
                      </TableCell>
                      <TableCell
                        sx={{ width: "13%" }}
                        className={classes.tableCell}
                      >
                        {contract.parent_contract_company_name}
                      </TableCell>
                      <TableCell
                        sx={{ width: "12%" }}
                        className={classes.tableCell}
                      >
                        {contract.parent_contract_id}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Paper>
  );
};

export default UserContractInfoField;
