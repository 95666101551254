import { Alert, AlertTitle, Box, Collapse } from "@mui/material";
import React, { useContext } from "react";
import classes from "./WarningAlert.module.scss";
import AppContext from "../../../contexts/AppContext.tsx";

const WarningAlert: React.FC = (props) => {
  const appContext = useContext(AppContext);
  return (
    <Collapse in={appContext?.warningOpen}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Alert
          severity="warning"
          onClose={() => {
            appContext?.setWarningOpen(false);
            appContext?.setAlertMessage("");
          }}
          className={classes.warningAlert}
        >
          <AlertTitle>Warning</AlertTitle>
          {appContext?.alertMessage}
        </Alert>
      </Box>
    </Collapse>
  );
};

export default WarningAlert;
