import React, { useContext, useState } from "react";
import { Box, Grid } from "@mui/material";
import classes from "./SearchSummaryField.module.scss";
import SearchDateField from "./SearchDateField.tsx";
import SearchCheckBoxField from "./SearchCheckBoxField.tsx";
import SearchButtonField from "./SearchButtonField.tsx";
import MasterContext from "../../../contexts/MasterContext.tsx";
import {
  defaultMasterDatas,
  MasterDatas,
} from "../../../types/response/MasterDataResponse.tsx";
import SelectItem from "../../parts/Select/SelectItem.tsx";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import { useTranslation } from "react-i18next";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import DatamartSummaryContext from "../../../contexts/DatamartSummaryContext.tsx";

type SearchSummaryFieldProps = {};

const SearchSummaryField: React.FC<SearchSummaryFieldProps> = (props) => {
  const { t } = useTranslation();
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;

  const { checkBoxData, searchData } = useContext(DatamartSearchContext);
  const { setSelectCells } = useContext(DatamartSummaryContext);
  const {
    createSelectProduct,
    setSearchDataBySelect,
    getCheckBoxInMiddleCategory,
    getCheckBoxInMinorCategory,
    getCheckBoxInPartsOrEnegies,
    setSelectItem,
  } = SearchFeatures();
  const [selectProduct, setSelectProduct] = useState(setSelectItem("products"));

  const handleSelectClick = (event) => {
    const name = event.target.value;
    setSelectProduct(name);
    setSearchDataBySelect(name, "products", masterData, true);
  };

  const resetData = () => {
    setSelectCells([]);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Box className={classes.searchItem}>
          <SearchDateField
            title={t("header.search.summary.targetYear")}
            fromDate={searchData.filter_params.from_target_year_month}
            toDate={searchData.filter_params.to_target_year_month}
          />
          <SearchDateField
            title={t("header.search.summary.baseYear")}
            fromDate={searchData.filter_params.from_base_year_month}
            toDate={searchData.filter_params.to_base_year_month}
            isBase={true}
          />
        </Box>
        <Box className={classes.searchItem}>
          <SelectItem
            title={t("header.search.summary.selectProduct")}
            handleClick={handleSelectClick}
            selectValue={selectProduct}
            values={createSelectProduct(masterData)}
            className={classes.selectItem}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={checkBoxData.major_categories}
            type="major_categories"
            title={t("header.search.summary.checkBoxMajor")}
          />
        </Box>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInMiddleCategory(checkBoxData)}
            type="middle_categories"
            title={t("header.search.summary.checkBoxMiddle")}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInMinorCategory(checkBoxData)}
            type="minor_categories"
            title={t("header.search.summary.checkBoxMinor")}
          />
        </Box>
        <Box className={classes.searchItem}>
          <SearchCheckBoxField
            checkBoxData={getCheckBoxInPartsOrEnegies(checkBoxData)}
            type="parts_or_energies"
            title={t("header.search.summary.checkBoxProductEnergies")}
          />
        </Box>
      </Grid>
      <Grid item xs={2} className={classes.searchButtonItem}>
        <SearchButtonField resetData={resetData} />
      </Grid>
    </Grid>
  );
};

export default SearchSummaryField;
