import { useContext } from "react";
import DatamartPcfChart from "../types/response/DatamartPcfChart.tsx";
import TabsContext from "../contexts/TabsContext.tsx";
import DatamartCompanyContext from "../contexts/DatamartCompanyContext.tsx";
import DatamartEmissionsData from "../types/response/DatamartEmissionsData.tsx";
import CommonUtils from "../common/utils/CommonUtils.tsx";

// ログイン処理後の共通処理
const DatamartFeatures = () => {
  const { tab } = useContext(TabsContext);
  const { selectPieGraph, savePieGraph } = useContext(DatamartCompanyContext);

  const getChartData = (data, type: string) => {
    const tabs = [
      "major_category_data",
      "middle_category_data",
      "minor_category_data",
      "parts_or_energies_data",
    ];
    const chart = data[tabs[tab]];
    if (chart) {
      return chart[type];
    }
    return [];
  };

  /**
   * グラフ用データ作成
   * graphData:[
   *  {
   *    "date": 日付
   *    title: 値
   *    ※titleは各データタイトル
   *  }
   * ]
   * 左グラフ用データキーリスト
   * leftKeys:[title]
   * 右グラフ用データキーリスト
   * rightKeys:[title]
   * @param charts
   * @param graphs
   * @returns
   */
  const createChartData = (
    charts: DatamartPcfChart[],
    graphs: DatamartPcfChart[],
    rightLabel: string
  ) => {
    const graphData: object = {};
    const rightKeys: Array<string> = [];
    const leftKeys: Array<string> = [];
    let integerPartDigits = 0;
    if (!charts.length && !graphs.length) {
      return {
        graphData: [],
        rightKeys: rightKeys,
        leftKeys: leftKeys,
        integerPartDigits: integerPartDigits,
      };
    }

    charts.forEach((chart) => {
      if (!chart.data.length) return;
      const title = chart.title;
      chart.data.forEach((data) => {
        const date = data.x_axis_key;
        if (!graphData[date]) graphData[date] = { date: date };
        graphData[date][title + "(" + rightLabel + ")"] = data.value;
      });
      rightKeys.push(title + "(" + rightLabel + ")");
    });

    graphs.forEach((graph) => {
      if (!graph.data.length) return;
      const title = graph.title;
      graph.data.forEach((data) => {
        const date = data.x_axis_key;
        if (!graphData[date]) graphData[date] = { date: date };
        graphData[date][title] = data.value;
        const digits = CommonUtils.getIntegerPartDigits(data.value) - 2;
        if (digits > 2 && digits > integerPartDigits)
          integerPartDigits = digits;
      });
      leftKeys.push(title);
    });

    return {
      graphData: Object.values(graphData) as object[],
      rightKeys: rightKeys,
      leftKeys: leftKeys,
      integerPartDigits: integerPartDigits,
    };
  };

  /**
   * セルを選択された状態の場合、保持データを返却
   * @param data
   * @param type
   * @returns
   */
  const getPieChartData = (data: DatamartEmissionsData[], type: string) => {
    if (selectPieGraph.length !== 0) {
      switch (type) {
        case "scopes":
          return savePieGraph.data_by_scope;
        case "energies":
          return savePieGraph.data_by_energy;
        case "organizations":
          return savePieGraph.data_by_organization;
      }
    }
    return data;
  };

  return { getChartData, createChartData, getPieChartData };
};

export default DatamartFeatures;
