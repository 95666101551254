import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import classes from "./FileDeleteDialog.module.scss";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import SelectItem from "../Select/SelectItem.tsx";
import DataInputFeatures from "../../../features/DataInputFeatures.tsx";
import CommonUtils from "../../../common/utils/CommonUtils.tsx";
import MasterContext from "../../../contexts/MasterContext.tsx";
import {
  defaultMasterDatas,
  MasterDatas,
} from "../../../types/response/MasterDataResponse.tsx";

type FileDeleteDialogProps = {
  className?: string;
  open: boolean;
  isMultiContract?: boolean;
  isCutomerList: boolean;
  handleClose: () => void;
  handleDelete: (fromDate, toDate, oragnizationId) => void;
};

const FileDeleteDialog: React.FC<FileDeleteDialogProps> = (props) => {
  const { t, i18n } = useTranslation();
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;
  const { getCutomerList, getOrganizationId } = DataInputFeatures();
  const [oragnizationId, setOrganizationId] = useState(getOrganizationId());
  const [fromDate, setFromDate] = useState<Date>(
    CommonUtils.toDateYYYY_MM(masterData.from_initial_year_month)
  );
  const [toDate, setToDate] = useState<Date>(
    CommonUtils.toDateYYYY_MM(masterData.to_initial_year_month)
  );

  // 顧客選択イベント
  const handleSelectClick = (event) => {
    setOrganizationId(event.target.value);
  };

  const handleFromChange = (date: Date | null) => {
    if (date) {
      setFromDate(date);
      if (date.getTime() > toDate.getTime()) {
        setToDate(date);
      }
    }
  };

  const handleToChange = (date: Date | null) => {
    if (date) {
      setToDate(date);
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={props.open}
      onClose={props.handleClose}
    >
      <DialogTitle className={classes.title}>
        {t("dialog.delete.title")}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {props.isCutomerList && (
          <Box sx={{ mb: 2 }}>
            <SelectItem
              title={t("dialog.fileDownload.cutomer")}
              selectValue={oragnizationId}
              values={getCutomerList(props.isMultiContract || false)}
              handleClick={handleSelectClick}
            />
          </Box>
        )}
        <Box className={classes.dateDiv}>
          <Typography
            sx={{
              float: "left",
              mr: 2,
              ml: 1,
            }}
            className={classes.dateTitle}
          >
            {t("dialog.fileDownload.date")}
          </Typography>
          <DatePicker
            selected={fromDate}
            dateFormat="yyyy/MM"
            onChange={handleFromChange}
            showMonthYearPicker
            renderMonthContent={(month, shortMonth, longMonth) => {
              return (
                <span>
                  {i18n.language === "ja"
                    ? (month + 1).toString() + "月"
                    : shortMonth}
                </span>
              );
            }}
            minDate={new Date(2010, 0)}
            maxDate={new Date(2030, 11)}
            className={classes.calender}
          />
          <Box sx={{ pl: 1, pr: 1, fontSize: "8px" }}>{t("common.tofrom")}</Box>
          <DatePicker
            selected={toDate}
            dateFormat="yyyy/MM"
            onChange={handleToChange}
            showMonthYearPicker
            renderMonthContent={(month, shortMonth, longMonth) => {
              return (
                <span>
                  {i18n.language === "ja"
                    ? (month + 1).toString() + "月"
                    : shortMonth}
                </span>
              );
            }}
            minDate={fromDate}
            maxDate={new Date(2030, 11)}
            className={classes.calender}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.button}
          onClick={props.handleClose}
          color="secondary"
        >
          {t("dialog.delete.cancel")}
        </Button>
        <Button
          className={classes.button}
          onClick={() =>
            props.handleDelete(
              CommonUtils.formatDateYYYYMMDD(fromDate),
              CommonUtils.formatDateYYYYMMDD(toDate),
              oragnizationId
            )
          }
          color="primary"
        >
          {t("dialog.delete.delete")}
        </Button>
        <Button
          className={classes.button}
          onClick={() => props.handleDelete("", "", oragnizationId)}
          color="primary"
        >
          {t("dialog.delete.deleteAll")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileDeleteDialog;
