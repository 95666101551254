import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import classes from "./FileUploadField.module.scss";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

type FileUploadFieldProps = {
  file: File | undefined;
  onDrop: (acceptedFiles: File) => void;
};

const FileUploadField: React.FC<FileUploadFieldProps> = (props) => {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const onDrop = acceptedFiles => {
    props.onDrop(acceptedFiles[0]);
    setIsDialogOpen(false);
  }

  const { getRootProps, open: openFileDialog, } = useDropzone({
    onDrop, accept: {
      "text/csv": [".csv", ".CSV"],
    },
    noClick: true,
  })

  const handleOpenDialog = () => {
    if (!isDialogOpen) {
      openFileDialog();
      setIsDialogOpen(true);
    }
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.file_name}>{props.file !== undefined ? props.file.name : t("accountCreate.fileUpload.selectAnnounce")}</Typography>
      <div {...getRootProps({ className: "dropzone" })}>
        <Button variant="contained" onClick={handleOpenDialog}>{t("accountCreate.fileUpload.select")}</Button>
      </div>
    </Box>
  );
};

export default FileUploadField;
