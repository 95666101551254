import React, { useContext } from "react";
import { Grid } from "@mui/material";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import classes from "./SearchButtonField.module.scss";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import MasterContext from "../../../contexts/MasterContext.tsx";
import {
  defaultMasterDatas,
  MasterDatas,
} from "../../../types/response/MasterDataResponse.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";
import { useTranslation } from "react-i18next";

type SearchButtonFieldProps = {
  resetData: () => void;
};

const SearchButtonField: React.FC<SearchButtonFieldProps> = (props) => {
  const { t } = useTranslation();
  const searchContext = useContext(DatamartSearchContext);
  const { setSearchDatabyMasterData } = SearchFeatures();
  const masterContext = useContext(MasterContext);
  const masterData = masterContext?.masterData
    ? (masterContext.masterData as MasterDatas)
    : defaultMasterDatas;

  const exeSearchButton = () => {
    searchContext.setSearch(true);
    searchContext.setSearchOpen(false);
  };

  const onResetButtonClick = () => {
    setSearchDatabyMasterData(masterData);
    searchContext.setResetStatus(!searchContext.resetStatus);
    props.resetData();
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} className={classes.buttonField}>
        <BaseButton
          label={t("header.search.resetButton")}
          handleClick={onResetButtonClick}
          className={classes.resetButton}
        />
      </Grid>
      <Grid item xs={6} className={classes.buttonField}>
        <BaseButton
          label={t("header.search.searchButton")}
          handleClick={exeSearchButton}
          className={classes.searchButton}
        />
      </Grid>
    </Grid>
  );
};

export default SearchButtonField;
