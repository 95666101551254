import React from "react";
import { TextField } from "@mui/material";

type InputTextProps = {
  label: string;
  className?: string;
  value?: string;
  required?: boolean;
  type?: string;
  inputProps?: {};
  handleInput?: (event) => void;
  handleChange?: (event) => void;
};

const InputText: React.FC<InputTextProps> = (props) => {
  return (
    <TextField
      label={props.label}
      className={props.className}
      required={props.required ? true : false}
      defaultValue={props.value ? props.value : ""}
      type={props.type ? props.type : ""}
      inputProps={props.inputProps ? props.inputProps : {}}
      onInput={props.handleInput ? props.handleInput : undefined}
      onChange={props.handleChange ? props.handleChange : undefined}
    />
  );
};

export default InputText;
