import React from "react";
import classes from "./InquiryForm.module.scss";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputTextOnLeftLabel from "../../parts/TextField/InputTextOnLeftLabel.tsx";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import InputMultilineTextOnLeftLabel from "../../parts/TextField/InputMultilineTextOnLeftLabel.tsx";
import { useInquiry } from "../../../hooks/useInquiry.tsx";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";

type InquiryFormProps = {};

const InquiryForm: React.FC<InquiryFormProps> = (props) => {
  const { t } = useTranslation();
  const {
    title,
    setTitle,
    content,
    setContent,
    postInquiryApi,
    inquiryLoading,
  } = useInquiry();

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.currentTarget.value);
  };

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setContent(event.currentTarget.value);
  };

  const handleSubmitClick = () => {
    postInquiryApi();
  };

  return (
    <>
      {!inquiryLoading && (
        <>
          <Typography variant="h5" component="div" className={classes.title}>
            {t("inquiry.title")}
          </Typography>
          <Box className={classes.inputForm}>
            <Typography
              variant="body2"
              component="div"
              className={classes.explanation}
            >
              {t("inquiry.explanation")}
            </Typography>
            <InputTextOnLeftLabel
              label={t("inquiry.titleInput")}
              className={classes.input}
              labelClassName={classes.inputLabel}
              inputProps={{
                maxLength: 100,
              }}
              value={title}
              handleChange={handleTitleChange}
            />
            <InputMultilineTextOnLeftLabel
              label={t("inquiry.titleMultiline")}
              className={classes.multiline}
              labelClassName={classes.multilineLabel}
              placeholder={t("inquiry.placeholderMultiline")}
              inputProps={{
                maxLength: 500,
              }}
              row={10}
              value={content}
              handleChange={handleContentChange}
            />
            <BaseButton
              label={t("inquiry.submitButton")}
              handleClick={handleSubmitClick}
              className={classes.submitButton}
            />
          </Box>
        </>
      )}
      {inquiryLoading && <CircularProgressDialog />}
    </>
  );
};

export default InquiryForm;
