import { Box, Paper, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import classes from "./SpreadSheetField.module.scss";
import SpreadSheet from "../../parts/Item/SpreadSheet.tsx";
import DatamartTableData from "../../../types/response/DatamartTableData.tsx";
import { useTranslation } from "react-i18next";

type SpreadSheetFieldProps = {
  data: {
    data: DatamartTableData[];
  };
};

const SpreadSheetField: React.FC<SpreadSheetFieldProps> = (props) => {
  const { t } = useTranslation();
  const [multiple, setMultiple] = useState(1);

  const handleChangeMultiple = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMultiple(event.target.value ? Number(event.target.value) : 1);
  };

  return (
    <Paper elevation={1} className={classes.spreadSheet}>
      <Box>
        <Typography
          sx={{
            fontWeight: "bold",
            textAlign: "left",
          }}
          color="text.secondary"
          gutterBottom
          className={classes.title}
        >
          {t("company.spreadSheet.title")}
        </Typography>
        <div className={classes.inputText}>
          <TextField
            id="outlined-basic"
            label={t("company.spreadSheet.multipleText")}
            variant="outlined"
            size="small"
            type="number"
            style={{ display: "flex" }}
            className={classes.multipleText}
            value={multiple}
            onChange={handleChangeMultiple}
          />
        </div>
      </Box>
      <Box className={classes.spreadSheetItem}>
        <SpreadSheet
          data={props.data ? props.data.data : []}
          multiple={multiple}
        />
      </Box>
    </Paper>
  );
};

export default SpreadSheetField;
