import React from "react";
import ContentLayout from "../../layouts/Common/ContentLayout.tsx";
import AccountCreateLayout from "../../layouts/AccountCreate/AccountCreateLayout.tsx";

const AccountCreatePage = () => {
  return (
    <ContentLayout>
      <AccountCreateLayout />
    </ContentLayout>
  );
};

export default AccountCreatePage;
