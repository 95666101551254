import React, { createContext, useState } from "react";
import {
  DatamartEquipment,
  defaultDatamartEquipment,
} from "../types/response/DatamartEquipmentResponse.tsx";
import { TargetInfo } from "../types/response/TargetResponse.tsx";

type DatamartFacilityProps = {
  saveEquipmentData: DatamartEquipment;
  setSaveEquipmentData: React.Dispatch<React.SetStateAction<DatamartEquipment>>;
  selectCells: string[];
  setSelectCells: React.Dispatch<React.SetStateAction<string[]>>;
  targets: TargetInfo;
  setTargets: React.Dispatch<React.SetStateAction<TargetInfo>>;
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  heatMapTab: number;
  setHeatMapTab: React.Dispatch<React.SetStateAction<number>>;
  dispSwitch: string;
  setDispSwitch: React.Dispatch<React.SetStateAction<string>>;
};

// サマリデータマート画面用コンテキスト
const DatamartFacilityContext = createContext<DatamartFacilityProps>({
  saveEquipmentData: defaultDatamartEquipment,
  setSaveEquipmentData: () => {},
  selectCells: [],
  setSelectCells: () => {},
  targets: {
    product_target_value: 0,
    equipment_target_value: 0,
  } as TargetInfo,
  setTargets: () => {},
  tab: "1",
  setTab: () => {},
  heatMapTab: 0,
  setHeatMapTab: () => {},
  dispSwitch: "",
  setDispSwitch: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const DatamartFacilityProvider: React.VFC<Props> = ({ children }) => {
  const [saveEquipmentData, setSaveEquipmentData] = useState<DatamartEquipment>(
    defaultDatamartEquipment
  );
  const [selectCells, setSelectCells] = useState<string[]>([]);
  const [targets, setTargets] = useState<TargetInfo>({
    product_target_value: 0,
    equipment_target_value: 0,
  } as TargetInfo);
  const [tab, setTab] = useState<string>("1");
  const [heatMapTab, setHeatMapTab] = useState<number>(0);
  const [dispSwitch, setDispSwitch] = useState<string>("emission");

  return (
    <DatamartFacilityContext.Provider
      value={{
        saveEquipmentData,
        setSaveEquipmentData,
        selectCells,
        setSelectCells,
        targets,
        setTargets,
        tab,
        setTab,
        heatMapTab,
        setHeatMapTab,
        dispSwitch,
        setDispSwitch,
      }}
    >
      {children}
    </DatamartFacilityContext.Provider>
  );
};

export default DatamartFacilityContext;
