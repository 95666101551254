import React, { useContext, useEffect } from "react";
import classes from "./GoalValueField.module.scss";
import { Box } from "@mui/material";
import BaseButton from "../../parts/Button/BaseButton.tsx";
import InputTextOnLeftLabel from "../../parts/TextField/InputTextOnLeftLabel.tsx";
import { useTranslation } from "react-i18next";
import CircularProgressDialog from "../../parts/Dialog/CircularProgressDialog.tsx";
import { useTarget } from "../../../hooks/useTarget.tsx";
import DatamartProductContext from "../../../contexts/DatamartProductContext.tsx";

type GoalValueFieldProps = {};

const GoalValueField: React.FC<GoalValueFieldProps> = (props) => {
  const { t } = useTranslation();
  const { targets } = useContext(DatamartProductContext);
  const {
    putTargetApi,
    targetLoading,
    productTargetValue,
    setProductTargetValue,
    setEquipmentTargetValue,
  } = useTarget();

  useEffect(() => {
    setProductTargetValue(targets.product_target_value);
    setEquipmentTargetValue(targets.equipment_target_value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * 目標値変更イベント
   * @param event
   */
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.currentTarget.value);
    setProductTargetValue(newValue);
  };

  /**
   * 目標値反映イベント
   */
  const handleClick = () => {
    putTargetApi(productTargetValue, targets.equipment_target_value);
  };

  return (
    <>
      {!targetLoading && (
        <Box className={classes.goalValue}>
          <InputTextOnLeftLabel
            label={t("product.goal.label")}
            type="number"
            value={targets.product_target_value}
            labelClassName={classes.numberGoalLabel}
            className={classes.numberGoal}
            inputProps={{ step: 0.1 }}
            handleChange={handleChange}
          />
          <BaseButton
            label={t("product.goal.button")}
            handleClick={handleClick}
            className={classes.button}
          />
        </Box>
      )}
      {targetLoading && <CircularProgressDialog />}
    </>
  );
};

export default GoalValueField;
