import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Apartment,
  PrecisionManufacturing,
  TextSnippet,
  ContentPasteSearch,
  CorporateFare,
  Inventory2,
  QueryStats,
  Construction,
} from "@mui/icons-material";
import { ReactComponent as HomeIcon } from "../../../assets/images/SideMenu/icon_home.svg";
import { ReactComponent as Keyboard } from "../../../assets/images/SideMenu/icon_data_input_color.svg";
import { ReactComponent as Warming } from "../../../assets/images/SideMenu/icon_warming.svg";
import React from "react";
import classes from "./SideMenuItem.module.scss";

type SideMenuItemProps = {
  id: string;
  title: string;
  icon: string;
  className?: string;
  selected: boolean;
  open: boolean;
  isChild?: boolean;
  handleClick(item: string): void;
};

const itemIcon = (icon) => {
  switch (icon) {
    case "home":
      return <HomeIcon />;
    case "data-input":
      return <Keyboard />;
    case "company":
      return <CorporateFare />;
    case "company_parent":
      return <Apartment />;
    case "product":
      return <Inventory2 />;
    case "product_parent":
      return <PrecisionManufacturing />;
    case "energy_saving":
      return <TextSnippet />;
    case "warming_countermeasures":
      return <Warming />;
    case "product_free_analysis":
    case "company_free_analysis":
      return <QueryStats />;
    case "summary":
      return <ContentPasteSearch />;
    case "facility":
      return <Construction />;
    case "account-create":
      return "";
    case "notice-management":
      return "";
    default:
      return "";
  }
};

const SideMenuItem: React.FC<SideMenuItemProps> = (props) => {
  return (
    <ListItem
      className={`${props.selected ? classes.selectedItem : classes.listItem} ${
        props.isChild ? classes.childItem : ""
      }`}
    >
      <ListItemButton
        onClick={() => props.handleClick(props.id)}
        className={classes.sideButton}
      >
        <ListItemIcon className={classes.itemIcon}>
          {itemIcon(props.icon)}
        </ListItemIcon>
        {props.open && (
          <ListItemText primary={props.title} className={classes.title} />
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default SideMenuItem;
