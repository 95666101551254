import DatamartPcfChart from "./DatamartPcfChart";
import DatamartPcfHeatmap from "./DatamartPcfHeatmap";
import DatamartPdsTable from "./DatamartPdsTable";
import DatamartGraphData from "./DatamartGraphData";
import DatamartStatistics from "./DatamartStatistics";
import ErrorResponse from "./ErrorResponse";

export const defaultDatamartSummary = {
  statistics: [],
  pcf_heatmap: {
    major_category_data: [],
    middle_category_data: [],
    minor_category_data: [],
    parts_or_energies_data: [],
  },
  pcf_chart: {
    major_category_data: {
      year_month: [],
      calendar_year: [],
      organization_fiscal_year: [],
      general_business_year: [],
    },
    middle_category_data: {
      year_month: [],
      calendar_year: [],
      organization_fiscal_year: [],
      general_business_year: [],
    },
    minor_category_data: {
      year_month: [],
      calendar_year: [],
      organization_fiscal_year: [],
      general_business_year: [],
    },
    parts_or_energies_data: {
      year_month: [],
      calendar_year: [],
      organization_fiscal_year: [],
      general_business_year: [],
    },
  },
  pcf_graph: {
    major_category_data: {
      year_month: [],
      calendar_year: [],
      organization_fiscal_year: [],
      general_business_year: [],
    },
    middle_category_data: {
      year_month: [],
      calendar_year: [],
      organization_fiscal_year: [],
      general_business_year: [],
    },
    minor_category_data: {
      year_month: [],
      calendar_year: [],
      organization_fiscal_year: [],
      general_business_year: [],
    },
    parts_or_energies_data: {
      year_month: [],
      calendar_year: [],
      organization_fiscal_year: [],
      general_business_year: [],
    },
  },
  pds_table: [],
  production_volume_chart: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
  production_volume_graph: {
    year_month: [],
    calendar_year: [],
    organization_fiscal_year: [],
    general_business_year: [],
  },
};

// サマリデータマート取得APIレスポンス
export interface DatamartSummary {
  // シングルデータ
  statistics: DatamartStatistics[];
  // PCFヒートマップ
  pcf_heatmap: {
    major_category_data: DatamartPcfHeatmap[];
    middle_category_data: DatamartPcfHeatmap[];
    minor_category_data: DatamartPcfHeatmap[];
    parts_or_energies_data: DatamartPcfHeatmap[];
  };
  // PCFチャート
  pcf_chart: {
    major_category_data: {
      year_month: DatamartPcfChart[];
      calendar_year: DatamartPcfChart[];
      organization_fiscal_year: DatamartPcfChart[];
      general_business_year: DatamartPcfChart[];
    };
    middle_category_data: {
      year_month: DatamartPcfChart[];
      calendar_year: DatamartPcfChart[];
      organization_fiscal_year: DatamartPcfChart[];
      general_business_year: DatamartPcfChart[];
    };
    minor_category_data: {
      year_month: DatamartPcfChart[];
      calendar_year: DatamartPcfChart[];
      organization_fiscal_year: DatamartPcfChart[];
      general_business_year: DatamartPcfChart[];
    };
    parts_or_energies_data: {
      year_month: DatamartPcfChart[];
      calendar_year: DatamartPcfChart[];
      organization_fiscal_year: DatamartPcfChart[];
      general_business_year: DatamartPcfChart[];
    };
  };
  // PCFグラフ
  pcf_graph: {
    major_category_data: {
      year_month: DatamartPcfChart[];
      calendar_year: DatamartPcfChart[];
      organization_fiscal_year: DatamartPcfChart[];
      general_business_year: DatamartPcfChart[];
    };
    middle_category_data: {
      year_month: DatamartPcfChart[];
      calendar_year: DatamartPcfChart[];
      organization_fiscal_year: DatamartPcfChart[];
      general_business_year: DatamartPcfChart[];
    };
    minor_category_data: {
      year_month: DatamartPcfChart[];
      calendar_year: DatamartPcfChart[];
      organization_fiscal_year: DatamartPcfChart[];
      general_business_year: DatamartPcfChart[];
    };
    parts_or_energies_data: {
      year_month: DatamartPcfChart[];
      calendar_year: DatamartPcfChart[];
      organization_fiscal_year: DatamartPcfChart[];
      general_business_year: DatamartPcfChart[];
    };
  };
  // PDS
  pds_table: DatamartPdsTable[];
  // 製品別PCF推移
  production_volume_chart: {
    year_month: DatamartGraphData[];
    calendar_year: DatamartGraphData[];
    organization_fiscal_year: DatamartGraphData[];
    general_business_year: DatamartGraphData[];
  };
  production_volume_graph: {
    year_month: DatamartGraphData[];
    calendar_year: DatamartGraphData[];
    organization_fiscal_year: DatamartGraphData[];
    general_business_year: DatamartGraphData[];
  };
}

type DatamartSummaryResponse = DatamartSummary | ErrorResponse;

export default DatamartSummaryResponse;
