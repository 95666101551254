import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import classes from "./SearchCheckBox.module.scss";
import SearchFeatures from "../../../features/SearchFeatures.tsx";
import { useTranslation } from "react-i18next";
import { CheckBoxType } from "../../../types/SearchCheckBoxType.tsx";
import DatamartSearchContext from "../../../contexts/DatamartSearchContext.tsx";

type SearchCheckBoxProps = {
  datas: { [key: string]: CheckBoxType };
  type: string;
};

const SearchCheckBox: React.FC<SearchCheckBoxProps> = (props) => {
  const { t } = useTranslation();
  const checkAll = () => {
    return !Object.values(props.datas).find((item) => {
      return item.checked === false;
    });
  };

  const [checkedAll, setCheckedAll] = useState<boolean>(true);
  const [checkedItems, setCheckedItems] = useState<{
    [key: string]: CheckBoxType;
  }>(props.datas);
  const { setSearchDataBySelectData } = SearchFeatures();
  const { resetStatus } = useContext(DatamartSearchContext);

  useEffect(() => {
    setCheckedAll(checkAll());
    setCheckedItems(Object.assign({}, props.datas));
  }, [resetStatus]);

  useEffect(() => {
    setCheckedItems(Object.assign({}, props.datas));
  }, [props.datas]);

  /**
   * チェックボックスイベント
   * @param event
   */
  const handleChange = (event) => {
    const { name, checked } = event.target;
    let allChecked = false;
    if (name === "all") {
      allChecked = checked;
      setCheckedAll(allChecked);
      Object.keys(checkedItems).forEach((key) => {
        checkedItems[key].checked = checked;
      });
      setCheckedItems(checkedItems);
    } else {
      if (checkedItems[name]) {
        checkedItems[name].checked = checked;
      }
      setCheckedItems(checkedItems);
      allChecked = !Object.values(checkedItems).find((item) => {
        return item.checked === false;
      });
      setCheckedAll(allChecked);
    }
    setSearchDataBySelectData(checkedItems, props.type, allChecked);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkedAll}
              onChange={handleChange}
              name="all"
              className={classes.checkBox}
            />
          }
          label={t("parts.checkBoxAll")}
        />
        <Box>
          {Object.values(checkedItems).map((value, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={value.checked}
                  onChange={handleChange}
                  name={value.label}
                  className={classes.checkBox}
                />
              }
              label={value.label}
              className={classes.checkBoxItem}
            />
          ))}
        </Box>
      </FormGroup>
    </FormControl>
  );
};

export default SearchCheckBox;
