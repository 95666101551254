import { useContext, useState } from "react";
import MessageType from "../types/Message.tsx";
import AppContext from "../contexts/AppContext.tsx";
import PasswordChangeRequest from "../types/request/PasswordChangeRequest.tsx";
import PasswordChangeApi from "../features/api/PasswordChangeApi.tsx";
import { ApiCommon } from "../features/ApiCommon.tsx";

export const usePasswordChange = () => {
  const appContext = useContext(AppContext);
  const { onCommonError } = ApiCommon();
  const Message = MessageType();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reNewPassword, setReNewPassword] = useState("");
  const [isLoading, setLoading] = useState<boolean>(false);

  /**
   * パスワード変更API成功時
   */
  const onPasswordChangeSuccess = (data) => {
    setLoading(false);
    appContext?.setSuccessOpen(true);
    appContext?.setAlertMessage(Message.SUCCESS_PASSWORD_CHANGE);
    setPassword("");
    setNewPassword("");
    setReNewPassword("");
  };

  /**
   * パスワード変更API失敗時
   */
  const onPasswordChangeError = (error) => {
    onCommonError(error, Message.ERROR_ERROR_PASSWORD_CHANGE);
    setLoading(false);
  };

  /**
   * 入力データ確認処理
   * パスワード更新用のフォームに入力されているデータがある場合、パスワード更新処理を実行する
   */
  const checkInputData = () => {
    if (password || newPassword || reNewPassword) {
      return true;
    }
    return false;
  };

  const getRequest = () => {
    return {
      password: password,
      new_password: newPassword,
    } as PasswordChangeRequest;
  };

  /**
   * パスワード変更API処理
   * @returns
   */
  const passwordChangeApi = async () => {
    setLoading(true);
    appContext?.setErrorOpen(false);
    appContext?.setAlertMessage("");
    exePasswordChange.refetch();
  };

  // パスワード変更API実行
  const exePasswordChange = PasswordChangeApi.PasswordChange(
    getRequest(),
    onPasswordChangeSuccess,
    onPasswordChangeError
  );

  return {
    passwordChangeApi,
    checkInputData,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    reNewPassword,
    setReNewPassword,
    isLoading,
  };
};
