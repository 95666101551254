import React, { createContext, useState } from "react";

type TabProps = {
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
};

// マスターデータコンテキスト
const TabsContext = createContext<TabProps>({
  tab: 0,
  setTab: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const TabsProvider: React.VFC<Props> = ({ children }) => {
  const [tab, setTab] = useState<number>(0);

  return (
    <TabsContext.Provider
      value={{
        tab,
        setTab,
      }}
    >
      {children}
    </TabsContext.Provider>
  );
};

export default TabsContext;
