import React, { useContext } from "react";
import { IconButton, Typography } from "@mui/material";
import classes from "./FullScreenField.module.scss";
import { useTranslation } from "react-i18next";
import AppContext from "../../../contexts/AppContext.tsx";
import { Fullscreen } from "@mui/icons-material";

const FullScreenField = () => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);

  return (
    <>
      {appContext && (
        <IconButton
          className={classes.iconButton}
          onClick={appContext.fullScreenHandle.enter}
        >
          <Fullscreen className={classes.iconImage} />
          <Typography
            variant="body1"
            component="div"
            sx={{ flexGrow: 1 }}
            className={classes.iconTitle}
          >
            {t("header.fullscreen")}
          </Typography>
        </IconButton>
      )}
    </>
  );
};

export default FullScreenField;
