import React, { useContext } from "react";
import InputCenterText from "../../parts/TextField/InputCenterText.tsx";
import LoginContext from "../../../contexts/LoginContext.tsx";
import { Stack } from "@mui/material";
import classes from "./OtpField.module.scss";
import { useTranslation } from "react-i18next";

const OtpField = () => {
  const loginContext = useContext(LoginContext);
  const { t } = useTranslation();

  /**
   * 2段階認証コード入力イベント
   * @param event
   */
  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    loginContext?.setOnetimePassword(event.target.value);
  };

  return (
    <Stack>
      <InputCenterText
        label={t("optLogin.inputOpt")}
        handleChange={handleOtpChange}
        value={loginContext?.onetimePassword}
        className={classes.input_otp}
      />
    </Stack>
  );
};

export default OtpField;
