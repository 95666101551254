import React, { useContext } from "react";
import classes from "./PCFLineGraphField.module.scss";
import { Box, Paper, Tab, Typography } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PCFGraph from "../../parts/Chart/PCFGraph.tsx";
import { DatamartEquipment } from "../../../types/response/DatamartEquipmentResponse.tsx";
import DatamartFeatures from "../../../features/DatamartFeatures.tsx";
import { useTranslation } from "react-i18next";
import DatamartFacilityContext from "../../../contexts/DatamartFacilityContext.tsx";

type PCFLineGraphFieldProps = {
  data: DatamartEquipment;
  emissionValue: string;
};

const PCFLineGraphField: React.FC<PCFLineGraphFieldProps> = (props) => {
  const { t } = useTranslation();
  const { createChartData } = DatamartFeatures();
  const { targets, selectCells, saveEquipmentData, tab, setTab } = useContext(
    DatamartFacilityContext
  );

  /**
   * タブ切り替え
   * @param event
   * @param newValue
   */
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <Paper elevation={1} className={classes.pcfLineGraph}>
      <Typography
        color="text.secondary"
        sx={{
          fontWeight: "bold",
          textAlign: "left",
        }}
        gutterBottom
        className={classes.label}
      >
        {t("facility.lineGraph.title")}
      </Typography>
      <TabContext value={tab}>
        <TabList
          onChange={handleChange}
          aria-label="pcfLineGraph"
          className={classes.tabList}
        >
          <Tab
            label={t("facility.lineGraph.tabYearMonth")}
            value="1"
            className={classes.tab}
          />
          <Tab
            label={t("facility.lineGraph.tabCalendarYear")}
            value="2"
            className={classes.tab}
          />
          <Tab
            label={t("facility.lineGraph.tabFiscalYear")}
            value="3"
            className={classes.tab}
          />
          <Tab
            label={t("facility.lineGraph.tabBusinessYear")}
            value="4"
            className={classes.tab}
          />
        </TabList>
        <TabPanel value="1" className={classes.tabPanel}>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                title={t("facility.lineGraph.yearMonth.co2GraphTitle")}
                data={createChartData(
                  props.emissionValue === "pcf"
                    ? props.data.pcf_chart.year_month
                    : props.data.emissions_chart.year_month,
                  props.emissionValue === "pcf"
                    ? props.data.pcf_graph.year_month
                    : props.data.emissions_graph.year_month,
                  t("facility.lineGraph.yearMonth.co2GraphRightLabel")
                )}
                leftLabel={
                  props.emissionValue === "pcf"
                    ? t("facility.lineGraph.yearMonth.co2GraphLeftLabelCFP")
                    : t("facility.lineGraph.yearMonth.co2GraphLeftLabel")
                }
                rightLabel={t(
                  "facility.lineGraph.yearMonth.co2GraphRightLabel"
                )}
                targetValue={targets.equipment_target_value}
              />
            </Paper>
          </Box>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                title={t("facility.lineGraph.yearMonth.activityGraphTitle")}
                data={createChartData(
                  selectCells.length === 0
                    ? props.data.amount_chart.year_month
                    : saveEquipmentData.amount_chart.year_month,
                  selectCells.length === 0
                    ? props.data.amount_graph.year_month
                    : saveEquipmentData.amount_graph.year_month,
                  t("facility.lineGraph.yearMonth.activityGraphRightLabel")
                )}
                leftLabel={t(
                  "facility.lineGraph.yearMonth.activityGraphLeftLabel"
                )}
                rightLabel={t(
                  "facility.lineGraph.yearMonth.activityGraphRightLabel"
                )}
              />
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value="2" className={classes.tabPanel}>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                title={t("facility.lineGraph.calendarYear.co2GraphTitle")}
                data={createChartData(
                  props.emissionValue === "pcf"
                    ? props.data.pcf_chart.calendar_year
                    : props.data.emissions_chart.calendar_year,
                  props.emissionValue === "pcf"
                    ? props.data.pcf_graph.calendar_year
                    : props.data.emissions_graph.calendar_year,
                  t("facility.lineGraph.calendarYear.co2GraphRightLabel")
                )}
                leftLabel={
                  props.emissionValue === "pcf"
                    ? t("facility.lineGraph.calendarYear.co2GraphLeftLabelCFP")
                    : t("facility.lineGraph.calendarYear.co2GraphLeftLabel")
                }
                rightLabel={t(
                  "facility.lineGraph.calendarYear.co2GraphRightLabel"
                )}
                targetValue={targets.equipment_target_value}
              />
            </Paper>
          </Box>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                title={t("facility.lineGraph.calendarYear.activityGraphTitle")}
                data={createChartData(
                  selectCells.length === 0
                    ? props.data.amount_chart.calendar_year
                    : saveEquipmentData.amount_chart.calendar_year,
                  selectCells.length === 0
                    ? props.data.amount_graph.calendar_year
                    : saveEquipmentData.amount_graph.calendar_year,
                  t("facility.lineGraph.calendarYear.activityGraphRightLabel")
                )}
                leftLabel={t(
                  "facility.lineGraph.calendarYear.activityGraphLeftLabel"
                )}
                rightLabel={t(
                  "facility.lineGraph.calendarYear.activityGraphRightLabel"
                )}
              />
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value="3" className={classes.tabPanel}>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                title={t("facility.lineGraph.fiscalYear.co2GraphTitle")}
                data={createChartData(
                  props.emissionValue === "pcf"
                    ? props.data.pcf_chart.organization_fiscal_year
                    : props.data.emissions_chart.organization_fiscal_year,
                  props.emissionValue === "pcf"
                    ? props.data.pcf_graph.organization_fiscal_year
                    : props.data.emissions_graph.organization_fiscal_year,
                  t("facility.lineGraph.fiscalYear.co2GraphRightLabel")
                )}
                leftLabel={
                  props.emissionValue === "pcf"
                    ? t("facility.lineGraph.fiscalYear.co2GraphLeftLabelCFP")
                    : t("facility.lineGraph.fiscalYear.co2GraphLeftLabel")
                }
                rightLabel={t(
                  "facility.lineGraph.fiscalYear.co2GraphRightLabel"
                )}
                targetValue={targets.equipment_target_value}
              />
            </Paper>
          </Box>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                title={t("facility.lineGraph.fiscalYear.activityGraphTitle")}
                data={createChartData(
                  selectCells.length === 0
                    ? props.data.amount_chart.organization_fiscal_year
                    : saveEquipmentData.amount_chart.organization_fiscal_year,
                  selectCells.length === 0
                    ? props.data.amount_graph.organization_fiscal_year
                    : saveEquipmentData.amount_graph.organization_fiscal_year,
                  t("facility.lineGraph.fiscalYear.activityGraphRightLabel")
                )}
                leftLabel={t(
                  "facility.lineGraph.fiscalYear.activityGraphLeftLabel"
                )}
                rightLabel={t(
                  "facility.lineGraph.fiscalYear.activityGraphRightLabel"
                )}
              />
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value="4" className={classes.tabPanel}>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                title={t("facility.lineGraph.businessYear.co2GraphTitle")}
                data={createChartData(
                  props.emissionValue === "pcf"
                    ? props.data.pcf_chart.general_business_year
                    : props.data.emissions_chart.general_business_year,
                  props.emissionValue === "pcf"
                    ? props.data.pcf_graph.general_business_year
                    : props.data.emissions_graph.general_business_year,
                  t("facility.lineGraph.businessYear.co2GraphRightLabel")
                )}
                leftLabel={
                  props.emissionValue === "pcf"
                    ? t("facility.lineGraph.businessYear.co2GraphLeftLabelCFP")
                    : t("facility.lineGraph.businessYear.co2GraphLeftLabel")
                }
                rightLabel={t(
                  "facility.lineGraph.businessYear.co2GraphRightLabel"
                )}
                targetValue={targets.equipment_target_value}
              />
            </Paper>
          </Box>
          <Box className={classes.tabGrid}>
            <Paper elevation={1} className={classes.pcfGraph}>
              <PCFGraph
                title={t("facility.lineGraph.businessYear.activityGraphTitle")}
                data={createChartData(
                  selectCells.length === 0
                    ? props.data.amount_chart.general_business_year
                    : saveEquipmentData.amount_chart.general_business_year,
                  selectCells.length === 0
                    ? props.data.amount_graph.general_business_year
                    : saveEquipmentData.amount_graph.general_business_year,
                  t("facility.lineGraph.businessYear.activityGraphRightLabel")
                )}
                leftLabel={t(
                  "facility.lineGraph.businessYear.activityGraphLeftLabel"
                )}
                rightLabel={t(
                  "facility.lineGraph.businessYear.activityGraphRightLabel"
                )}
              />
            </Paper>
          </Box>
        </TabPanel>
      </TabContext>
    </Paper>
  );
};

export default PCFLineGraphField;
