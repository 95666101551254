import { IconButton } from "@mui/material";
import React from "react";
import DownloadIcon from "@mui/icons-material/Download";

type DownloadButtonProps = {
  label: string;
  value: string;
  iconClassName?: string;
  className?: string;
  disabled?: boolean;
  handleClick: React.MouseEventHandler;
};

const DownloadButton: React.FC<DownloadButtonProps> = (props) => {
  return (
    <IconButton
      aria-label={props.label}
      onClick={props.handleClick}
      disabled={props.disabled}
      value={props.value}
      className={props.iconClassName}
    >
      <DownloadIcon className={props.className} />
    </IconButton>
  );
};

export default DownloadButton;
