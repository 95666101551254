import React, { useContext, useEffect } from "react";
import { Drawer } from "@mui/material";
import classes from "./SideMenuForm.module.scss";
import { useLocation } from "react-router-dom";
import SideMenuField from "../../fields/SideMenu/SideMenuField.tsx";
import AppContext from "../../../contexts/AppContext.tsx";

const SideMenuForm = () => {
  const appContext = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    // パス先値に応じて、サイドメニュー設定
    const pathName = location.pathname.split("/");
    if (pathName.length > 1) {
      appContext?.setSideItem(pathName[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      variant="permanent"
      className={`${classes.drawer} ${
        appContext?.sideOpen ? classes.openSide : classes.notOpenSide
      }`}
    >
      <div className={classes.toolbar} />
      <SideMenuField />
    </Drawer>
  );
};

export default SideMenuForm;
