import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import UserContext from "../../../contexts/UserContext.tsx";
import { UserInfo } from "../../../types/response/UserInfoResponse.tsx";
import classes from "./AccountMenu.module.scss";
import { useLogin } from "../../../hooks/useLogin.tsx";
import { useTranslation } from "react-i18next";
import { UserFuatures } from "../../../features/UserFuatures.tsx";

type AccountMenuProps = {
  open: boolean;
  anchorEl: null | HTMLElement;
  handleClose?: (event) => void;
};

const AccountMenu: React.FC<AccountMenuProps> = (props) => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const userInfo = userContext?.userInfo as UserInfo;
  const { getUserName } = UserFuatures();
  const { useLogoutApi } = useLogin();
  const [account, setAccount] = useState({
    name: "",
    company_name: "",
    email: "",
    id: "",
    corporate_number: "",
    start_date: "",
    end_date: "",
  });

  const Logout = () => {
    useLogoutApi();
  };

  useEffect(() => {
    if (userInfo) {
      account.name = getUserName();
      account.email = userInfo.email;
      if (userInfo.contracts && userInfo.contracts.length) {
        const contract = userInfo.contracts[0];
        account.id = contract.organization_id;
        account.start_date = contract.start_date;
        account.end_date = contract.end_date;

        if (userInfo.organizations && userInfo.organizations.length) {
          const organization = userInfo.organizations.find((org) => {
            return org.id === contract.organization_id;
          });
          account.company_name = organization ? organization.company_name : "";
          account.corporate_number = organization
            ? organization.corporate_number
            : "";
        }
      }
      setAccount(account);
    }
  }, [userInfo]);

  return (
    <Menu
      id="long-menu"
      open={props.open}
      onClose={props.handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorEl={props.anchorEl}
    >
      <Card
        sx={{ minWidth: 275 }}
        elevation={0}
        className={classes.cardContent}
      >
        <CardContent className={classes.menuContent}>
          <Typography
            className={classes.accountItem}
            color="text.secondary"
            gutterBottom
          >
            {account.company_name}
          </Typography>
          <Typography variant="body2">
            {/* <Avatar aria-label="avatar" />
            <br /> */}
            <Stack className={classes.accountItem}>{account.name}</Stack>
            <Stack className={classes.accountItem}>{account.email}</Stack>
            <Stack className={classes.accountItem}>{`${t(
              "parts.accountMenu.contractId"
            )}：${account.id}`}</Stack>
            <Stack className={classes.accountItem}>{`${t(
              "parts.accountMenu.company"
            )}：${account.company_name}`}</Stack>
            <Stack className={classes.accountItem}>{`${t(
              "parts.accountMenu.companyNumber"
            )}：${account.corporate_number}`}</Stack>
            <Stack className={classes.accountItem}>{`${t(
              "parts.accountMenu.date"
            )}：${account.start_date} ${t("common.tofrom")} ${
              account.end_date
            }`}</Stack>
            {/* <Button size="small">アカウントを表示</Button> */}
          </Typography>
          <IconButton className={classes.logoutButton} onClick={Logout}>
            <Typography className={classes.logoutButton} color="text.secondary">
              {t("parts.accountMenu.logout")}
            </Typography>
          </IconButton>
        </CardContent>
      </Card>
    </Menu>
  );
};

export default AccountMenu;
