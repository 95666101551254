import React, { createContext, useState } from "react";
import {
  DatamartProduct,
  defaultDatamartProduct,
} from "../types/response/DatamartProductResponse.tsx";
import RequestCode from "../types/request/RequestCode.tsx";
import { TargetInfo } from "../types/response/TargetResponse.tsx";

type DatamartProductProps = {
  selectType: string;
  setSelectType: React.Dispatch<React.SetStateAction<string>>;
  saveProductData: DatamartProduct;
  setSaveProductData: React.Dispatch<React.SetStateAction<DatamartProduct>>;
  selectCells: { [key: string]: RequestCode[] };
  setSelectCells: React.Dispatch<
    React.SetStateAction<{ [key: string]: RequestCode[] }>
  >;
  exeBesideBarSearch: boolean;
  setBesideBarSearch: React.Dispatch<React.SetStateAction<boolean>>;
  targets: TargetInfo;
  setTargets: React.Dispatch<React.SetStateAction<TargetInfo>>;
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
};

// 製品軸データマート画面用コンテキスト
const DatamartProductContext = createContext<DatamartProductProps>({
  selectType: "",
  setSelectType: () => {},
  saveProductData: defaultDatamartProduct,
  setSaveProductData: () => {},
  selectCells: {},
  setSelectCells: () => {},
  exeBesideBarSearch: false,
  setBesideBarSearch: () => {},
  targets: {
    product_target_value: 0,
    equipment_target_value: 0,
  } as TargetInfo,
  setTargets: () => {},
  tab: "1",
  setTab: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const DatamartProductProvider: React.VFC<Props> = ({ children }) => {
  const [selectType, setSelectType] = useState<string>("");
  const [saveProductData, setSaveProductData] = useState<DatamartProduct>(
    defaultDatamartProduct
  );
  const [selectCells, setSelectCells] = useState<{
    [key: string]: RequestCode[];
  }>({});
  const [exeBesideBarSearch, setBesideBarSearch] = useState<boolean>(false);
  const [targets, setTargets] = useState<TargetInfo>({
    product_target_value: 0,
    equipment_target_value: 0,
  } as TargetInfo);
  const [tab, setTab] = useState<string>("1");

  return (
    <DatamartProductContext.Provider
      value={{
        selectType,
        setSelectType,
        saveProductData,
        setSaveProductData,
        selectCells,
        setSelectCells,
        exeBesideBarSearch,
        setBesideBarSearch,
        targets,
        setTargets,
        tab,
        setTab,
      }}
    >
      {children}
    </DatamartProductContext.Provider>
  );
};

export default DatamartProductContext;
