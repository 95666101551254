import DatamartDataOrganization from "./DatamartDataOrganization";
import DatamartDataScope from "./DatamartDataScope";
import DatamartEmissionsData from "./DatamartEmissionsData";
import DatamartStatistics from "./DatamartStatistics";
import DatamartTableData from "./DatamartTableData";
import DatamartYoyData from "./DatamartYoyData";
import ErrorResponse from "./ErrorResponse";

export const defaultDatamartOrganization = {
  statistics: [],
  emissions_chart: {
    data_by_energy: [],
    data_by_organization: [],
    data_by_scope: [],
  },
  total_amount_graph: {
    data_by_organization: [],
    data_by_scope: [],
  },
  base_unit_graph: {
    average_yoy_data: [],
    yoy_data: [],
  },
  base_unit_table: {
    data: [],
  },
};

// 企業別データマート取得APIレスポンス
export interface DatamartOrganization {
  // シングルデータ
  statistics: DatamartStatistics[];
  // 円グラフ
  emissions_chart: {
    data_by_energy: DatamartEmissionsData[];
    data_by_organization: DatamartEmissionsData[];
    data_by_scope: DatamartEmissionsData[];
  };
  // 総量トレンドグラフ
  total_amount_graph: {
    data_by_organization: DatamartDataOrganization[];
    data_by_scope: DatamartDataScope[];
  };
  // 排出量原単位トレンドグラフ
  base_unit_graph: {
    average_yoy_data: DatamartYoyData[];
    yoy_data: DatamartYoyData[];
  };
  // 排出量原単位集計表
  base_unit_table: {
    data: DatamartTableData[];
  };
}

type DatamartOrganizationResponse = DatamartOrganization | ErrorResponse;

export default DatamartOrganizationResponse;
